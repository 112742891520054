import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import classNames from 'classnames';

const VisionAcuityInFarDistance = ({ control, data, setValue }) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState(data?.visionAcuityInFarDistance?.r);
  const [lValue, setLValue] = useState(data?.visionAcuityInFarDistance?.l);

  const lValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: lValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: lValue === false,
        value: false,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: rValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: rValue === false,
        value: false,
      },
    ],
    [rValue]
  );

  
  useEffect(() => {
    setValue('visionAcuityInFarDistance.r', data?.visionAcuityInFarDistance?.r);
    setValue('visionAcuityInFarDistance.l', data?.visionAcuityInFarDistance?.l);
  }, []);
  return (
    <>
      <Separator text={t('examinations:screenings.visionAcuityInFarDistanceTest.title')} hint={t('examinations:screenings.visionAcuityInFarDistanceTest.hint')}/>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:rightEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:screenings.visionAcuityInFarDistanceTest.identifiesImages')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'visionAcuityInFarDistance.r',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.visionAcuityInFarDistance?.r,
            }}
            onChange={v => {
              setRValue(v);
              setValue('visionAcuityInFarDistance.r', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:leftEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:screenings.visionAcuityInFarDistanceTest.identifiesImages')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'visionAcuityInFarDistance.l',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.visionAcuityInFarDistance?.l,
            }}
            onChange={v => {
              setLValue(v);
              setValue('visionAcuityInFarDistance.l', v);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default VisionAcuityInFarDistance;
