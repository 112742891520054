import classnames from 'classnames';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import {Alert, Button, Card, Col, FormLabel, Image, Modal, Row} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import PageTitle from '../../components/PageTitle/PageTitle';
import SymptomsFormValues from '../../components/SymptomsForm/SymptomsFormValues';
import { CountryRegions, YerevanCommunities } from '../../constants';
import { loadPatient } from '../../store/actions';
import Examinations from './Examinations/Examinations';
import FileThumbnail, { FileThumbnailType } from '../../components/FileThumbnail/FileThumbnail';
import {hasPermission} from '../../accessControl';
import {AppContext} from '../../context';

const PatientProfile = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: authData } = useSelector(state => state.auth);
  const theUser = authData.user;

  const { data, loading, error } = useSelector(state => state.patient);
  const { locale } = useContext(AppContext);

  const [region, setRegion] = useState();
  const [community, setCommunity] = useState();
  const [showImageModal, setShowImageModal] = useState(false);

  const handleCloseModal = () => setShowImageModal(false);
  const handleShowModal = () => setShowImageModal(true);

  useEffect(() => {
    dispatch(loadPatient({ id }));
  }, []);

  useEffect(() => {
    if (data && !loading & !error) {
      const region = CountryRegions.find(_ => _.value === data?.region)?.label;
      setRegion(region);

      if (data.community) {
        const community = YerevanCommunities.find(_ => _.value === data?.community)?.label;
        setCommunity(community);
      }
    }
  }, [data]);

  const schoolRegion = useMemo(() => {
    if (!data || !data.school) {
      return null;
    }

    return CountryRegions.find(_ => _.value === data.school?.region)?.label;
  }, [data]);

  const schoolCommunity = useMemo(() => {
    if (!data || !data.school) {
      return null;
    }

    return YerevanCommunities.find(_ => _.value === data.school?.community)?.label;
  }, [data]);

  if (error) {
    return (
      <Alert variant="danger" className="mt-3">
        <p className="text-danger text-center m-2">{error.message}</p>
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:patientProfile') + t('pageTitles:tail')}</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle title={t('pageTitles:patientProfile')} />
        {
          hasPermission('updateOwn', 'patient') &&
            <NavLink to={`/${locale.value}/patient/${data?.id}/edit`} className="me-1">
              <Button className="btn-soft-primary">{t('buttons.edit')}</Button>
            </NavLink>
        }
      </div>

      <Row className="patient-profile">
        <Col sm={12}>
          <Card>
            <Card.Body>
              {loading || (!data && !error) ? (
                <LoadingIndicator />
              ) : (
                <div className="met-profile">
                  <Row>
                    <Col md={6} className="align-self-center mb-3 mb-lg-0">
                      <div className="met-profile-main">
                        <div className="met-profile_user-detail">
                          <h5 className="met-user-name mb-3">
                            <i className={classnames(['las align-middle me-2 mt-n2', 'la-' + data.gender])}></i>
                            {data.fullName} (#{data.uid})
                            <small className="text-muted fw-normal fs-6"></small>
                          </h5>
                          <p className="mb-0 met-user-name-post">
                            <strong>{t('patient:profile.dob')}:</strong> {data.dob}
                          </p>
                          <p className="mb-0 met-user-name-post">
                            <strong>{t('patient:profile.birthCertificateNumber')}:</strong>{' '}
                            {data.birthCertificateNumber}
                          </p>
                          <p className="mb-0 met-user-name-post">
                            <strong>{t('patient:profile.socialCardNumber')}:</strong> {data.socialCardNumber}
                          </p>
                          <p className="mb-0 met-user-name-post">
                            <strong> {t('patient:profile.medInstitutionName')}:</strong> {data.medInstitutionName}
                          </p>
                          <p className="mb-0 met-user-name-post">
                            <strong>{t('patient:profile.familyDoctorName')}:</strong> {data.familyDoctorName}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="ms-auto align-self-center">
                      <ul className="list-unstyled personal-detail mb-0">
                        <li className="mt-3">
                          <i className="las la-school text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:profile.school')}</b>:{' '}
                          {data.school
                            ? [
                                data.school.name,
                                data.school.address,
                                schoolRegion,
                                t(`address.settlementType.${data.school.settlementType}`) +
                                  ' ' +
                                  data.school.settlement,
                                schoolCommunity,
                              ]
                                .filter(_ => !!_)
                                .join(', ')
                            : null}
                        </li>

                        <li className="mt-2">
                          <i className="las la-home text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:profile.address')}</b>:{' '}
                          {[
                            data.address,
                            region,
                            t(`address.settlementType.${data.settlementType}`) + ' ' + data.settlement,
                            community,
                          ]
                            .filter(_ => !!_)
                            .join(', ')}
                        </li>
                        <li className="mt-2">
                          <i className="las la-user-alt text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:adultContacts.parent')}</b>: {data.parent?.fullName} (
                          {t('patient:adultContacts.' + data.parent.relation)})
                        </li>
                        <li className="mt-2">
                          <i className="las la-phone text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:adultContacts.mobilePhone')}</b>: {data.parent.mobilePhone}
                        </li>
                        <li className="mt-2">
                          <i className="las la-tty text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:adultContacts.homePhone')}</b>: {data.parent.homePhone}
                        </li>
                        <li className="mt-2">
                          <i className="las la-at text-secondary font-22 align-middle mr-2"></i>
                          <b> {t('patient:adultContacts.email')}</b>: {data.parent.email}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col md={6} className=" mb-3 mb-lg-0">
                      <FormLabel className="text-muted d-flex align-items-center">
                        {t('patient:profile.lastScreening')}
                        {t('formLabelSeparator')}
                        {data.hasIssue ? (
                            <span className="badge badge-soft-danger ms-1">{t('examinations:visionState.hasIssue')}</span>
                        ) : null}
                        {data.hasIssue === false ? (
                            <span className="badge badge-soft-success ms-1">{t('examinations:visionState.normalVision')}</span>
                        ) : null}
                      </FormLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FileThumbnail
                        type={FileThumbnailType.rect}
                        onClick={handleShowModal}
                        file={{ name: t('patient:profile.viewParentConsent') }}
                      />
                    </Col>
                  </Row>
                  <Modal show={showImageModal} onHide={handleCloseModal} fullscreen className="patient-modal">
                    <Modal.Header closeButton />
                    <Modal.Body>
                      <Image
                        className="attach-img"
                        src={data.parentConcent.secure_url}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>{loading || (!data && !error) ? <LoadingIndicator /> : <Examinations patientId={data.id} appointmentId={data.appointments?.find(_ => _.doctor === theUser.id)?.id}/>}</Col>
      </Row>
    </>
  );
};
export default PatientProfile;
