import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomRadioGroup from '../CustomRadioGroup/CustomRadioGroup';
import CustomFormGroup from '../CustomFormGroup/CustomFormGroup';

const SymptomsFormFields = ({ control, setValue, data, type }) => {
  const { t } = useTranslation();
  const [parentEvaluationList, setParentEvaluationList] = useState([
    [
      { title: t('patient:symptoms.se1'), name: 'se1', checked: undefined },
      { title: t('patient:symptoms.se2'), name: 'se2', checked: undefined },
    ],
    [
      { title: t('patient:symptoms.se3'), name: 'se3', checked: undefined },
      { title: t('patient:symptoms.se4'), name: 'se4', checked: undefined },
    ],
    [
      { title: t('patient:symptoms.se5'), name: 'se5', checked: undefined },
      { title: t('patient:symptoms.se6'), name: 'se6', checked: undefined },
    ],
    [
      { title: t('patient:symptoms.se7'), name: 'se7', checked: undefined },
      { title: t('patient:symptoms.se8'), name: 'se8', checked: undefined },
    ],
    [
      { title: t('patient:symptoms.se9'), name: 'se9', checked: undefined },
      { title: t('patient:symptoms.se10'), name: 'se10', checked: undefined },
    ],
    [
      { title: t('patient:symptoms.se11'), name: 'se11', checked: undefined },
      { title: t('patient:symptoms.se12'), name: 'se12', value: undefined },
    ],
  ]);

  useEffect(() => {
    if (data) {
      setParentEvaluationList(
        parentEvaluationList.map(_ => {
          setValue(`symptoms.${_[0].name}`, data[_[0].name]);
          setValue(`symptoms.${_[1].name}`, data[_[1].name]);
          _[0].checked = data[_[0].name];
          _[1].checked = data[_[1].name];
          return _;
        })
      );
    }
  }, [data]);

  const handleChange = useCallback(
    (value, _, id, type = 'bool') => {
      setValue(`symptoms.${_[id].name}`, type === 'bool' ? !!value : value.target.value);
      setParentEvaluationList(
        parentEvaluationList.map(el => {
          if (el[id].name === _[id].name) {
            el[id].checked = type === 'bool' ? !!value : value.target.value;
          }
          return el;
        })
      );
    },
    [parentEvaluationList]
  );

  return (
    <>
      {parentEvaluationList.map((_, index) => {
        if (type === 'regd' && index === 5) {
          return (
            <Row key={index}>
            <Col className="mb-3">
              <CustomRadioGroup
                label={_[0].title}
                labelClass="d-block"
                items={[
                  { label: t('yes'), checked: _[0].checked === true, value: true },
                  { label: t('no'), checked: _[0].checked === false, value: false },
                ]}
                controlProps={{
                  name: `symptoms.${_[0].name}`,
                  control,
                  rules: { validate: v => v !== undefined || 'required' },
                }}
                onChange={v => {
                  handleChange(v, _, 0);
                }}
              />
            </Col>
            <Col className="mb-3">
            <CustomFormGroup
              label={_[1].title}
              groupProps={{ className: 'col-md-4 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: `symptoms.${_[1].name}`,
                name: `symptoms.${_[1].name}`,
                defaultValue: _[1].value,
              }}
              register={{onChange: v => {handleChange(v, _, 1, 'string');}}}
            />
            </Col>
          </Row>
          );
        } else if (index === 5) {
          return null;
        }

        return (
          <Row key={index}>
            <Col className="mb-3">
              <CustomRadioGroup
                label={_[0].title}
                labelClass="d-block"
                items={[
                  { label: t('yes'), checked: _[0].checked === true, value: true },
                  { label: t('no'), checked: _[0].checked === false, value: false },
                ]}
                controlProps={{
                  name: `symptoms.${_[0].name}`,
                  control,
                  rules: { validate: v => v !== undefined || 'required' },
                }}
                onChange={v => {
                  handleChange(v, _, 0);
                }}
              />
            </Col>
            <Col className="mb-3">
              <CustomRadioGroup
                label={_[1].title}
                labelClass="d-block"
                items={[
                  { label: t('yes'), checked: _[1].checked === true, value: true },
                  { label: t('no'), checked: _[1].checked === false, value: false },
                ]}
                controlProps={{
                  name: `symptoms.${_[1].name}`,
                  control,
                  rules: { validate: v => v !== undefined || 'required' },
                }}
                onChange={v => {
                  handleChange(v, _, 1);
                }}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

SymptomsFormFields.propTypes = {};

export default SymptomsFormFields;
