import React from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FMDExaminationResult } from '../../../../../constants';
import { ExaminationDataType } from '../../Examination/Examination';

type Props = {
  data: FMDExaminationDataType;
};
export type FMDExaminationDataType = ExaminationDataType & {
  result: number;
  notes: string;
  funding: string;
  dateRange: string;
  regionalDoctor: {
    fullName: string;
  };
  nextAppointment: {
    date: string;
  };
};
function FMDExamValues({ data }: Props) {
  const { t } = useTranslation();

  return (
    <Row>
      <Col className="py-3">
        <Form.Group>
          <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.date')}` </FormLabel>
          <span className="text-dark text-capitalize">{data.date}</span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.result')}` </FormLabel>
          <span className="text-dark text-capitalize">
            {t(`examinations:fmdExam.general.resultValue${data.result}`)}
          </span>
        </Form.Group>
        {data.result === FMDExaminationResult.referedToRegionalDoctor ? (
          <>
            <Form.Group>
              <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.funding')}` </FormLabel>
              <span className="text-dark text-capitalize">
                {t(`examinations:fmdExam.general.fundingValues.${data.funding}`)}
              </span>
            </Form.Group>
            <Form.Group>
              <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.regd')}` </FormLabel>
              <span className="text-dark text-capitalize">{data.regionalDoctor.fullName}</span>
            </Form.Group>
            <Form.Group>
              <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.dateRange')}` </FormLabel>
              <span className="text-dark text-capitalize">{data.dateRange}</span>
            </Form.Group>
          </>
        ) : null}
        {data.result === FMDExaminationResult.wrongScreeningResults ? (
          <Form.Group>
            <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.notes')}` </FormLabel>
            <div className="text-dark text-capitalize px-3 mb-3">{data.notes}</div>
          </Form.Group>
        ) : null}
        <Form.Group>
          <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.nextAppointment')}` </FormLabel>
          <span className="text-dark text-capitalize">{data.nextAppointment ? t('yes') : t('no')}</span>
        </Form.Group>
        {data.nextAppointment ? (
          <Form.Group>
            <FormLabel className="text-muted me-1">{t('examinations:fmdExam.general.nextAppointmentDate')}` </FormLabel>
            <span className="text-dark text-capitalize">{data.nextAppointment.date}</span>
          </Form.Group>
        ) : null}
      </Col>
    </Row>
  );
}

FMDExamValues.propTypes = {};

export default FMDExamValues;
