import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../../components/Separator/Separator';
import AddressForm from '../../../../../components/AddressForm/AddressForm';
import axios from '../../../../../axios';
import CustomAutocomplete from '../../../../../components/CustomSelect/CustomAutocomplete';
import { Row } from 'react-bootstrap';

const School = ({ register, control, setValue, errors, data }) => {
  const [school, setSchool] = useState(data);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setSchool({ ...data });
      setValue('school.name', data.name);
    }
  }, [data]);

  const handleSchoolSearch = useCallback(async q => {
    if (q.length) {
      const response = await axios.get('/school/list', { params: { all: true, q } });
      if (response.status === 200) {
        return [...response.data.data.schools];
      }
    } else {
      return [];
    }
  }, []);

  return (
    <>
      <Separator text={t('examinations:screenings.school')} />
      <Row>
        <CustomAutocomplete
          name="school.name"
          register={register}
          error={errors?.school?.name}
          onSelect={setSchool}
          selected={school}
          onChange={handleSchoolSearch}
          labelField="name"
          controlProps={{ rules: { validate: v => {
            return v !== undefined && v !== '' || 'required';
          } } }}
          valueFieldName="school.id"
          setValue={setValue}
        />

        <AddressForm
          control={control}
          register={register}
          setValue={setValue}
          nestedKey="school"
          errors={errors}
          data={school}
          streetAddressNeeded={false}
          editable={!school?.id}
        />
      </Row>
    </>
  );
};
export default School;
