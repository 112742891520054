import React from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import Diagnosis from './Diagnosis/Diagnosis';
import DiagnosisDescription from './Diagnosis/DiagnosisDescription';
import AnotherIndication from './Diagnosis/AnotherIndication';
import GlassesPrescribed from './Diagnosis/GlassesPrescribed';
import { RegDExamSubProps } from '../AddREGDExam';

const GlassesPrescription = ({ register, control, setValue, errors, data, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Separator text={`${t('examinations:regdExam.diagnos')}`} />
      <Diagnosis register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <DiagnosisDescription register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <GlassesPrescribed register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <AnotherIndication register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>

    </>
  );
};
export default GlassesPrescription;
