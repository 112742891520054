import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const BinocularVisionTest = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState();
  const [lValue, setLValue] = useState();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      { 
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );

  const deviations = [
    'monocular',
    'interchangeableMonocular',
    'simultaneously'
  ];

  const [deviationsValues, setDeviationsValues] = useState(Object.assign(...deviations.map(k => ({ [k]: false }))));

  const mDeviationsValues = useMemo(
    () => [...deviations.map(_=> {
      return {
        'label': t(`examinations:regdExam.binocularVisionTest.${_}`),
        'checked': deviationsValues[_ as keyof typeof deviationsValues],
        'value': _
      };
    })],
    [deviationsValues]
  );

  useEffect(()=>{
    setValue('binocularVisionTest.deviations', deviationsValues);
  }, [deviationsValues]);


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.binocularVisionTest.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'binocularVisionTest.r.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.binocularVisionTest?.r?.v,
            }}
            onChange={v => {
              setRValue(v);
              setValue('binocularVisionTest.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'binocularVisionTest.l.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.binocularVisionTest?.l?.v,
            }}
            onChange={v => {
              setLValue(v);
              setValue('binocularVisionTest.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomCheckboxGroup
            label={t('examinations:regdExam.binocularVisionTest.deviationKind')}
            labelClass="d-block"
            items={mDeviationsValues}
            inline={false}
            disabled={!rValue && !lValue}
            controlProps={{
              name: 'binocularVisionTest.deviations',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.binocularVisionTest?.deviationKind
            }}
            onChange={(v, c) => {
              clearErrors('binocularVisionTest');
              setDeviationsValues({
                ...deviationsValues,
                [v]: c
              });
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};
export default BinocularVisionTest;
