import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const CooperativeEyeWorkTest = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState();
  const [lValue, setLValue] = useState();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      { 
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.cooperativeEyeWorkTest.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'cooperativeEyeWorkTest.r.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.cooperativeEyeWorkTest?.r?.v,
            }}
            onChange={v => {
              setRValue(v);
              setValue('cooperativeEyeWorkTest.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'cooperativeEyeWorkTest.l.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.cooperativeEyeWorkTest?.l?.v,
            }}
            onChange={v => {
              setLValue(v);
              setValue('cooperativeEyeWorkTest.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomFormGroup
            register={{ ...register('cooperativeEyeWorkTest.convClosestPoint', { required: rValue === true }) }}
            label={t('examinations:regdExam.cooperativeEyeWorkTest.convClosestPoint')}
            appendContent={t('examinations:regdExam.mm')}
            error={errors.cooperativeEyeWorkTest?.convClosestPoint}
            groupProps={{ className: 'mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'cooperativeEyeWorkTest.convClosestPoint',
              name: 'cooperativeEyeWorkTest.convClosestPoint',
              defaultValue: data?.cooperativeEyeWorkTest?.convClosestPoint || '',
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};
export default CooperativeEyeWorkTest;
