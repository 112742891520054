import React, { useEffect, useState } from 'react';
import Separator from '../../../components/Separator/Separator';
import { Col, Form, Row } from 'react-bootstrap';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import { Controller } from 'react-hook-form';
import { EmailRegex, PhoneRegex, RelationToChild } from '../../../constants';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { useTranslation } from 'react-i18next';

const AdultContacts = ({ register, control, errors, data }) => {
  const { t } = useTranslation();
  const [relationToChild, setRelationToChild] = useState(RelationToChild[0]);

  useEffect(() => {
    if (data) {
      setRelationToChild(RelationToChild.find(_ => _.value === data?.parent?.relation.toLowerCase()));
    }
  }, [data]);

  return (
    <>
      <Separator text={`${t('patient:adultContacts.title')}`} />
      <Row>
        <CustomFormGroup
          label={t('patient:adultContacts.firstName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.parent?.firstName}
          fixedLabel={true}
          controlProps={{
            id: 'parentFirstName',
            name: 'parent.firstName',
            defaultValue: data?.parent?.firstName || ''
          }}
          register={register('parent.firstName', {
            required: true
          })}
        />
        <CustomFormGroup
          label={t('patient:adultContacts.lastName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors?.parent?.lastName}
          fixedLabel={true}
          controlProps={{
            id: 'parentLastName',
            name: 'parent.lastName',
            defaultValue: data?.parent?.lastName || ''
          }}
          register={register('parent.lastName', {
            required: true
          })}
        />
      </Row>
      <Row>
        <CustomFormGroup
          label={t('patient:adultContacts.fatherName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.parent?.fatherName}
          fixedLabel={true}
          controlProps={{
            id: 'parentFatherName',
            name: 'parent.fatherName',
            defaultValue: data?.parent?.fatherName || ''
          }}
          register={register('parent.fatherName')}
        />
        <Form.Group as={Col} md={6} className="mb-3">
          <Form.Label>{t('patient:adultContacts.relationToChild')}</Form.Label>
          <Controller
            name="parent.relation"
            control={control}
            defaultValue={RelationToChild[0].value}
            render={({ field: { onChange } }) => {
              return (
                <CustomSelect
                  register={{ ...register('parent.relation', relationToChild.value) }}
                  name="relation"
                  options={RelationToChild}
                  value={relationToChild.value ? relationToChild.value : ''}
                  contentRenderer={() => (
                    <span
                      className="text-size-smaller">{relationToChild.value && t('patient:adultContacts.' + relationToChild.value)}</span>
                  )}
                  onChange={val => {
                    setRelationToChild(val[0]);
                    onChange(val[0].value);
                  }}
                />
              );
            }}
          />

        </Form.Group>
      </Row>
      <Row>
        <CustomFormGroup
          label={t('patient:adultContacts.mobilePhone')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.parent?.mobilePhone}
          fixedLabel={true}
          controlProps={{
            id: 'mobilePhone',
            name: 'parent.mobilePhone',
            defaultValue: data?.parent?.mobilePhone
          }}
          register={register('parent.mobilePhone', {
            required: true,
            pattern: {
              value: PhoneRegex,
              message: 'invalidPhoneNumber'
            }
          })}
        />
        <CustomFormGroup
          label={t('patient:adultContacts.homePhone')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.parent?.phone}
          fixedLabel={true}
          controlProps={{
            id: 'phone',
            name: 'parent.phone',
            defaultValue: data?.parent?.phone
          }}
          register={register('parent.phone', {
            pattern: {
              value: PhoneRegex,
              message: 'invalidPhoneNumber'
            }
          })}
        />
      </Row>
      <Row>
        <CustomFormGroup
          label={t('patient:adultContacts.email')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.parent?.email}
          fixedLabel={true}
          controlProps={{
            id: 'email',
            name: 'parent.email',
            defaultValue: data?.parent?.email || ''
          }}
          register={register('parent.email', {
            pattern: {
              value: EmailRegex,
              message: 'invalidEmail'
            }
          })}
        />
      </Row>
    </>
  );

};
export default AdultContacts;
