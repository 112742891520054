import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Separator from '../Separator/Separator';
import { YerevanCommunities, CountryRegions, Yerevan } from '../../constants';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomFormGroup from '../CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../CustomRadioGroup/CustomRadioGroup';

function AddressForm({
  title,
  control,
  data,
  register,
  errors,
  setValue,
  nestedKey,
  streetAddressNeeded = true,
  streetAddressRequired = true,
  editable = true
}) {
  const { t } = useTranslation();
  const yerevan = CountryRegions.find(_ => _.key === 'yerevan');
  const regions = CountryRegions.filter(_ => _.key !== 'yerevan');

  const [selectedRegion, setSelectedRegion] = useState(regions[0]);
  const [selectedCommunity, setSelectedCommunity] = useState(YerevanCommunities[0]);
  const [settlementType, setSettlementType] = useState();

  const keyPrefix = nestedKey ? nestedKey + '.' : '';

  useEffect(() => {
    if (selectedRegion.key === 'yerevan') {
      setSettlementType('city');
      setSelectedCommunity(YerevanCommunities[0]);
      setValue(keyPrefix + 'settlementType', 'city');
      setValue(keyPrefix + 'settlement', Yerevan);
      setValue(keyPrefix + 'community', YerevanCommunities[0].id);
    } else {
      setValue(keyPrefix + 'settlementType', data?.settlementType);
      setSettlementType(data?.settlementType);
      setValue(keyPrefix + 'settlement', data?.settlement);
      setSelectedCommunity(null);
      setValue(keyPrefix + 'community', null);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (data) {
      setSelectedRegion(CountryRegions.find(_ => _.value === data.region));
      setSettlementType(data.settlementType);
      setValue(keyPrefix + 'settlement', data.settlement);
      setValue(keyPrefix + 'address', data.address);     
      setValue(keyPrefix + 'region', data.region);
      setValue(keyPrefix + 'settlementType', data.settlementType);
      setValue(keyPrefix + 'settlement', data.settlement);

      if (data.community) {
        setSelectedCommunity(YerevanCommunities.find(_ => _.value === data.community));
        setValue(keyPrefix + 'community', data.community);
      }
    }
  }, [data]);

  const settlmentTypes = useMemo(
    () => [
      {
        label: t('address.settlementType.city'),
        checked: settlementType === 'city',
        value: 'city',
        disabled: selectedRegion.key === 'yerevan',
      },
      {
        label: t('address.settlementType.village'),
        checked: settlementType === 'village',
        value: 'village',
        disabled: selectedRegion.key === 'yerevan',
      },
    ],
    [settlementType, selectedRegion]
  );

  return (
    <>
      {title && <Separator text={title} />}
      <Row>
        <Form.Group as={Col} md={6} className="mb-3">
          <Form.Label>{t('address.region.title')}</Form.Label>
          <Controller
            name={`${keyPrefix}region`}
            control={control}
            defaultValue={regions[0].value}
            value={selectedRegion ? selectedRegion.value : regions[0].value}
            render={({ field: { onChange, name } }) => {
              return (
                <Row>
                  <Col md={6}>
                    <CustomSelect
                      disabled={(!selectedRegion && selectedRegion.key === 'yerevan') || !editable}
                      options={regions}
                      values={selectedRegion ? [selectedRegion] : []}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{selectedRegion && selectedRegion.label}</span>
                      )}
                      onChange={selected => {
                        setSelectedRegion(selected[0]);
                        onChange(selected[0].value);
                      }}
                    />
                  </Col>
                  <Col md={6} className="pt-2">
                    <Form.Check
                      label={t('address.region.yerevan')}
                      checked={selectedRegion && selectedRegion.value === yerevan.value}
                      name={name}
                      id="form-check-community"
                      onChange={v => {
                        setSelectedRegion(yerevan);
                        onChange(v);
                      }}
                      type="radio"
                      disabled={!editable}
                    />
                  </Col>
                </Row>
              );
            }}
          />
        </Form.Group>
        <Col md={6}>
          <Row>
            <Controller
              name={`${keyPrefix}settlement`}
              control={control}
              render={() => (
                <CustomFormGroup
                  label={t('address.settlement')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={nestedKey ? errors[nestedKey]?.settlement : errors.settlement}
                  fixedLabel={true}
                  controlProps={{
                    id: `${keyPrefix}settlement`,
                    name: `${keyPrefix}settlement`,
                    defaultValue: data?.settlement || '',
                    disabled: (selectedRegion && selectedRegion.value === 1) || !editable,
                  }}
                  register={register(`${keyPrefix}settlement`, {
                    required: true,
                  })}
                />
              )}
            />
            <Col md={6}>
              <CustomRadioGroup
                label={t('address.settlementType.title')}
                labelClass="d-block mb-3"
                items={settlmentTypes}
                controlProps={{
                  name: `${keyPrefix}settlementType`,
                  control,
                  rules: { validate: v => v !== undefined || 'required' },
                  defaultValue: data?.settlementType,
                  disabled: !editable,
                }}
                onChange={v => {
                  setSettlementType(v);
                  setValue(keyPrefix + 'settlementType', v);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {streetAddressNeeded && (
          <Controller
            name={`${keyPrefix}address`}
            control={control}
            render={() => (
              <CustomFormGroup
                label={t('address.streetAddress')}
                groupProps={{ className: 'col-md-6 mb-3' }}
                error={nestedKey ? errors[nestedKey]?.address : errors.address}
                fixedLabel={true}
                controlProps={{
                  id: `${keyPrefix}address`,
                  name: `${keyPrefix}address`,
                  defaultValue: data?.address || '',
                  disabled: !editable,
                }}
                register={register(`${keyPrefix}address`, {
                  required: streetAddressRequired,
                })}
              />
            )}
          />
        )}
        {selectedRegion && selectedRegion.value === 1 && (
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Label>{t('address.community.title')}</Form.Label>
            <Controller
              name={`${keyPrefix}community`}
              control={control}
              defaultValue={selectedCommunity?.value}
              render={({ field: { onChange, name } }) => {
                return (
                  <CustomSelect
                    register={{ ...register(`${keyPrefix}community`) }}
                    disabled={!editable}
                    name={name}
                    options={YerevanCommunities}
                    value={selectedCommunity?.value}
                    contentRenderer={() => (
                      <span className="text-size-smaller">
                        {selectedCommunity
                          ? YerevanCommunities.find(_ => _.value === selectedCommunity.value).label
                          : YerevanCommunities[0].label}
                      </span>
                    )}
                    onChange={val => {
                      setSelectedCommunity(val[0]);
                      onChange(val[0].value);
                    }}
                  />
                );
              }}
            />
          </Form.Group>
        )}
      </Row>
    </>
  );
}

AddressForm.propTypes = {};

export default AddressForm;
