import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/index.ts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import './ForgotPassword.scss';
import { Alert, Button, Card, Col, Container, Form, Image, Nav, NavItem, Row, Spinner } from 'react-bootstrap';
import i18n from '../../../i18n';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import axios from '../../../axios';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { EmailRegex } from '../../../constants';

const YEAR = new Date().getFullYear();

const ForgotPassword = () => {
  const { t } = useTranslation();
  const state = useContext(AppContext);
  const { locale } = state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const { handleSubmit, formState: { errors }, register } = useForm();

  const handleSend = async values => {
    try {
      setLoading(true);
      const response = await axios.post('/auth/password-request', values);
      if (response.status === 200) {
        setShowSuccess(true);
      } else {
        setError(response.error);
      }
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };


  return (
    <div className="auth-page">
      <Helmet>
        <title>{t('pageTitles:resetPassword') + t('pageTitles:tail')}</title>
      </Helmet>
      <Container>
        <Row className="vh-100 justify-content-center">
          <Col md={12} className="align-self-center">
          <Row>
                <Col lg={6} className="mx-auto text-center">
                      <NavLink to="/" className="logo logo-admin">
                          <Image src={logo} height="70" alt="logo"
                                 className="auth-logo mb-4" />
                        </NavLink>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mx-auto">
                  <Nav className="justify-content-end lang">
                    <NavItem>
                      <Nav.Link className={`p-2 ${locale.value === 'en' ? 'active' : ''}`} onClick={() => {
                        locale.setValue('en');
                        i18n.changeLanguage('en');
                      }}><Image src="/images/flags/en_flag.jpg"
                                alt=""
                                height="15" className="me-2" />{t('languages.en')}</Nav.Link>
                    </NavItem>
                    <NavItem className="nav-link p-2">|</NavItem>
                    <NavItem>
                      <Nav.Link className={`p-2 ${locale.value === 'am' ? 'active' : ''}`} onClick={() => {
                        locale.setValue('am');
                        i18n.changeLanguage('am');
                      }}><Image src="/images/flags/am_flag.jpg"
                                alt=""
                                height="15" className="me-2" />{t('languages.am')}</Nav.Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            <Row>
              <Col lg={6} className="mx-auto">
                <Card>
                  <Card.Body className={'p-0 auth-header-box'}>
                    <div className="text-center p-3">
                      <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{t('auth:resetPassword.title')}</h4>
                      <p className="text-muted  mb-0">{t('auth:resetPassword.subtitle')}</p>
                    </div>
                  </Card.Body>
                  <Card.Body className="pt-0">
                    {!showSuccess ?
                      <Form className="my-4" onSubmit={handleSubmit(handleSend)}>
                        <CustomFormGroup
                          disabled={loading}
                          fixedLabel={true}
                          label={t('auth:email')}
                          error={errors.email || (error && { message: 'wrongEmailAddress' })}
                          groupProps={{ className: 'mb-3' }}
                          controlProps={{
                            id: 'email',
                            name: 'email',
                            disabled: loading,
                            placeholder: t('auth:emailPlaceholder')
                          }}
                          register={register('email', {
                            required: true,
                            pattern: {
                              value: EmailRegex,
                              message: 'invalidEmail'
                            }
                          })}
                        />
                        <Form.Group className="mb-0">
                          <Row>
                            <Col>
                              <Button className="btn btn-primary w-100"
                                      type="submit" disabled={loading}>
                                {loading && (
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"
                                           className="spinner" />
                                )}
                                {t('auth:resetPassword.reset')}<i
                                className="fas fa-sign-in-alt ms-1" />
                              </Button>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form> : <Alert className="mt-3" variant="info">{t('auth:resetPassword.checkEmail')}</Alert>}
                    <div className="m-3 text-center text-muted">
                      <NavLink to={`/${locale.value}/sign-in`}
                               className="text-primary ms-2 text-decoration-none"> {t('auth:resetPassword.backToLogin')}
                      </NavLink>
                    </div>
                  </Card.Body>
                  <div className="card-body bg-light-alt text-center">
                    &copy;
                    {YEAR}
                    <span className="m-2">{t('aecp')}</span>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>

    </div>
  );
};
export default ForgotPassword;
