import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Pagination.scss';

function Pagination({ pagingCount, setPage, selectedPage = 1 }) {
  const { t } = useTranslation();
  const pageBound = 3;
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [upperPageBound, setUpperPageBound] = useState(3);
  const [lowerPageBound, setLowerPageBound] = useState(0);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState(false);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    setPageNumbers(Array.from({ length: pagingCount }, (_, i) => i + 1));
    setIsNextBtnActive(pagingCount > 1 && selectedPage < pagingCount);
    setIsPrevBtnActive(selectedPage > 1);
  }, []);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleClick = targetPage => {
    setCurrentPage(targetPage);
    setPrevAndNextBtnState(targetPage);
  };

  const handlePrevClick = () => {
    if ((currentPage - 1) % pageBound === 0) {
      setUpperPageBound(upperPageBound - pageBound);
      setLowerPageBound(lowerPageBound - pageBound);
    }
    let targetPage = currentPage - 1;
    setCurrentPage(targetPage);
    setPrevAndNextBtnState(targetPage);
  };

  const handleNextClick = () => {
    if (currentPage + 1 > upperPageBound) {
      setUpperPageBound(upperPageBound + pageBound);
      setLowerPageBound(lowerPageBound + pageBound);
    }
    let targetPage = currentPage + 1;
    setCurrentPage(targetPage);
    setPrevAndNextBtnState(targetPage);
  };

  const setPrevAndNextBtnState = targetPage => {
    if (pagingCount === targetPage) {
      setIsNextBtnActive(false);
      setIsPrevBtnActive(pagingCount > 1);
    } else if (targetPage === 1) {
      setIsNextBtnActive(true);
      setIsPrevBtnActive(false);
    } else if (pagingCount > 1) {
      setIsNextBtnActive(true);
      setIsPrevBtnActive(true);
    }
  };

  const btnIncrementClick = () => {
    setUpperPageBound(upperPageBound + pageBound);
    setLowerPageBound(lowerPageBound + pageBound);

    let targetPage = upperPageBound + 1;
    setCurrentPage(targetPage);
    setPrevAndNextBtnState(targetPage);
  };

  const btnDecrementClick = () => {
    setUpperPageBound(upperPageBound - pageBound);
    setLowerPageBound(lowerPageBound - pageBound);

    let targetPage = upperPageBound - pageBound;
    setCurrentPage(targetPage);
    setPrevAndNextBtnState(targetPage);
  };

  const pages = [];
  let attachPrevDots = true,
    attachNextDots = true;

  pageNumbers.forEach((number, i) => {
    if (i == 0 || (i >= currentPage - 3 && i < currentPage + 2) || i == currentPage - 1) {
      pages.push(
        <BootstrapPagination.Item
          id={number}
          key={number}
          active={currentPage - 1 === i}
          onClick={() => handleClick(number)}
        >
          {number}
        </BootstrapPagination.Item>
      );
    } else {
      if (attachPrevDots && currentPage >= 3 && i == currentPage - 4) {
        pages.push(<BootstrapPagination.Ellipsis text="Previous" onClick={btnDecrementClick} />);
        attachPrevDots = false;
      }

      if (attachNextDots && currentPage <= pagingCount - 3 && i == currentPage + 2) {
        pages.push(<BootstrapPagination.Ellipsis text="Previous" onClick={btnIncrementClick} />);
        attachNextDots = false;
      }
    }
  });

  return (
    <>
      <BootstrapPagination size="sm" className="mb-0">
        <BootstrapPagination.Item className={!isPrevBtnActive && 'disabled'} onClick={handlePrevClick}>
          <span className="prev">{t('prev')}</span>
        </BootstrapPagination.Item>
        {pages}
        <BootstrapPagination.Item className={!isNextBtnActive && 'disabled'} onClick={handleNextClick}>
          <span className="next">{t('next')}</span>
        </BootstrapPagination.Item>
      </BootstrapPagination>
    </>
  );
}

Pagination.propTypes = {
  pagingCount: PropTypes.number.isRequired,
};
export default Pagination;
