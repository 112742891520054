import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';

const EyeSynchronousMovementCheck = ({ control, data, setValue }) => {
  const { t } = useTranslation();
  const [testDuration, setTestDuration] = useState(data?.eyeSynchronousMovementCheck?.b?.t);
  const [bValue, setBValue] = useState(data?.eyeSynchronousMovementCheck?.b?.v);

  const values = useMemo(
    () => [
      {
        label: t('yes'),
        checked: bValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: bValue === false,
        value: false,
      },
    ],
    [bValue]
  );

  
  useEffect(() => {
    setValue('eyeSynchronousMovementCheck.b.t', data?.eyeSynchronousMovementCheck?.b?.t);
    setValue('eyeSynchronousMovementCheck.b.v', data?.eyeSynchronousMovementCheck?.b?.v);
  }, []);

  const durations = useMemo(
    () => [
      {
        label: t('examinations:screenings.eyeSynchronousMovementCheck.1'),
        checked: testDuration === 1,
        value: 1,
      },
      {
        label: t('examinations:screenings.eyeSynchronousMovementCheck.2'),
        checked: testDuration === 2,
        value: 2,
      },
      {
        label: t('examinations:screenings.eyeSynchronousMovementCheck.3'),
        checked: testDuration === 3,
        value: 3,
      },
      {
        label: t('examinations:screenings.eyeSynchronousMovementCheck.4'),
        checked: testDuration === 4,
        value: 4,
      },
    ],
    [testDuration]
  );

  return (
    <>
      <Separator text={`${t('examinations:screenings.eyeSynchronousMovementCheck.title')}`} hint={t('examinations:screenings.eyeSynchronousMovementCheck.hint')}/>
      <Row>
        <Col><h5>{t('examinations:bothEyes')}</h5></Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomRadioGroup
            label={t('examinations:screenings.eyeSynchronousMovementCheck.testDuration')}
            labelClass="d-block"
            inlineList={false}
            items={durations}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'eyeSynchronousMovementCheck.b.t',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.eyeSynchronousMovementCheck?.b?.t,
            }}
            onChange={v => {
              setTestDuration(v);
              setValue('eyeSynchronousMovementCheck.b.t', v);
            }}
          />
        </Col>
        <Col md={6}>
          <CustomRadioGroup
            label={t('examinations:screenings.eyeSynchronousMovementCheck.correctlyReadsSymbols')}
            labelClass="d-block"
            items={values}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'eyeSynchronousMovementCheck.b.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.eyeSynchronousMovementCheck?.b?.v,
            }}
            onChange={v => {
              setBValue(v);
              setValue('eyeSynchronousMovementCheck.b.v', v);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default EyeSynchronousMovementCheck;
