import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import FileThumbnail from '../../../../../components/FileThumbnail/FileThumbnail';
import useCloudinaryWidget from '../../../../../hooks/useCloudinaryWidget';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

const WelchAllynDeviceDiagnostics = ({ patientId, register, control, errors, data, setValue, clearErrors }) => {
  const { t } = useTranslation();

  const {
    showWidget: showWelchAllynWidget,
    files: welchAllynFiles,
    tick: tick1,
  } = useCloudinaryWidget({
    folder: `patient/${patientId}/screening/welchAllynDeviceFiles/`,
  });

  const [bValue, setBValue] = useState(data?.welchAllynDeviceDiagnostics?.v);
  const [nValue, setNValue] = useState(data?.welchAllynDeviceDiagnostics?.n);

  const values = useMemo(
    () => [
      {
        label: t('yes'),
        checked: bValue === true,
        value: true
      },
      {
        label: t('no'),
        checked: bValue === false,
        value: false
      }
    ],
    [bValue]
  );

  const nValues = useMemo(
    () => [
      {
        label: t('examinations:screenings.welchAllynDeviceDiagnostics.issueNotFound'),
        checked: nValue === true,
        value: true
      },
      {
        label: t('examinations:screenings.welchAllynDeviceDiagnostics.issueFound'),
        checked: nValue === false,
        value: false
      }
    ],
    [nValue]
  );

  useEffect(() => {
    if (welchAllynFiles?.length) {
      setValue('welchAllynDeviceFiles', welchAllynFiles);
      clearErrors('welchAllynDeviceFiles');
    }
  }, [welchAllynFiles]);
  
  useEffect(() => {
    setValue('welchAllynDeviceDiagnostics.v', data?.welchAllynDeviceDiagnostics?.v);
    setValue('welchAllynDeviceDiagnostics.n', data?.welchAllynDeviceDiagnostics?.n);
  }, []);


  return (
    <>
      <Separator text={t('examinations:screenings.welchAllynDeviceDiagnostics.title')} hasMargin={false} hint={t('examinations:screenings.welchAllynDeviceDiagnostics.hint')}/>
      <Row>
        <Col md={4}>
          <CustomRadioGroup
            label={t('examinations:screenings.welchAllynDeviceDiagnostics.wasTheDiagnosticsPerformed')}
            labelClass="d-block"
            items={values}
            controlProps={{
              name: 'welchAllynDeviceDiagnostics.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.welchAllynDeviceDiagnostics?.v
            }}
            onChange={v => {
              clearErrors('welchAllynDeviceDiagnostics.n');
              clearErrors('welchAllynDeviceDiagnostics.id');
              setBValue(v);
              setValue('welchAllynDeviceDiagnostics.v', v);
            }}
          />
        </Col>


        <Col md={4}>
          <CustomRadioGroup
            label={t('examinations:screenings.welchAllynDeviceDiagnostics.result')}
            labelClass="d-block"
            items={nValues}
            disabled={!bValue}
            controlProps={{
              name: 'welchAllynDeviceDiagnostics.n',
              control,
              rules: { validate: v => bValue !== undefined || 'required' },
              defaultValue: data?.welchAllynDeviceDiagnostics?.n
            }}
            onChange={v => {
              setNValue(v);
              setValue('welchAllynDeviceDiagnostics.n', v);
            }}
          />
        </Col>
        <CustomFormGroup
          register={{ ...register('welchAllynDeviceDiagnostics.id', { required: !!bValue }) }}
          label={t('examinations:screenings.welchAllynDeviceDiagnostics.deviceLocalPatientID')}
          error={errors.welchAllynDeviceDiagnostics?.id}
          groupProps={{ className: 'col-md-4 mb-3' }}
          fixedLabel={true}
          controlProps={{
            id: 'welchAllynDeviceDiagnostics.id',
            name: 'welchAllynDeviceDiagnostics.id',
            defaultValue: data?.welchAllynDeviceDiagnostics?.id || '',
            disabled: !bValue
          }}
        />
      </Row>
      <Row>
        <Col>
          <div className="file-box-content">
            <Controller
              control={control}
              name="welchAllynDeviceFiles"
              rules={{ required: !!bValue }}
              defaultValue={data?.welchAllynDeviceFiles}
              render={({ field: { value }, fieldState: {error} }) => (
                <>
                  <Form.Group className={classNames({ 'is-invalid': !!error })}>
                    <div className={classNames({
                      'file-box': true,
                      'cursor-pointer': true,
                      'bg-light': !error,
                      'bg-soft-danger': error,
                      'border-danger': !!error,
                      'mb-0': true,
                    })} 
                    onClick={showWelchAllynWidget}>
                      <a className="download-icon-link">
                        <i className="las la-download file-download-icon text-white"></i>
                      </a>
                      <div className="text-center">
                        <i className="las la-plus text-info"></i>
                        <h6 className="text-truncate">{t('examinations:attachFile')}</h6>
                        <small className="text-muted">{t('examinations:attachFileHint')}</small>
                      </div>
                    </div>
                    {value?.map(file => (
                      <FileThumbnail key={file.id} file={file} type={1} actionIcon="times" />
                    ))}
                  </Form.Group>
                  {error ? (
                    <Form.Control.Feedback type="invalid">
                      {<span id="error-attached-file">{t('errors:validationMessages.required')}</span>}
                    </Form.Control.Feedback>
                  ) : null}
                </>
              )}
            />
            
          </div>
        </Col>
      </Row>
    </>
  );
};
export default WelchAllynDeviceDiagnostics;
