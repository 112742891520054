import React from 'react';
import { REGDExaminationDataType } from '../REGDExamValues';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FileThumbnail, { FileThumbnailType } from '../../../../../../components/FileThumbnail/FileThumbnail';

type Props = {
  data: REGDExaminationDataType;
};
function Autorefractometry({ data }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mb-3 m-0 mt-4">{t('examinations:regdExam.autorefractometry.title')}</h5>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel className="me-1">
              {t('examinations:regdExam.isPerformed')}
              {t('formLabelSeparator')}
            </FormLabel>
            <span className="text-dark text-capitalize">{data?.autorefractometryState ? t('yes') : t('no')}</span>
          </FormGroup>
        </Col>
        <Col>
          <p className="card-title my-3">
            <strong>{t('examinations:regdExam.autorefractometry.files')}</strong>
          </p>
          <div className="file-box-content">
            {data?.autorefractometryFiles?.map(file => (
              <FileThumbnail key={file.id} file={file} type={FileThumbnailType.square} actionIcon="times" />
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Autorefractometry;
