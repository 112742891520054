import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import classnames from 'classnames';
import './CustomSelect.scss';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function CustomSelect(props) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const { error, keepSelectedInList = false, dropdownPosition = 'bottom' } = props;
  return (
    <>
      <Select
        className={classnames({
          'form-control': true,
          'react-dropdown-select-open': isOpen,
          'custom-select': true,
          'custom-select-top': dropdownPosition === 'top'
        })}
        searchable={false}
        dropdownGap={0}
        keepSelectedInList={keepSelectedInList}
        onDropdownOpen={onOpen}
        onDropdownClose={onClose}
        {...props}
      />
      {error && (
        <Form.Control.Feedback type="invalid">
          {(error.message && t(`errors:validationMessages.${error.message}`)) ||
          (error.type === 'required' && t('errors:validationMessages.required'))}
        </Form.Control.Feedback>
      )}
    </>
  );
}

CustomSelect.propTypes = {
  multi: PropTypes.bool,
  labelField: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.array,
  optionRenderer: PropTypes.any
};
CustomSelect.defaultProps = {};

export default CustomSelect;
