import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const LacrimalSystem = ({ setValue, data, control }: RegDExamSubProps) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState<boolean>();
  const [lValue, setLValue] = useState<boolean>();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      { 
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.lacrimalSystem.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'lacrimalSystem.r.v',
              control,
              rules: { validate: (v: string) => control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required' },
              defaultValue: data?.lacrimalSystem?.r?.v,
            }}
            onChange={v => {
              setRValue(v as boolean);
              setValue('lacrimalSystem.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'lacrimalSystem.l.v',
              control,
              rules: { validate: (v: string) => control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required' },
              defaultValue: data?.lacrimalSystem?.l?.v,
            }}
            onChange={v => {
              setLValue(v as boolean);
              setValue('lacrimalSystem.l.v', v);
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};
export default LacrimalSystem;
