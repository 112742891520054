import * as actionTypes from '../actionTypes/data';

export const loadData = payload => {
  return {
    type: actionTypes.LOAD_DATA_START,
    payload,
  };
};

export const loadDataDone = payload => {
  return {
    type: actionTypes.LOAD_DATA_DONE,
    payload,
  };
};

export const loadDataFail = payload => {
  return {
    type: actionTypes.LOAD_DATA_FAIL,
    payload,
  };
};


export const loadDataList = payload => {
  return {
    type: actionTypes.LOAD_DATA_LIST_START,
    payload,
  };
};

export const loadDataListDone = payload => {
  return {
    type: actionTypes.LOAD_DATA_LIST_DONE,
    payload,
  };
};

export const loadDataListFail = payload => {
  return {
    type: actionTypes.LOAD_DATA_LIST_FAIL,
    payload,
  };
};