/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AccessControl } from 'accesscontrol';

const AC = new AccessControl();

export const grantPermissions = (grants: object) => {
  AC.setGrants({ I: grants });
};

//@ts-ignore
export const hasPermission = (action: string, resource: string): boolean => AC.can('I')[action](resource).granted;

export default AC as AccessControl;
