import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../../context/index';

function DashboardMenu() {
  const { locale } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="title-box">
        <h6 className="menu-title">{t('menu:dashboard.title')}</h6>
      </div>

      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/dashboard/analytics`}>
            {t('menu:dashboard.analytics')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/dashboard/patients`}>
            {t('menu:dashboard.patients')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/dashboard/stats`}>
            {t('menu:dashboard.stats')}
          </NavLink>
        </li>
      </ul>
    </>
  );
}

DashboardMenu.propTypes = {};

export default DashboardMenu;
