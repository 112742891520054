import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import reducer from './reducers';
import { watchAll } from './sagas';

const devMode = process.env.NODE_ENV !== 'production';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if (devMode) {
  // middleware.push(logger);
}

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => [...getDefaultMiddleware({ thunk: false }), ...middleware],
  devTools: devMode,
  preloadedState: {},
});

sagaMiddleware.run(watchAll);
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

export { RootState, AppDispatch };
