import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';
import { REGDExaminationDataShagkapeniSubType } from '../../REGDExamValues/REGDExamValues';

const Shagkapeni = ({ register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState<boolean>();
  const [lValue, setLValue] = useState<boolean>();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );

  const deviations = ['swelling', 'hypertension', 'production', 'inflammatoryReaction', 'dryWithFolds', 'other'];

  const statuses = ['weak', 'medium', 'strong'];

  const types = ['follicular', 'epidural', 'chronicScarring'];

  const [rDeviationsValues, setRDeviationsValues] = useState<REGDExaminationDataShagkapeniSubType>(
    deviations.reduce((o, key) => ({ ...o, [key]: { v: false, status: '' } }), {})
  );

  const [lDeviationsValues, setLDeviationsValues] = useState<REGDExaminationDataShagkapeniSubType>(
    deviations.reduce((o, key) => ({ ...o, [key]: { v: false, status: '' } }), {})
  );

  useEffect(() => {
    setValue('shagkapeni.r.deviations', rDeviationsValues);
  }, [rDeviationsValues]);

  useEffect(() => {
    setValue('shagkapeni.l.deviations', lDeviationsValues);
  }, [lDeviationsValues]);

  return (
    <Row className="mb-3">
      <h5 className="mb-3 m-0">{t('examinations:regdExam.shagkapeni.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'shagkapeni.r.v',
              control,
              rules: {
                validate: (v: string) =>
                  control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required',
              },
              defaultValue: data?.shagkapeni?.r?.v,
            }}
            onChange={v => {
              setRValue(v as boolean);
              setValue('shagkapeni.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'shagkapeni.l.v',
              control,
              rules: {
                validate: (v: string) =>
                  control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required',
              },
              defaultValue: data?.shagkapeni?.l?.v,
            }}
            onChange={v => {
              setLValue(v as boolean);
              setValue('shagkapeni.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h6>{t('examinations:regdExam.ifDeviation')}</h6>
          {deviations.map(d => (
            <React.Fragment key={'r' + d}>
              <CustomCheckboxGroup
                labelClass="d-block"
                items={[
                  {
                    label: t(`examinations:regdExam.shagkapeni.${d}`),
                    checked: rDeviationsValues[d as keyof typeof rDeviationsValues].v,
                    value: d,
                  },
                ]}
                inline={false}
                disabled={!rValue || control._formValues.stillLampExamState === false}
                controlProps={{
                  name: `shagkapeni.r.deviations.${d}.v`,
                  control,
                  defaultValue: data?.shagkapeni?.r?.deviations?.[d].v,
                }}
                onChange={(v, c) => {
                  clearErrors('shagkapeni');
                  setRDeviationsValues({
                    ...rDeviationsValues,
                    [v]: {
                      ...rDeviationsValues[v],
                      v: c,
                    },
                  });
                }}
              />
              {d === 'other' ? (
                <>
                  <CustomFormGroup
                    register={register(`shagkapeni.r.deviations.${d}.details`)}
                    error={errors.shagkapeni?.r?.deviations?.d?.details}
                    groupProps={{ className: 'col-md-6 mb-3' }}
                    fixedLabel={true}
                    controlProps={{
                      id: `shagkapeni.r.deviations.${d}.details`,
                      name: `shagkapeni.r.deviations.${d}.details`,
                      defaultValue: data?.shagkapeni?.r?.deviations?.d?.details || '',
                      disabled: !rDeviationsValues[d].v || control._formValues.stillLampExamState === false
                    }}
                  />
                </>
              ) : (
                <CustomRadioGroup
                  label={t('examinations:regdExam.manifestation')}
                  labelClass="d-block"
                  items={[
                    ...(d === 'inflammatoryReaction' ? types : statuses).map(s => {
                      return {
                        label: t(`examinations:regdExam.statuses.${s}`),
                        checked: rDeviationsValues[d].status === s,
                        value: s,
                      };
                    }),
                  ]}
                  groupProps={{ className: 'mb-3' }}
                  controlProps={{
                    name: `shagkapeni.r.deviations.${d}.status`,
                    control,
                    defaultValue: data?.shagkapeni?.r?.deviations?.[d]?.status,
                  }}
                  disabled={!rValue || !rDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                  onChange={v => {
                    setRDeviationsValues({
                      ...rDeviationsValues,
                      [d]: {
                        ...rDeviationsValues[d],
                        status: v,
                      },
                    });
                    setValue(`shagkapeni.r.deviations.${d}.status`, v);
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Col>
        <Col md={6}>
          <h6>{t('examinations:regdExam.ifDeviation')}</h6>
          {deviations.map(d => (
            <React.Fragment key={'l' + d}>
              <CustomCheckboxGroup
                labelClass="d-block"
                items={[
                  {
                    label: t(`examinations:regdExam.shagkapeni.${d}`),
                    checked: lDeviationsValues[d as keyof typeof lDeviationsValues].v,
                    value: d,
                  },
                ]}
                inline={false}
                disabled={!lValue || control._formValues.stillLampExamState === false}
                controlProps={{
                  name: `shagkapeni.l.deviations.${d}.v`,
                  control,
                  defaultValue: data?.shagkapeni?.l?.deviations?.[d].v,
                }}
                onChange={(v, c) => {
                  clearErrors('shagkapeni');
                  setLDeviationsValues({
                    ...lDeviationsValues,
                    [v]: {
                      ...lDeviationsValues[v],
                      v: c,
                    },
                  });
                }}
              />
              {d === 'other' ? (
                <>
                  <CustomFormGroup
                    register={{ ...register(`shagkapeni.l.deviations.${d}.details`) }}
                    error={errors.shagkapeni?.l?.deviations?.[d]?.details}
                    groupProps={{ className: 'col-md-6 mb-3' }}
                    fixedLabel={true}
                    controlProps={{
                      id: `shagkapeni.l.deviations.${d}.details`,
                      name: `shagkapeni.l.deviations.${d}.details`,
                      defaultValue: data?.shagkapeni?.l?.deviations?.[d]?.details || '',
                      disabled: !lDeviationsValues[d].v || control._formValues.stillLampExamState === false
                    }}
                  />
                </>
              ) : (
                <CustomRadioGroup
                  label={t('examinations:regdExam.manifestation')}
                  labelClass="d-block"
                  items={[
                    ...(d === 'inflammatoryReaction' ? types : statuses).map(s => {
                      return {
                        label: t(`examinations:regdExam.statuses.${s}`),
                        checked: lDeviationsValues[d].status === s,
                        value: s,
                      };
                    }),
                  ]}
                  groupProps={{ className: 'mb-3' }}
                  controlProps={{
                    name: `shagkapeni.l.deviations.${d}.status`,
                    control,
                    defaultValue: data?.shagkapeni?.l?.deviations?.[d]?.status,
                  }}
                  disabled={!lValue || !lDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                  onChange={v => {
                    setLDeviationsValues({
                      ...lDeviationsValues,
                      [d]: {
                        ...lDeviationsValues[d],
                        status: v,
                      },
                    });
                    setValue(`shagkapeni.l.deviations.${d}.status`, v);
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </Row>
  );
};
export default Shagkapeni;
