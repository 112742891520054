export const reducer = (state, update) => {
  switch (update.prop) {
  case 'locale':
    return {
      ...state,
      locale: {
        ...state.locale,
        value: update.value,
      },
    };
  case 'confirmationModal':
  case 'referFMDModal': {
    return {
      ...state,
      [update.prop]: {
        ...update.value,
        data: {
          variant: state[update.prop].data.variant,
          ...update.value.data,
        },
      },
    };
  }
  default:
    return {
      ...state,
      [update.prop]: update.value,
    };
  }
};
