export const GET_ME_START = 'auth/meStart';
export const GET_ME_DONE = 'auth/meDone';
export const GET_ME_FAIL = 'auth/meFail';


export const CHANGE_LANGUAGE_START = 'user/changeLanguageStart';
export const CHANGE_LANGUAGE_DONE = 'user/changeLanguageDone';
export const CHANGE_LANGUAGE_FAIL = 'user/changeLanguageFail';

export const UPDATE_USER_INFO_START = 'user/updateStart';
export const UPDATE_USER_INFO_DONE = 'user/updateDone';
export const UPDATE_USER_INFO_FAIL = 'user/updateFail';
