import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import './Attachements.scss';
import ModalImage from 'react-modal-image';
import Separator from '../../../../components/Separator/Separator';
import classNames from 'classnames';

const Attachments = ({ setAttachFile, error, data }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const [file, setFile] = useState(data);
  const showWidget = () => {
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'vectar',
        uploadPreset: 'thnyi16e',
        folder: 'aecp-school-screenings/consent-documents',

        sources: ['local', 'camera'],
        language: lang,
        styles: {
          palette: {
            window: 'rgb(82,48,89)',
            sourceBg: '#FFFFFF',
            tabIcon: '#FFFFFF',
            menuIcons: '#D7D7D8',
            link: '#0b51b7',
            action: '#FFFFFF',
            error: '#FFFFFF',
          },
        },
        text: {
          [lang]: {
            or: t('cloudinary:widget.or'),
            back: t('cloudinary:widget.back'),
            close: t('cloudinary:widget.clode'),
            no_results: t('cloudinary:widget.no_results'),
            menu: {
              files: t('cloudinary:widget.files'),
            },
            actions: {
              upload: t('cloudinary:widget.upload'),
              next: t('cloudinary:widget.next'),
            },
            local: {
              browse: t('cloudinary:widget.browse'),
              dd_title_single: t('cloudinary:widget.dd_title_single'),
              dd_title_multi: t('cloudinary:widget.dd_title_multi'),
              drop_title_single: t('cloudinary:widget.drop_title_single'),
              drop_title_multiple: t('cloudinary:widget.drop_title_multiple'),
            },
            crop: {
              title: t('cloudinary:widget.crop_title'),
              crop_btn: t('cloudinary:widget.crop_title'),
              skip_btn: t('cloudinary:widget.skip_btn'),
              reset_btn: t('cloudinary:widget.reset_btn'),
              close_btn: t('cloudinary:widget.close_btn'),
              close_prompt: t('cloudinary:widget.close_prompt'),
              image_error: t('cloudinary:widget.image_error'),
              corner_tooltip: t('cloudinary:widget.corner_tooltip'),
              handle_tooltip: t('cloudinary:widget.handle_tooltip'),
            },
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setFile(result.info);
          setAttachFile(result.info);
        }
      }
    );
    widget.open();
  };

  return (
    <>
      <Separator text={`${t('patient:parentConsent.title')}`} />
      <Row>
        <Col md={6}>
          <Form.Group className={classNames({ 'is-invalid': !file })}>
            <Form.Label>{t('patient:parentConsent.fileHint')}</Form.Label>
            <div className="file-box-content">
              <div
                className={classNames({
                  'file-box': true,
                  'cursor-pointer': true,
                  'bg-light': !error,
                  'bg-soft-danger': error,
                  'border-danger': !!error
                })}
                onClick={showWidget}
              >
                <a className="download-icon-link">
                  <i className="las la-download file-download-icon text-white"></i>
                </a>
                <div className="text-center">
                  <i className="las la-plus text-info"></i>
                  <h6 className="text-truncate">{t('examinations:attachFile')}</h6>
                </div>
              </div>
            </div>
          </Form.Group>
          {error ? (
            <Form.Control.Feedback type="invalid">
              {<span id="error-attached-file">{t('errors:validationMessages.required')}</span>}
            </Form.Control.Feedback>
          ) : null}
        </Col>
        <Col md={6} className="text-center">
          {file && (
            <ModalImage className="mb-3 attach-patient-file" small={file.secure_url} large={file.secure_url} alt="" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Attachments;
