import React, { useMemo } from 'react';
import { Accordion } from 'react-bootstrap';
import FMDExamValues from '../FMDExam/FMDExamValues/FMDExamValues';
import ScreeningValues from '../Screenings/ScreeningValues/ScreeningValues';
import { ExaminationItemType } from './Examination';
import REGDExamValues from '../REGDExam/REGDExamValues/REGDExamValues';

type Props = {
  item: ExaminationItemType;
};
function ExaminationBody({ item }: Props) {
  const ExamComponent = useMemo(() => {
    switch (item.type) {
      case 'schoolscreening':
        return ScreeningValues;

      case 'fmdexamination':
        return FMDExamValues;
      case 'regdexamination':
        return REGDExamValues;
      default:
        return null;
    }
  }, [item.type]);
  return (
    <Accordion.Collapse eventKey={item.id}>
      <div className="px-4 bg-light">{ExamComponent && <ExamComponent data={item.data} />}</div>
    </Accordion.Collapse>
  );
}

ExaminationBody.propTypes = {};

export default ExaminationBody;
