import { all, put, takeLatest, takeEvery, delay } from '@redux-saga/core/effects';
import axios from '../../axios';
import * as actionTypes from '../actionTypes';
import { loadPatientDone, loadPatientFail } from '../actions';

export function* watchPatient() {
  yield all([takeLatest(actionTypes.LOAD_PATIENT_START, loadPatient)]);
}

function* loadPatient(action) {
  const { id } = action.payload;

  try {
    const response = yield axios.get(`/patient/${id}`);

    if (response.status === 200) {
      yield put(loadPatientDone({ data: { patient: response.data.data.patient } }));
    } else {
      yield put(loadPatientFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = (err.toJSON && err.toJSON()) || {};
    delete errJson.config;
    // eslint-disable-next-line
    yield put(loadPatientFail({ error: err.response?.data || errJson || err }));
  }
}
