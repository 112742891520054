import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Alert, Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { hasPermission } from '../../../../accessControl';
import axios from '../../../../axios';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../../../context/index.ts';
import usePatient from '../../../../hooks/usePatient';
import Result from './Result';
import ColorVisionCheck from './ScreeningForm/ColorVisionCheck';
import EyeCooperativeWorkCheck from './ScreeningForm/EyeCooperativeWorkCheck';
import EyeFrontalZoneEvaluation from './ScreeningForm/EyeFrontalZoneEvaluation';
import EyeSynchronousMovementCheck from './ScreeningForm/EyeSynchronousMovementCheck';
import School from './ScreeningForm/School';
import ScreeningDetails from './ScreeningForm/ScreeningDetails';
import SubjectiveSymptoms from './ScreeningForm/SubjectiveSymptoms';
import VisionAcuityInCloseDistance from './ScreeningForm/VisionAcuityInCloseDistance';
import VisionAcuityInFarDistance from './ScreeningForm/VisionAcuityInFarDistance';
import WelchAllynDeviceDiagnostics from './ScreeningForm/WelchAllynDeviceDiagnostics';
import _3DVisionCheck from './ScreeningForm/_3DVisionCheck';

const AddScreening = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [screeningId, setScreeningId] = useState();
  const { patient, loading: patientLoading, error: patientError } = usePatient();
  const [mode, setMode] = useState('form'); //TODO: set to 'form'
  const [hasIssue, setHasIssue] = useState(false); //TODO: set to false
  const { data: authData } = useSelector(state => state.auth);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    clearErrors,
  } = useForm();

  const { showSuccess, showError } = useContext(ToasterContext);

  const addNewScreening = async values => {
    try {
      setLoading(true);
      values.patient = patient.id;
      values.date = moment(values.date).format('DD/MM/yyyy');
      if (!values.nurse && !hasPermission('readAny', 'patient')) {
        values.nurse = authData.user.id;
      }

      const response = await axios.post('screening/create', values);

      showSuccess(t('messages:screeningCreated'));
      setScreeningId(response.data.data.screening.id);
      setTimeout(() => {
        setHasIssue(response.data.data.hasIssue);
        setMode('result');
      }, 2000);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:screeningCreateFailed'));
    }
    setLoading(false);
  };

  if (patientError) {
    return (
      <Alert variant="danger" className="mt-3">
        <p className="text-danger text-center m-2">{patientError.message}</p>
      </Alert>
    );
  }

  const pageTitle = (
    <>
      {t('pageTitles:addNewScreening')}
      {t('formLabelSeparator')}
      <NavLink className="ms-1 text-primary" to={`/${locale.value}/patient/${patient?.id}/view`}>
        {patient?.fullName} (#{patient?.uid})
      </NavLink>
    </>
  );
  const checkKeyDown = e => {
    if (e.code === 'Enter') e.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addNewScreening') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={pageTitle} />
      <Row>
        <Col className=" mx-auto new-examination">
          <Card>
            <Card.Body>
              {patientLoading ? (
                <LoadingIndicator />
              ) : mode === 'form' ? (
                <Form onSubmit={handleSubmit(addNewScreening)} onKeyDown={e => checkKeyDown(e)}>
                  <School register={register} control={control} setValue={setValue} errors={errors} />
                  <ScreeningDetails register={register} control={control} setValue={setValue} errors={errors} />
                  <SubjectiveSymptoms control={control} setValue={setValue} />
                  <WelchAllynDeviceDiagnostics
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                  <VisionAcuityInFarDistance
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                  />
                  <VisionAcuityInCloseDistance
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                  />
                  <EyeFrontalZoneEvaluation register={register} errors={errors} control={control} setValue={setValue} />
                  <_3DVisionCheck register={register} errors={errors} control={control} setValue={setValue} />
                  <EyeSynchronousMovementCheck register={register} control={control} setValue={setValue} />
                  <EyeCooperativeWorkCheck register={register} control={control} setValue={setValue} />
                  <ColorVisionCheck register={register} control={control} setValue={setValue} />
                  <Row className="mt-3">
                    <Col>
                      <FormGroup className="form-group">
                        <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="spinner"
                            />
                          )}
                          {t('buttons.create')}
                        </Button>

                        <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/patient/all`}>
                          {t('buttons.cancel')}
                        </NavLink>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Result hasIssue={hasIssue} patientId={patientId} screeningId={screeningId} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AddScreening;
