import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalsContext, ToasterContext } from '../../../context';
import { Button, Modal, Spinner } from 'react-bootstrap';
import axios from '../../../axios';
import useQuery from '../../../hooks/useQuery';
import FileThumbnail, { FileThumbnailType } from '../../FileThumbnail/FileThumbnail';

type Props = {
  actionCallback?: () => void | undefined;
  params?: { [key: string]: any };
  type?: string;
};

function ExportPatientsModal({ params, type }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const query = useQuery();
  const { showError } = useContext(ToasterContext);
  const { exportPatientsModal } = useContext(ModalsContext);

  const handleClose = () => {
    setFile(null);
    exportPatientsModal.hide();
  };

  const handleAction = async () => {
    setLoading(true);

    //FIXME: check school filter
    const queryParams = { ...params };

    if (query.get('type')) {
      queryParams.type = query.get('type');
    } else if (type) {
      queryParams.type = type;
    }

    try {
      const response = await axios.get('/patient/list?export=true', { params: queryParams });
      if (response.status === 200) {
        setLoading(false);
        setFile(response.data.data);

        // exportPatientsModal.data.action && exportPatientsModal.data.action();
        // exportPatientsModal.hide();
        // actionCallback && actionCallback();
      }
    } catch {
      setLoading(false);
      showError(t('messages:patientExportFailed'));
    }
  };

  return (
    <Modal show={exportPatientsModal.open} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton={!loading} closeVariant="white" className="bg-primary">
        <Modal.Title as="h6" className="m-0 text-white">
          <>
            <i className="fas fa-exclamation-circle me-2" />
            {t('modals:confirmation.exportPatients.title')}
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modals:confirmation.exportPatients.message')}</p>
        {file && (
          <div className="file-box-content d-flex justify-content-center mb-2">
            <FileThumbnail type={FileThumbnailType.square} file={file} actionIcon='download' style={{width: 'auto', margin: 0}}/>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="de-dark" onClick={handleClose} disabled={loading}>
          {t('cancel') as string}
        </Button>
        <Button variant="de-success" disabled={loading} onClick={handleAction}>
          <>
            {loading && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="spinner" />
            )}
            {t('modals:confirmation.exportPatients.btnText') as string}
          </>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportPatientsModal;
