import * as actionTypes from '../actionTypes/patient';

export const loadPatient = payload => {
  return {
    type: actionTypes.LOAD_PATIENT_START,
    payload
  };
};

export const loadPatientDone = payload => {
  return {
    type: actionTypes.LOAD_PATIENT_DONE,
    payload
  };
};

export const loadPatientFail = payload => {
  return {
    type: actionTypes.LOAD_PATIENT_FAIL,
    payload
  };
};
