import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';

const EyeFrontalZoneEvaluation = ({ register, errors, data, control, setValue }) => {
  const { t } = useTranslation();
  const [rValue, setRValue] = useState( data?.eyeFrontalZoneEvaluation?.r?.v);
  const [lValue, setLValue] = useState( data?.eyeFrontalZoneEvaluation?.l?.v);

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:screenings.eyeFrontalZoneEvaluation.hasIssue'),
        checked: lValue === true,
        value: true,
      },
      {
        label: t('examinations:screenings.eyeFrontalZoneEvaluation.norm'),
        checked: lValue === false,
        value: false,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:screenings.eyeFrontalZoneEvaluation.hasIssue'),
        checked: rValue === true,
        value: true,
      },
      {
        label: t('examinations:screenings.eyeFrontalZoneEvaluation.norm'),
        checked: rValue === false,
        value: false,
      },
    ],
    [rValue]
  );

  
  useEffect(() => {
    setValue('eyeFrontalZoneEvaluation.r.v', data?.eyeFrontalZoneEvaluation?.r.v);
    setValue('eyeFrontalZoneEvaluation.l.v', data?.eyeFrontalZoneEvaluation?.l.v);
  }, []);

  return (
    <>
      <Separator text={t('examinations:screenings.eyeFrontalZoneEvaluation.title')} hint={t('examinations:screenings.eyeFrontalZoneEvaluation.hint')} />
      <Row>
        <Col md={6}>
          <h5>{t('examinations:rightEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:screenings.eyeFrontalZoneEvaluation.hasAnyDeviation')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'eyeFrontalZoneEvaluation.r.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.eyeFrontalZoneEvaluation?.r?.v,
            }}
            onChange={v => {
              setRValue(v);
              setValue('eyeFrontalZoneEvaluation.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:leftEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:screenings.eyeFrontalZoneEvaluation.hasAnyDeviation')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'eyeFrontalZoneEvaluation.l.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.eyeFrontalZoneEvaluation?.l?.v,
            }}
            onChange={v => {
              setLValue(v);
              setValue('eyeFrontalZoneEvaluation.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomFormGroup
            register={{ ...register('eyeFrontalZoneEvaluation.r.note', { required: rValue === true }) }}
            label={t('examinations:screenings.eyeFrontalZoneEvaluation.details')}
            error={errors.eyeFrontalZoneEvaluation?.r?.note}
            groupProps={{ className: 'mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'eyeFrontalZoneEvaluation.r.note',
              name: 'eyeFrontalZoneEvaluation.r.note',
              defaultValue: data?.eyeFrontalZoneEvaluation?.r?.note || '',
              disabled: !rValue,
            }}
          />
        </Col>
        <Col md={6}>
          <CustomFormGroup
            register={{ ...register('eyeFrontalZoneEvaluation.l.note', { required: lValue === true }) }}
            label={t('examinations:screenings.eyeFrontalZoneEvaluation.details')}
            error={errors.eyeFrontalZoneEvaluation?.l?.note}
            groupProps={{ className: 'mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'eyeFrontalZoneEvaluation.l.note',
              name: 'eyeFrontalZoneEvaluation.l.note',
              defaultValue: data?.eyeFrontalZoneEvaluation?.l?.note || '',
              disabled: !lValue,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default EyeFrontalZoneEvaluation;
