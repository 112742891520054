import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const DelusionTest = ({ register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState<boolean>();
  const [lValue, setLValue] = useState<boolean>();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      { 
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );

  const deviations = [
    'eyeballIsTiltedInwards',
    'eyeballsAreDeflectedOutwards',
    'eyeballIsTiltedUpwards',
    'eyeballIsTiltedDownwards',
    'other'
  ];

  const [rDeviationsValues, setRDeviationsValues] = useState(deviations.reduce((o, key) => ({ ...o, [key]: false}), {}));
  const [lDeviationsValues, setLDeviationsValues] = useState(deviations.reduce((o, key) => ({ ...o, [key]: false}), {}));

  const mRDeviationsValues = useMemo(
    () => [...deviations.map(_=> {
      return {
        'label': t(`examinations:regdExam.delusionTest.${_}`),
        'checked': rDeviationsValues[_ as keyof typeof rDeviationsValues],
        'value': _
      };
    })],
    [rDeviationsValues]
  );
  const mLDeviationsValues = useMemo(
    () => [...deviations.map(_=> {
      return {
        'label': t(`examinations:regdExam.delusionTest.${_}`),
        'checked': lDeviationsValues[_ as keyof typeof lDeviationsValues],
        'value': _
      };
    })],
    [lDeviationsValues]
  );

  useEffect(()=>{
    setValue('delusionTest.r.deviations', rDeviationsValues);
  }, [rDeviationsValues]);

  useEffect(()=>{
    setValue('delusionTest.l.deviations', lDeviationsValues);
  }, [lDeviationsValues]);



  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.delusionTest.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:rightEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'delusionTest.r.v',
              control,
              rules: { validate: (v: string) => v !== undefined || 'required' },
              defaultValue: data?.delusionTest?.r?.v,
            }}
            onChange={v => {
              setRValue(v as boolean);
              setValue('delusionTest.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:leftEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'delusionTest.l.v',
              control,
              rules: { validate: (v: string) => v !== undefined || 'required' },
              defaultValue: data?.delusionTest?.l?.v,
            }}
            onChange={v => {
              setLValue(v as boolean);
              setValue('delusionTest.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomCheckboxGroup
            label={t('examinations:regdExam.delusionTest.specificDeviation')}
            labelClass="d-block"
            items={mRDeviationsValues}
            inline={false}
            disabled={!rValue}
            controlProps={{
              name: 'delusionTest.r',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.delusionTest?.r
            }}
            onChange={(v, c) => {
              clearErrors('delusionTest');
              setRDeviationsValues({
                ...rDeviationsValues,
                [v]: c
              });
            }}
          />
          <CustomFormGroup
            appendContent={t('examinations:regdExam.specify')}
            key={'other'}
            register={{ ...register('delusionTest.r.deviations.other.details') }}
            error={errors.delusionTest?.r?.deviations?.other?.details}
            groupProps={{ className: 'col-md-6 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'delusionTest.r.deviations.other.details',
              name: 'delusionTest.r.deviations.other.details',
              defaultValue: data?.delusionTest?.r?.deviations?.other?.details || '',
              disabled: !rDeviationsValues.other

            }}
          />
        </Col>
        <Col md={6}>
          <CustomCheckboxGroup            
            label={t('examinations:regdExam.delusionTest.specificDeviation')}
            labelClass="d-block"
            items={mLDeviationsValues}
            inline={false}
            disabled={!lValue}
            controlProps={{
              name: 'delusionTest.l',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.delusionTest?.l,
            }}
            onChange={(v, c) => {
              clearErrors('delusionTest.l');
              setLDeviationsValues({
                ...lDeviationsValues,
                [v]: c
              });
            }}
          />
          <CustomFormGroup
            appendContent={t('examinations:regdExam.specify')}
            key={'other'}
            register={{ ...register('delusionTest.l.deviations.other.details') }}
            error={errors.delusionTest?.l?.deviations?.other?.details}
            groupProps={{ className: 'col-md-6 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'delusionTest.l.deviations.other.details',
              name: 'delusionTest.l.deviations.other.details',
              defaultValue: data?.delusionTest?.l?.deviations?.other?.details || '',
              disabled: !lDeviationsValues.other
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};
export default DelusionTest;
