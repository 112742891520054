import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const MovementsOfTheEyeballs = ({ register, control, setValue, errors, data }) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState();
  const [lValue, setLValue] = useState();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.movementsOfTheEyeballs.title')}</h5>
      {/* <EyeFrontalZoneEvaluation register={register} errors={errors} control={control} setValue={setValue} /> */}

      <Row>
        <Col md={6}>
          <h5>{t('examinations:rightEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'movementsOfTheEyeballs.r.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.movementsOfTheEyeballs?.r?.v,
            }}
            onChange={v => {
              setRValue(v);
              setValue('movementsOfTheEyeballs.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:leftEye')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'movementsOfTheEyeballs.l.v',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.movementsOfTheEyeballs?.l?.v,
            }}
            onChange={v => {
              setLValue(v);
              setValue('movementsOfTheEyeballs.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomFormGroup
            register={{ ...register('movementsOfTheEyeballs.r.note', { required: rValue === true }) }}
            label={t('examinations:regdExam.movementsOfTheEyeballs.details')}
            error={errors.movementsOfTheEyeballs?.r?.note}
            groupProps={{ className: 'mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'movementsOfTheEyeballs.r.note',
              name: 'movementsOfTheEyeballs.r.note',
              defaultValue: data?.movementsOfTheEyeballs.r?.note || '',
              disabled: !rValue,
            }}
          />
        </Col>
        <Col md={6}>
          <CustomFormGroup
            register={{ ...register('movementsOfTheEyeballs.l.note', { required: lValue === true }) }}
            label={t('examinations:regdExam.movementsOfTheEyeballs.details')}
            error={errors.movementsOfTheEyeballs?.l?.note}
            groupProps={{ className: 'mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'movementsOfTheEyeballs.l.note',
              name: 'movementsOfTheEyeballs.l.note',
              defaultValue: data?.movementsOfTheEyeballs.l?.note || '',
              disabled: !lValue,
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};
export default MovementsOfTheEyeballs;
