import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/index.ts';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import i18n from 'i18next';

const Routing = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/:lang/" component={App} />
            <Route exact path="/">
              <App />
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
