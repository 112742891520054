import React, { useCallback, Suspense, useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import PatientList from '../../components/PatientList/PatientList';
import AddPatient from './AddPatient';
import './Patient.scss';
import { AppContext } from '../../context/index.ts';
import { hasPermission } from '../../accessControl';
import useQuery from '../../hooks/useQuery';
import { useHistory } from 'react-router-dom';

const Patients = ({ appLoading }) => {
  let { page } = useParams();
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const query = useQuery();

  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
      case 'all': {
        if (!hasPermission('readAny', 'patient') && !query.get('type')) {
          return <Redirect to={`/${locale.value}/patients/all?type=own&page=1`} />;
        } else {
          tab = <PatientList title={t('pageTitles:allPatients')} />;
        }
        break;
      }
      case 'pending': {
        let title = t('pageTitles:pending');
        if (hasPermission('readOwn', 'pendingPatient1')) {
          title = t('pageTitles:pendingPatients1');
        } else if (hasPermission('readOwn', 'pendingPatient2')) {
          title = t('pageTitles:pendingPatients2');
        }
        tab = <PatientList title={title} type="pending" />;
        break;
      }
      case 'create': {
        tab = <AddPatient />;
        break;
      }
      default: {
        tab = <Redirect to={`/${locale.value}/`} />;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:patients') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page, query.get('type')]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default Patients;
