import { all, put, takeLatest, takeEvery, } from '@redux-saga/core/effects';
import axios from '../../axios';
import * as actionTypes from '../actionTypes';
import {
  setLanguageDone, setLanguageFail,
  getAuthUserDone, getAuthUserFail, resetPasswordDone, resetPasswordFail,
  signInDone,
  signInFail, updateUserInfoDone, updateUserInfoFail, getAuthUser, loadData
} from '../actions';
import { grantPermissions } from '../../accessControl';

export function* watchAuth() {
  yield all([takeLatest(actionTypes.SIGN_IN_START, signIn)]);
  yield all([takeEvery(actionTypes.GET_ME_START, getMe)]);
  yield all([takeLatest(actionTypes.RESET_PASSWORD_START, resetPassword)]);
  yield all([takeLatest(actionTypes.CHANGE_LANGUAGE_START, setLanguage)]);
  yield all([takeLatest(actionTypes.UPDATE_USER_INFO_START, updateUserInfo)]);
}

function* signIn(action) {
  try {
    // yield delay(5000);

    const response = yield axios.post('/auth/login', action.payload);

    if (response.status === 200) {
      grantPermissions(response.data.data.permissions);

      yield put(signInDone({ data: { user: response.data.data.user, permissions: response.data.data.permissions } }));
      axios.defaults.headers.common['Authorization'] = ('Bearer ' + response.data.data.token) || '';
      localStorage.setItem('aecp-ss-auth-token', response.data.data.token);
      localStorage.setItem('aecp-ss-refresh-token', response.data.data.refreshToken);
      yield put(getAuthUser());
      yield put(loadData());
    } else {
      yield put(signInFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = err.toJSON && err.toJSON() || {};
    delete errJson.config;
    // eslint-disable-next-line
    yield put(signInFail({ error: err.response.data || errJson || err }));
  }
}

function* resetPassword(action) {
  try {
    const response = yield axios.post('/user/password-change', { password: action.payload.values.password }, {
      headers: { 'Authorization': `Bearer ${action.payload.token}` }
    });
    if (response.status === 200) {
      localStorage.removeItem('aecp-ss-auth-token');
      localStorage.removeItem('aecp-ss-refresh-token');
      yield put(resetPasswordDone());
    } else {
      yield put(resetPasswordFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = err.toJSON && err.toJSON() || {};
    delete errJson.config;
    yield put(resetPasswordFail({ error: err.response.data || errJson || err }));
  }
}

function* updateUserInfo(action) {
  try {
    const response = yield axios.post(`/user/${action.payload.userId}/update`, { ...action.payload });
    if (response.status === 200) {
      yield put(updateUserInfoDone({
        data: {
          user: response.data.data.user,
          permissions: response.data.data.permissions
        }
      }));
    } else {
      yield put(updateUserInfoFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = err.toJSON && err.toJSON() || {};
    delete errJson.config;
    yield put(updateUserInfoFail({ error: err.response.data || errJson || err }));
  }
}

function* getMe() {
  try {
    const response = yield axios('/user/me');

    if (response.status === 200) {
      grantPermissions(response.data.data.permissions);
      yield put(getAuthUserDone({
        data: {
          user: response.data.data.user,
          permissions: response.data.data.permissions
        }
      }));
    }
  } catch (err) {
    const errJson = err.toJSON && err.toJSON() || {};
    delete errJson.config;
    yield put(getAuthUserFail({ error: err.response?.data || errJson || err }));
  }
}


function* setLanguage(action) {
  const { token, lang } = action.payload;
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = yield axios.post('/user/set-language', { language: lang });
    if (response.status === 200) {
      yield put(setLanguageDone(lang));
    } else {
      yield put(setLanguageFail({ error: response.data }));
    }
  } catch (err) {
    yield put(setLanguageFail({ error: err.response.data || err.toJSON() }));
  }
}

