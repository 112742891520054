import common from './common.json';
import modals from './modals.json';
import errors from './errors.json';
import pageTitles from './pageTitles.json';
import menu from './menu.json';
import cloudinary from './cloudinary.json';
import messages from './messages.json';
import auth from './auth.json';
import staff from './staff.json';
import profile from './profile.json';
import patient from './patient.json';
import examinations from './examinations.json';
import school from './school.json';

const namespaces = {
  common,
  modals,
  errors,
  pageTitles,
  menu,
  cloudinary,
  messages,
  auth,
  staff,
  profile,
  patient,
  examinations,
  school
};

export default namespaces;
