import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import Avatar from '../../components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import CustomFormGroup from '../../components/CustomFormGroup/CustomFormGroup';
import { EmailRegex, PhoneRegex, UserRoles } from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { AppContext, ToasterContext } from '../../context/index.ts';

const EditProfile = ({ history }) => {
  const { id, page } = useParams();
  const { locale } = useContext(AppContext);
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [selectedRole, setSelectedRole] = useState(UserRoles[0]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm();

  const { showSuccess, showError } = useContext(ToasterContext);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await axios.get(`/user/${id}`);
        if (response.status === 200) {
          setData(response.data.data.user);
          setAvatarUrl(response.data.data.user.avatar);
          setSelectedRole(UserRoles.find(role => role.value === response.data.data.user.role));
        }
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const editUser = async values => {
    try {
      setLoading(true);
      setError(null);
      values.avatar = avatarUrl || undefined;
      values.role = selectedRole.value;

      const response = await axios.post(`/user/${id}/update`, values);

      if (response.status === 200) {
        showSuccess(t('messages:userUpdated'));
        setTimeout(() => {
          history.goBack();
        }, 3000);
      }
    } catch (err) {
      const error = err.response.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:userUpdateFailed'));
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:edit') + t('pageTitles:tail')}</title>
      </Helmet>

      {data && (
        <>
          <PageTitle title={t('pageTitles:edit') + ' ' + data?.fullName} />
          <Col className=" mx-auto new-user">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit(editUser)}>
                  <FormGroup className="mb-3 met-profile">
                    <div className="met-profile-main" style={{ minWidth: 100 }}>
                      <Avatar avatarUrl={avatarUrl || data?.avatar} setAvatarUrl={setAvatarUrl} />
                    </div>
                  </FormGroup>
                  {error && (
                    <div className="alert alert-outline-danger" role="alert">
                      <strong>Oh snap!</strong> {error.message}.
                    </div>
                  )}
                  <FormGroup className="row">
                    <CustomFormGroup
                      label={t('profile:settings.info.firstName')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.firstName}
                      fixedLabel={true}
                      controlProps={{
                        id: 'firstName',
                        name: 'firstName',
                        defaultValue: data?.firstName,
                        maxLength: 100,
                      }}
                      register={register('firstName', {
                        required: true,
                      })}
                    />
                    <CustomFormGroup
                      label={t('profile:settings.info.lastName')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.lastName}
                      fixedLabel={true}
                      controlProps={{
                        id: 'lastName',
                        name: 'lastName',
                        defaultValue: data?.lastName,
                        maxLength: 100,
                      }}
                      register={register('lastName', {
                        required: true,
                      })}
                    />
                  </FormGroup>
                  <FormGroup className="row">
                    <CustomFormGroup
                      label={t('profile:settings.info.phone')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.phone}
                      fixedLabel={true}
                      prependContent={<i className="las la-phone" />}
                      controlProps={{
                        id: 'phone',
                        name: 'phone',
                        defaultValue: data?.phone,
                        maxLength: 100,
                      }}
                      register={register('phone', {
                        pattern: {
                          value: PhoneRegex,
                          message: 'invalidPhoneNumber',
                        },
                      })}
                    />
                    <CustomFormGroup
                      label={t('profile:settings.info.email')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.email}
                      prependContent={<i className="las la-at" />}
                      fixedLabel={true}
                      controlProps={{
                        id: 'email',
                        name: 'email',
                        defaultValue: data?.email,
                        maxLength: 100,
                      }}
                      register={register('email', {
                        // required: true,
                        pattern: {
                          value: EmailRegex,
                          message: 'invalidEmail',
                        },
                      })}
                    />
                  </FormGroup>
                  <FormGroup className="row">
                    <CustomFormGroup
                      label={t('profile:settings.info.jobTitle')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.title}
                      fixedLabel={true}
                      controlProps={{
                        id: 'title',
                        name: 'title',
                        defaultValue: data?.title,
                        maxLength: 100,
                      }}
                      register={register('title', {})}
                    />
                    <CustomFormGroup
                      label={t('profile:settings.info.company')}
                      groupProps={{ className: 'col-md-6 mb-3' }}
                      error={errors.company}
                      fixedLabel={true}
                      controlProps={{
                        id: 'company',
                        name: 'company',
                        defaultValue: data?.company,
                        maxLength: 100,
                      }}
                      register={register('company', {})}
                    />
                  </FormGroup>
                  <FormGroup className="row">
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>{t('role')}</Form.Label>
                      <Controller
                        name="role"
                        control={control}
                        defaultValue={UserRoles[0].value}
                        value={selectedRole.value}
                        render={() => {
                          return (
                            <CustomSelect
                              options={UserRoles}
                              values={selectedRole ? [selectedRole] : []}
                              contentRenderer={() => (
                                <span className="text-size-smaller">{selectedRole && selectedRole.label}</span>
                              )}
                              onChange={selected => {
                                setSelectedRole(selected[0]);
                              }}
                            />
                          );
                        }}
                      />
                    </Form.Group>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="spinner"
                        />
                      )}
                      {t('buttons.edit')}
                    </Button>
                    <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/staff/${page}`}>
                      {t('buttons.cancel')}
                    </NavLink>
                  </FormGroup>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};

export default EditProfile;
