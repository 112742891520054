import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const GlassesPrescribed = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();
  const [gValue, setGValue] = useState();


  const gValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.glassesPrescribed.farDistance'),
        checked: gValue === 'farDistance',
        value: 'farDistance',
      },
      {
        label: t('examinations:regdExam.glassesPrescribed.nearDistance'),
        checked: gValue === 'nearDistance',
        value: 'nearDistance',
      },
      {
        label: t('examinations:regdExam.glassesPrescribed.permanent'),
        checked: gValue === 'permanent',
        value: 'permanent',
      },
      {
        label: t('examinations:regdExam.glassesPrescribed.other'),
        checked: gValue === 'other',
        value: 'other',
      }
    ],
    [gValue]
  );


  return (
    <Row className="mb-3">
      <Row>
        <Col md={4}>
          <h5 className='mb-3 m-0'>{t('examinations:regdExam.glassesPrescribed.title')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.glassesPrescribed.specifyTheType')}
            labelClass="d-block"
            items={gValues}
            groupProps={{ className: 'mb-3' }}
            inline={false}
            controlProps={{
              name: 'glassesPrescribed.type',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.glassesPrescribed?.type,
            }}
            onChange={v => {
              setGValue(v);
              setValue('glassesPrescribed.type.v', v);

            }}
          />
          <CustomFormGroup
            prependContent={t('examinations:regdExam.specify')}
            register={{ ...register('glassesPrescribed.type.d')}}
            error={ errors.glassesPrescribed?.type }
            groupProps={{ className: 'col-md-12 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'glassesPrescribed.type.d',
              name: 'glassesPrescribed.type.d',
              defaultValue: data?.glassesPrescribed?.type.d || '',
              disabled: gValue !== 'other'
            }}
          />
        </Col>
        <Col md={8}>
          <h5 className='mb-3 m-0'>{t('examinations:regdExam.glassesPrescribed.tickets')}</h5>
          <Row>
            {['od', 'os'].map(eye =>
              <Col md={6} key={eye}>
                <h6 className='mb-3 m-0'>{t(`examinations:regdExam.${eye}`)}</h6>
                {['sph', 'cyl', 'axis'].map(_ => 
                  <CustomFormGroup
                    key={_}
                    prependContent={t(`examinations:regdExam.${_}`)}
                    register={{ ...register(`glassesPrescribed.tickets.${eye}.${_}`)}}
                    error={ errors.glassesPrescribed?.tickets?.[eye]?.[_] }
                    groupProps={{ className: 'col-md-6 mb-3' }}
                    fixedLabel={true}
                    controlProps={{
                      id: `glassesPrescribed.tickets.${eye}.${_}`,
                      name: `glassesPrescribed.tickets.${eye}.${_}`,
                      defaultValue: data?.glassesPrescribed?.tickets?.[eye]?.[_] || '',
                      disabled: !gValue
                    }}
                  />
                )}
              </Col>
            )}
          </Row>
          <Row>
            <CustomFormGroup
              prependContent={t('examinations:regdExam.add')}
              register={{ ...register('glassesPrescribed.tickets.add')}}
              error={ errors.glassesPrescribed?.tickets?.add }
              groupProps={{ className: 'col-md-6 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: 'glassesPrescribed.tickets.add',
                name: 'glassesPrescribed.tickets.add',
                defaultValue: data?.glassesPrescribed?.tickets?.add || '',
                disabled: !gValue
              }}
            />
            <CustomFormGroup
              prependContent={t('examinations:regdExam.dpp')}
              appendContent={t('examinations:regdExam.mm')}
              register={{ ...register('glassesPrescribed.tickets.dpp')}}
              error={ errors.glassesPrescribed?.tickets?.dpp }
              groupProps={{ className: 'col-md-6 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: 'glassesPrescribed.tickets.dpp',
                name: 'glassesPrescribed.tickets.dpp',
                defaultValue: data?.glassesPrescribed?.tickets?.dpp || '',
                disabled: !gValue
              }}
            />
          </Row>
        </Col>
      </Row>
    </Row>
  );
};
export default GlassesPrescribed;
