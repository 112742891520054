import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type Props = {
  actionButton?: {
    title: ReactNode;
    to: string;
    className?: string;
  };
};

const NoDataMessage = ({ actionButton }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="alert alert-light mb-0 text-center" role="alert">
      <h4 className="alert-heading font-18">{t('messages:noData')}</h4>
      <p>{t('messages:noRecordsFound')}</p>
      {!!actionButton && (
        <NavLink to={actionButton.to} className="btn btn-success px-4 ">
          {actionButton.title}
        </NavLink>
      )}
    </div>
  );
};

export default NoDataMessage;
