import React, { useCallback, Suspense, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
import AddScreening from './AddScreening';
import EditScreening from './EditScreening';
import { hasPermission } from '../../../../accessControl';
import { AppContext } from '../../../../context';

type Props = {
  appLoading: boolean;
};
const Screenings = ({ appLoading }: Props) => {
  const { page, patientId } = useParams();
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const renderActiveTab = useCallback(() => {
    let tab;
    
    switch (page) {
      case 'create': {
        tab = <AddScreening />;
        break;
      }
      case 'edit': {
        if (!hasPermission('updateAny', 'schoolScreening')) {
          return <Redirect to={`/${locale.value}/patient/${patientId}`} />;
        }
        tab = <EditScreening />;
        break;
      }
      default: {
        tab = <AddScreening />;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:staff') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default Screenings;
