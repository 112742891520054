import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Separator from '../../../components/Separator/Separator';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../components/CustomRadioGroup/CustomRadioGroup';

const PersonalInformation = ({ register, getValues, errors, control, clearErrors, setError, data, setValue }) => {
  const { t } = useTranslation();
  const [gender, setGender] = useState('male');

  useEffect(() => {
    if (data) {
      setGender(data?.gender);
    }
  }, [data]);

  const validate = value => {
    if (!getValues('socialCardNumber') && !getValues('birthCertificateNumber')) {
      setError('social', {
        type: 'manual',
        message: t('errors:validationMessages.oneIsRequired'),
      });
    }
  };

  const genders = useMemo(
    () => [
      {
        label: t('male'),
        checked: gender === 'male',
        value: 'male',
        disabled: false,
      },
      {
        label: t('female'),
        checked: gender === 'female',
        value: 'female',
        disabled: false,
      },
    ],
    [gender]
  );

  var dateParts = data?.dob.split('/');
  return (
    <>
      <Separator text={`${t('patient:profile.title')}`} />
      <Row>
        <CustomFormGroup
          label={t('patient:profile.firstName')}
          groupProps={{ className: 'col-md-4 mb-3' }}
          error={errors.firstName}
          fixedLabel={true}
          controlProps={{
            id: 'firstName',
            name: 'firstName',
            defaultValue: data?.firstName,
            maxLength: 100,
          }}
          register={register('firstName', {
            required: true,
          })}
        />
        <CustomFormGroup
          label={t('patient:profile.lastName')}
          groupProps={{ className: 'col-md-4 mb-3' }}
          error={errors.lastName}
          fixedLabel={true}
          controlProps={{
            id: 'lastName',
            name: 'lastName',
            defaultValue: data?.lastName,
            maxLength: 100,
          }}
          register={register('lastName', {
            required: true,
          })}
        />
        <CustomFormGroup
          label={t('patient:profile.fatherName')}
          groupProps={{ className: 'col-md-4 mb-3' }}
          error={errors.fatherName}
          fixedLabel={true}
          controlProps={{
            id: 'fatherName',
            name: 'fatherName',
            defaultValue: data?.fatherName,
            maxLength: 100,
          }}
          register={register('fatherName', {
            required: true,
          })}
        />
      </Row>
      <Row>
        <Col md={4} className="mb-3">
          <CustomRadioGroup
            label={t('patient:profile.gender')}
            labelClass="d-block"
            items={genders}
            controlProps={{
              name: 'gender',
              control,
              defaultValue: gender,
              rules: { validate: v => v !== undefined || 'required' },
            }}
            onChange={v => {
              setGender(v);
              setValue('gender', v);
            }}
          />
        </Col>
        <Col md={4} className="mb-3">
          <Form.Label>{t('patient:profile.dob')}</Form.Label>
          <Controller
            control={control}
            name="dob"
            defaultValue={data && new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className={`${errors.dob ? 'is-invalid' : ''}`}>
                <DatePicker
                  name="dob"
                  className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                  selected={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  showDisabledMonthNavigation
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                />
              </div>
            )}
          />
          {errors?.dob && (
            <Form.Control.Feedback type="invalid">
              {(errors?.dob.message && t(`errors:validationMessages.${errors?.dob.message}`)) ||
                (errors?.dob.type === 'required' && (
                  <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                ))}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6} className="col-md-6 ">
          <Form.Label>{t('patient:profile.socialCardNumber')}</Form.Label>
          <Controller
            name="socialCardNumber"
            control={control}
            render={({ field: { ...field } }) => (
              <Form.Control
                className={`${errors?.social ? 'is-invalid' : ''}`}
                id="socialCardNumber"
                type="number"
                min={0}
                defaultValue={data?.socialCardNumber}
                name="socialCardNumber"
                {...register('socialCardNumber')}
                onChange={e => {
                  field.onChange(e);
                  clearErrors('social');
                }}
              />
            )}
          />
        </Col>
        <Col md={6} className={`col-md-6  ${errors?.social ? 'is-invalid' : ''}`}>
          <Form.Label>{t('patient:profile.birthCertificateNumber')}</Form.Label>
          <Controller
            name="birthCertificateNumber"
            control={control}
            render={({ field: { ...field } }) => (
              <Form.Control
                className={`${errors?.social ? 'is-invalid' : ''}`}
                id="birthCertificateNumber"
                type="text"
                name="birthCertificateNumber"
                defaultValue={data?.birthCertificateNumber}
                {...register('birthCertificateNumber')}
                onChange={e => {
                  field.onChange(e);
                  clearErrors('social');
                }}
              />
            )}
          />
        </Col>
        {errors.social && (
          <Form.Control.Feedback type="invalid">
            {errors.social.type === 'manual' && (
              <span id="error-social">{t('errors:validationMessages.oneIsRequired')}</span>
            )}
          </Form.Control.Feedback>
        )}
      </Row>
    </>
  );
};
export default PersonalInformation;
