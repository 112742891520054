import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../../components/Separator/Separator';
import SymptomsFormValues from '../../../../../components/SymptomsForm/SymptomsFormValues';
import { CountryRegions, YerevanCommunities } from '../../../../../constants';
import { ExaminationDataType } from '../../Examination/Examination';
// import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../../../../accessControl';
import axios from '../../../../../axios';
import FileThumbnail, { FileThumbnailType } from '../../../../../components/FileThumbnail/FileThumbnail';
import { AppContext, ToasterContext } from '../../../../../context';
import useCloudinaryWidget, { AttachmentInfoType } from '../../../../../hooks/useCloudinaryWidget';
import usePatient from '../../../../../hooks/usePatient';
import { NavLink } from 'react-router-dom';

type Props = {
  data: ScreeningExaminationDataType;
};
export type ScreeningExaminationDataType = ExaminationDataType & {
  school: {
    name: string;
    region: number;
    community: number;
    address: string;
    settlementType: string;
    settlement: string;
  };
  parent: object;
  glassesLenses: string;
  symptoms: object;
  welchAllynDeviceDiagnostics: {
    n: boolean;
    v: boolean;
    id: string;
  };
  visionAcuityInFarDistance: {
    r: boolean;
    l: boolean;
  };
  visionAcuityInCloseDistance: {
    b: boolean;
  };
  eyeFrontalZoneEvaluation: {
    l: {
      v: boolean;
      note: string;
    };
    r: {
      v: boolean;
      note: string;
    };
  };
  _3DVisionCheck: {
    b: boolean;
  };
  colorVisionCheck: {
    b: boolean;
  };
  eyeCooperativeWorkCheck: {
    b: boolean;
  };
  eyeSynchronousMovementCheck: {
    b: {
      t: number;
      v: boolean;
    };
  };
  welchAllynDeviceFiles: AttachmentInfoType[];
  otherFiles: AttachmentInfoType[];
};

enum AttachmentFilesPropType {
  WelchAllyn = 'welchAllynDeviceFiles',
  Other = 'otherFiles',
}

function ScreeningValues({ data }: Props) {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const { patient } = usePatient();
  const [screening, setScreening] = useState(data);

  const {
    showWidget: showWelchAllynWidget,
    files: welchAllynFiles,
    tick: tick1,
  } = useCloudinaryWidget({
    folder: `patient/${patient.id}/screening/${data.id}/${AttachmentFilesPropType.WelchAllyn}/`,
  });
  const {
    showWidget: showOtherWidget,
    files: otherFiles,
    tick: tick2,
  } = useCloudinaryWidget({ folder: `patient/${patient.id}/screening/${data.id}/${AttachmentFilesPropType.Other}` });

  const { showSuccess, showError } = useContext(ToasterContext);

  useEffect(() => {
    setScreening(data);
  }, [data]);

  const schoolRegion = useMemo(() => {
    if (!data || !data.school) {
      return null;
    }

    return CountryRegions.find(_ => _.value === data.school?.region)?.label;
  }, [data]);

  const schoolCommunity = useMemo(() => {
    if (!data || !data.school) {
      return null;
    }

    return YerevanCommunities.find(_ => _.value === data.school?.community)?.label;
  }, [data]);

  useEffect(() => {
    if (welchAllynFiles?.length) {
      editScreening(welchAllynFiles, AttachmentFilesPropType.WelchAllyn);
    }
  }, [welchAllynFiles]);

  useEffect(() => {
    if (otherFiles?.length) {
      editScreening(otherFiles, AttachmentFilesPropType.Other);
    }
  }, [otherFiles]);

  const editScreening = useCallback(
    async (newFiles: AttachmentInfoType[], type: AttachmentFilesPropType) => {
      try {
        const response = await axios.post(`screening/${screening.id}/update`, {
          [type]: [...screening[type], ...newFiles],
        });

        setScreening(response.data.data.screening);

        showSuccess(t('messages:screeningUpdated'));
      } catch (err) {
        const error = err.response?.data;
        showError(error.code ? t('errors:codes.' + error.code) : t('messages:screeningEditFailed'));
      }
    },
    [screening]
  );

  return (
    <Row>
      <Col className="py-3">
        <div className="text-end float-end">
          {hasPermission('updateAny', 'schoolScreening') ? (
            <NavLink
              className="btn btn-soft-primary btn-sm"
              to={`/${locale.value}/patient/${patient.id}/screening/${screening.id}/edit`}
            >
              {t('buttons.edit')}
            </NavLink>
          ) : null}
        </div>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.date')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">{screening.date}</span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.school')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">
            {[
              screening.school.name,
              screening.school.address,
              schoolRegion,
              t(`address.settlementType.${screening.school.settlementType}`) + ' ' + screening.school.settlement,
              schoolCommunity,
            ]
              .filter(_ => !!_)
              .join(', ')}
          </span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.glassesLensesState')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">{t(`examinations:screenings.${screening.glassesLenses}`)}</span>
        </Form.Group>
        <Separator text={`${t('examinations:screenings.subjectiveSymptoms')}`} variant="light" />
        <SymptomsFormValues data={screening.symptoms} className="mb-3" />
        <Separator text={t('examinations:screenings.welchAllynDeviceDiagnostics.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.welchAllynDeviceDiagnostics.wasTheDiagnosticsPerformed')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">{screening.welchAllynDeviceDiagnostics.v ? t('yes') : t('no')}</span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.welchAllynDeviceDiagnostics.result')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">
            {screening.welchAllynDeviceDiagnostics.n
              ? t('examinations:screenings.welchAllynDeviceDiagnostics.issueNotFound')
              : t('examinations:screenings.welchAllynDeviceDiagnostics.issueFound')}
          </span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.welchAllynDeviceDiagnostics.deviceLocalPatientID')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">{screening.welchAllynDeviceDiagnostics.id}</span>
        </Form.Group>
        <Separator text={t('examinations:screenings.visionAcuityInFarDistanceTest.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.visionAcuityInFarDistanceTest.identifiesImages')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:rightEye')}</b>
              {t('formLabelSeparator')} {screening.visionAcuityInFarDistance.r ? t('yes') : t('no')}
            </Col>
            <Col className="text-dark">
              <b>{t('examinations:leftEye')}</b>
              {t('formLabelSeparator')} {screening.visionAcuityInFarDistance.l ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:screenings.visionAcuityInCloseDistance.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.visionAcuityInCloseDistance.identifiesImages')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:bothEyes')}</b>
              {t('formLabelSeparator')} {screening.visionAcuityInCloseDistance.b ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:screenings.eyeFrontalZoneEvaluation.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.eyeFrontalZoneEvaluation.hasAnyDeviation')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:rightEye')}</b>
              {t('formLabelSeparator')} {screening.eyeFrontalZoneEvaluation.r.v ? t('yes') : t('no')}
              <div>{screening.eyeFrontalZoneEvaluation.r.note}</div>
            </Col>
            <Col className="text-dark">
              <b>{t('examinations:leftEye')}</b>
              {t('formLabelSeparator')} {screening.eyeFrontalZoneEvaluation.l.v ? t('yes') : t('no')}
              <div>{screening.eyeFrontalZoneEvaluation.l.note}</div>
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:screenings.3DVisionCheck.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.3DVisionCheck.label')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:bothEyes')}</b>
              {t('formLabelSeparator')} {screening._3DVisionCheck.b ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={`${t('examinations:screenings.eyeSynchronousMovementCheck.title')}`} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.eyeSynchronousMovementCheck.testDuration')}
            {t('formLabelSeparator')}{' '}
            <span className="text-dark">
              {t(`examinations:screenings.eyeSynchronousMovementCheck.${screening?.eyeSynchronousMovementCheck?.b?.t}`)}
            </span>
          </FormLabel>
          <br />
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.eyeSynchronousMovementCheck.correctlyReadsSymbols')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:bothEyes')}</b>
              {t('formLabelSeparator')} {screening.eyeSynchronousMovementCheck?.b?.v ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:screenings.eyeCooperativeWorkCheck.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.eyeCooperativeWorkCheck.withoutEyeSlant')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:bothEyes')}</b>
              {t('formLabelSeparator')} {screening.eyeCooperativeWorkCheck?.b ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:screenings.colorVisionCheck.title')} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:screenings.colorVisionCheck.identifiesNumbers')}
            {t('formLabelSeparator')}
          </FormLabel>
          <Row>
            <Col className="text-dark">
              <b>{t('examinations:bothEyes')}</b>
              {t('formLabelSeparator')} {screening.colorVisionCheck?.b ? t('yes') : t('no')}
            </Col>
          </Row>
        </Form.Group>
        <Separator text={t('examinations:attachments.title')} variant="light" />
        <p className="card-title my-3">
          <strong>{t('examinations:screenings.files.welchAllyn')}</strong>
        </p>
        <div className="file-box-content">
          {screening.welchAllynDeviceFiles.map(file => (
            <FileThumbnail key={file.id} file={file} type={FileThumbnailType.square} actionIcon="times" />
          ))}
          {hasPermission('updateOwn', 'schoolScreening') ? (
            <div className="file-box bg-light cursor-pointer" onClick={showWelchAllynWidget}>
              <a className="download-icon-link">
                <i className="las la-download file-download-icon text-white"></i>
              </a>
              <div className="text-center">
                <i className="las la-plus text-info"></i>
                <h6 className="text-truncate">{t('examinations:attachFile')}</h6>
                <small className="text-muted">{t('examinations:attachFileHint')}</small>
              </div>
            </div>
          ) : null}
        </div>
        <p className="card-title my-3">
          <strong>{t('examinations:screenings.files.other')}</strong>
        </p>
        <div className="file-box-content">
          {screening.otherFiles.map(file => (
            <FileThumbnail key={file.id} file={file} type={FileThumbnailType.square} actionIcon="times" />
          ))}
          {hasPermission('updateOwn', 'schoolScreening') ? (
            <div className="file-box bg-light cursor-pointer" onClick={showOtherWidget}>
              <a className="download-icon-link">
                <i className="las la-download file-download-icon text-white"></i>
              </a>
              <div className="text-center">
                <i className="las la-plus text-info"></i>
                <h6 className="text-truncate">{t('examinations:attachFile')}</h6>
                <small className="text-muted">{t('examinations:attachFileHint')}</small>
              </div>
            </div>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

ScreeningValues.propTypes = {};

export default ScreeningValues;
