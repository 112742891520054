import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { UserRoles } from '../../constants';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

function RoleSelect({ groupProps = {}, control, onChange }) {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState(UserRoles[0]);

  useEffect(() => {
    onChange(selectedRole);
  }, [selectedRole]);

  return (
    <Form.Group {...groupProps}>
      <Form.Label>{t('role')}</Form.Label>
      <Controller
        name="role"
        control={control}
        defaultValue={UserRoles[0].value}
        value={selectedRole.value}
        render={() => {
          return (
            <CustomSelect
              options={UserRoles}
              dropdownPosition="top"
              values={selectedRole ? [selectedRole] : []}
              contentRenderer={() => (
                <span className="text-size-smaller">{selectedRole && selectedRole.label}</span>
              )}
              onChange={selected => {
                setSelectedRole(selected[0]);
              }}
            />
          );
        }}
      />
    </Form.Group>
  );
}

RoleSelect.propTypes = {};

export default RoleSelect;
