import React from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword';
import PersonalInfo from './PersonalInfo';

const Settings = () => {
  const { t } = useTranslation();
  return (
    <Card.Body className="p-0">
      <Tabs defaultActiveKey="settings" className="nav nav-tabs" role="tablist">
        <Tab eventKey="settings" title={t('profile:settings.title')}>
          <div className="tab-pane p-3" id="Settings" role="tabpanel">
            <Row>
              <Col lg={6} xl={6}>
                <Card>
                  <Card.Header>
                    <Row className="align-items-center">
                      <Col>
                        <Card.Title>{t('profile:settings.info.title')}</Card.Title>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <PersonalInfo />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} xl={6}>
                <Card>
                  <Card.Header>
                    <Card.Title>{t('profile:settings.changePassword.title')}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <ChangePassword />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Tab>
      </Tabs>

    </Card.Body>
  );

};
export default Settings;
