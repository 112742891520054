import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const Accommodation = ({ register, errors, data, clearErrors, setValue, control }) => {
  const { t } = useTranslation();
  const [sValue, setSValue] = useState();
  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  return (
    <>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.accommodation.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'accommodationState',
            control,
            defaultValue: data?.accommodationState,
          }}
          onChange={v => {
            clearErrors('accommodationState');
            setSValue(v);
            setValue('accommodationState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        {['rightEyeOD', 'leftEyeOS', 'bothEyesOU'].map(eye => (
          <Col md={4} key={eye}>
            <h5>{t(`examinations:regdExam.${eye}`)}</h5>
            {['amplitude', 'ability', 'answer'].map(_ => (
              <CustomFormGroup
                key={_}
                register={{ ...register(`accommodation.${eye.charAt(0)}.${_}`) }}
                label={t(`examinations:regdExam.accommodation.${_}`)}
                error={errors.accommodation?.[eye.charAt(0)]?.[_]}
                groupProps={{ className: 'mb-3' }}
                fixedLabel={true}
                controlProps={{
                  id: `accommodation.${eye.charAt(0)}.${_}`,
                  name: `accommodation.${eye.charAt(0)}.${_}`,
                  defaultValue: data?.accommodation?.[eye.charAt(0)]?.[_] || '',
                }}
              />
            ))}
          </Col>
        ))}
      </Row>
    </>
  );
};
export default Accommodation;
