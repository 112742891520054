import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const VisualAcuityAndRefraction = ({ register, errors, data }: RegDExamSubProps) => {
  const { t } = useTranslation();


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.visualAcuityAndRefraction.title')}</h5>
      {[
        'rightEyeOD',
        'leftEyeOS'
      ].map(eye => 
        <Col key={eye}>
          <h5 className='mb-4 m-0'>{t(`examinations:regdExam.${eye}`)}</h5>
          {[
            'distanceVisionWithoutCorrection',
            'distanceVisionCorrection',
            'closeUpVisionWithoutCorrection',
            'nearVisionCorrection'
          ].map(field => 
            <CustomFormGroup
              appendContent={null}
              prependContent={null}
              key={field}
              register={{ ...register(`visualAcuityAndRefraction.${eye}.${field}`, { required: field === 'distanceVisionWithoutCorrection'})}}
              label={t(`examinations:regdExam.visualAcuityAndRefraction.${field}`)}
              error={errors.visualAcuityAndRefraction?.[eye]?.[field]}
              groupProps={{ className: 'col-md-6 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: `visualAcuityAndRefraction.${eye}.${field}`,
                name: `visualAcuityAndRefraction.${eye}.${field}`,
                defaultValue: data?.visualAcuityAndRefraction?.[eye]?.[field] || ''
              }}
            />
          )}
        </Col>
      )}
      
    </Row>
  );
};
export default VisualAcuityAndRefraction;
