import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Footer(props) {
  const {t} = useTranslation();
  return (
    <footer className="footer text-center text-sm-start">
      &copy; {new Date().getFullYear()} {t('aecpFull')}
      <span className="text-muted d-none d-sm-inline-block float-end">
        Crafted by <i className="mdi mdi-heart text-danger"></i> Vectar
      </span>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
