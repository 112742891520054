import React, { useContext } from 'react';
import Toast from './Toast';
import { ToasterContext } from '../../context/index.ts';
import { ToastContainer } from 'react-bootstrap';
import './Toaster.scss';

function Toaster() {
  const { toasts, removeToast } = useContext(ToasterContext);
  return (
    <div className="toaster-wrapper position-fixed">
      <ToastContainer position="top-center" className="p-3">
        {toasts.map(toast => (
          <Toast key={toast.id} toast={toast} id={toast.id} type={toast.type} onClose={removeToast} />
        ))}
      </ToastContainer>
    </div>
  );
}

export default Toaster;
