import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/index';

function OtherMenu() {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  return (
    <>
      <div className="title-box">
        <h6 className="menu-title">{t('menu:other.title')}</h6>
      </div>
      <ul className="nav flex-column">
        <li className="nav-item">

          <NavLink className="nav-link" to={`/${locale.value}/data/school/all`}>
            {t('menu:other.school.schools')}
          </NavLink>
        </li>
        <li className="nav-item pt-3">
          <NavLink className="btn btn-soft-success d-grid ml-2" to={`/${locale.value}/data/school/create`}>
            {t('menu:other.school.addSchool')}
          </NavLink>
        </li>
      </ul>
    </>
  );
}

OtherMenu.propTypes = {};

export default OtherMenu;
