import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../../context/index';
import { hasPermission } from '../../../accessControl';
import { Badge } from 'react-bootstrap';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store';

function PatientsMenu() {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);

  const { data } = useAppSelector<RootState>(state => state.data);

  return (
    <>
      <div className="title-box">
        <h6 className="menu-title">{t('menu:patients.title')}</h6>
      </div>
      <ul className="nav flex-column">
        {hasPermission('readAny', 'patient') && (
          <li className="nav-item py-2">
            <NavLink className="nav-link" to={`/${locale.value}/patients/all`}>
              {t('menu:patients.allPatients')}
            </NavLink>
          </li>
        )}
        {hasPermission('readOwn', 'patient') && !hasPermission('readAny', 'patient') && (
          <li className="nav-item py-2">
            <NavLink
              className="nav-link"
              to={`/${locale.value}/patients/all?type=own`}
              isActive={(match, location) => {
                const query = new URLSearchParams(location.search);

                return !!match && !!query.get('type');
              }}
            >
              {t('menu:patients.allPatients')}
            </NavLink>
          </li>
        )}
        {hasPermission('readOwn', 'pendingPatient1') && (
          <li className="nav-item py-2">
            <NavLink
              className="nav-link"
              to={`/${locale.value}/patients/pending`}
              isActive={(match, location) => {
                const query = new URLSearchParams(location.search);

                return !!match && !query.get('type');
              }}
            >
              {t('menu:patients.pending1')}
              <Badge bg="danger" pill>
                {!!data.badges.pendingPatients1 && data.badges.pendingPatients1}
              </Badge>
            </NavLink>
          </li>
        )}
        {hasPermission('readOwn', 'pendingPatient2') && (
          <li className="nav-item py-2">
            <NavLink
              className="nav-link"
              to={`/${locale.value}/patients/pending`}
              isActive={(match, location) => {
                const query = new URLSearchParams(location.search);

                return !!match && !query.get('type');
              }}
            >
              {t('menu:patients.pending2')}
              <Badge bg="danger" pill>
                {!!data.badges.pendingPatients2 && data.badges.pendingPatients2}
              </Badge>
            </NavLink>
          </li>
        )}

        {hasPermission('readOwn', 'pendingPatient3') && (
          <li className="nav-item py-2">
            <NavLink
              className="nav-link"
              to={`/${locale.value}/patients/pending`}
              isActive={(match, location) => {
                const query = new URLSearchParams(location.search);

                return !!match && !query.get('type');
              }}
            >
              {t('menu:patients.pending2')}
              <Badge bg="danger" pill>
                {!!data.badges.pendingPatients3 && data.badges.pendingPatients3}
              </Badge>
            </NavLink>
          </li>
        )}
        {hasPermission('createAny', 'patient') && (
          <li className="nav-item pt-3">
            <NavLink className="btn btn-soft-success d-grid ml-2" to={`/${locale.value}/patients/create`}>
              {t('menu:patients.addPatient')}
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
}

export default PatientsMenu;
