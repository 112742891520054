import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'react-bootstrap';

function CustomTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className="table-responsive">
      <Table {...getTableProps()} className="table">
        <thead className="thead-light">
          {headerGroups.map((headerGroup, id) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`header-${id}`}>
              {headerGroup.headers.map((column, id) => (
                <th
                  key={`column-${id}`}
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      className: column.headerClassName,
                      style: column.headerStyle,
                    },
                  ])}
                >
                  {column.render('Header')}
                  {column.canSort &&
                    (column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="mdi mdi-sort-ascending font-16" />
                      ) : (
                        <i className="mdi mdi-sort-descending font-16" />
                      )
                    ) : (
                      <i className="mdi mdi-sort-ascending font-16" />
                    ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`row-${i}`} {...row.getRowProps()}>
                {row.cells.map((cell, id) => {
                  return (
                    <td
                      key={`cell-${id}`}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default CustomTable;
