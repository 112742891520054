import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressForm from '../../../components/AddressForm/AddressForm';

const LivingAddress = ({ register, control, setValue, errors, data }) => {
  const { t } = useTranslation();

  return (
    <AddressForm
      title={t('patient:livingAddress.title')}
      control={control}
      register={register}
      setValue={setValue}
      errors={errors}
      data={data}
      streetAddressRequired={false}
    />
  );
};
export default LivingAddress;
