import React from 'react';

export const AppContext = React.createContext<AppContextType>({});
export const ToasterContext = React.createContext<ToasterContextType>({});
export const ModalsContext = React.createContext<ModalsContextType>({});

export type AppLocaleType = {
  value: string;
  setValue: (value: string) => void;
};

export type AppContextType =
  | {
      locale: AppLocaleType;
    }
  | Record<string, never>;

export type ToasterContextType =
  | {
      showError: (message: string) => void;
      showSuccess: (message: string) => void;
    }
  | Record<string, never>;

type ModalsContextType =
  | {
      confirmationModal: ConfirmModalContextType;
      referFMDModal: ReferFMDModalContextType;
      exportPatientsModal: ExportPatientsModalContextType;
    }
  | Record<string, never>;

type ModalContextType = {
  open: boolean;
  show: (any) => void;
  hide: () => void;
  data: ModalContextDataType;
};

type ModalContextDataType = {
  action?: () => void;
  variant?: string;
};

interface ReferFMDModalContextType extends ModalContextType {
  show: (
    data: ModalContextDataType & {
      patientId: string;
    }
  ) => void;
  data: ModalContextDataType & {
    patientId: string;
  };
}

interface ConfirmModalContextType extends ModalContextType {
  data: ModalContextDataType & {
    title: string | undefined;
    btnText: string | undefined;
    message: string | undefined;
  };
}

interface ExportPatientsModalContextType extends ModalContextType {
  data: ModalContextDataType;
}
