import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import axios from '../../../../../axios';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import { hasPermission } from '../../../../../accessControl';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';

const ScreeningDetails = ({ register, control, setValue, errors, data }) => {
  const { t } = useTranslation();
  const [nurseList, setNursesList] = useState([]);
  const [glassesLenses, setGlassesLenses] = useState();

  // console.log(data);
  const glassesLensesValues = useMemo(
    () => [
      {
        label: t('examinations:screenings.noGlasses'),
        checked: glassesLenses === 'noGlasses',
        value: 'noGlasses',
      },
      {
        label: t('examinations:screenings.glassesLenses'),
        checked: glassesLenses === 'glassesLenses',
        value: 'glassesLenses',
      },
      {
        label: t('examinations:screenings.alwaysOnGlasses'),
        checked: glassesLenses === 'alwaysOnGlasses',
        value: 'alwaysOnGlasses',
      },
      {
        label: t('examinations:screenings.contactLenses'),
        checked: glassesLenses === 'contactLenses',
        value: 'contactLenses',
      },
    ],
    [glassesLenses]
  );

  useEffect(() => {
    if (data) {
      let dateParts = data?.date.split('/');
      setValue('date', dateParts && new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
      setGlassesLenses(data.glassesLenses);
      setValue('glassesLenses', data.glassesLenses);
      setValue('welchAllynDeviceFiles', data.welchAllynDeviceFiles);
    }
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/user/list', { params: { role: 'nurse' } });

        if (response.status === 200) {
          const nurseList = response.data.data.users;
          setNursesList(
            nurseList.map(_ => {
              return { value: _.id, label: _.fullName };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  var dateParts = data?.date.split('/');

  return (
    <>
      <Separator text={`${t('examinations:screenings.screeningDetails')}`} />
      <Row className="mb-3">
        <Col md={6} className="mb-3">
          <Form.Label>{t('examinations:screenings.date')}</Form.Label>
          <Controller
            control={control}
            name="date"
            defaultValue={dateParts && new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <div className={`${errors.date ? 'is-invalid' : ''}`}>
                  <DatePicker
                    name="date"
                    className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                    selected={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    showDisabledMonthNavigation
                    showYearDropdown
                    placeholderText="dd/mm/yyyy"
                  />
                </div>
              );
            }}
          />
          {errors?.date && (
            <Form.Control.Feedback type="invalid">
              {(errors?.date.message && t(`errors:validationMessages.${errors?.date.message}`)) ||
                (errors?.date.type === 'required' && (
                  <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                ))}
            </Form.Control.Feedback>
          )}
        </Col>
        {hasPermission('readAny', 'patient') && (
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Label>{t('examinations:screenings.nurse')}</Form.Label>
            {nurseList.length > 0 && (
              <Controller
                name="nurse"
                control={control}
                defaultValue={nurseList[0].value}
                render={({ field: { onChange } }) => {
                  return (
                    <CustomSelect
                      register={{ ...register('nurse') }}
                      name="nurse"
                      options={nurseList}
                      value={nurseList[0].value ? nurseList[0].value : ''}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{nurseList[0].value && nurseList[0].label}</span>
                      )}
                      onChange={val => {
                        // setNursesList(val[0]);
                        onChange(val[0].value);
                      }}
                    />
                  );
                }}
              />
            )}
          </Form.Group>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          {/* <Form.Label>{t('examinations:screenings.glassesLensesState')}</Form.Label> */}
          <CustomRadioGroup
            label={t('examinations:screenings.glassesLensesState')}
            labelClass="d-block"
            items={glassesLensesValues}
            groupProps={{ className: 'mb-3' }}
            inline={false}
            controlProps={{
              name: 'glassesLenses',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.glassesLenses,
            }}
            onChange={v => {
              setGlassesLenses(v);
              setValue('glassesLenses', v);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ScreeningDetails;
