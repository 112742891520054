import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavDropdown, NavLink, NavItem, Dropdown, Button } from 'react-bootstrap';
import i18n from '../../i18n';
import { AppContext } from '../../context/index.ts';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import axios from '../../axios';
import { AdvancedImage, placeholder } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import cloudinary from '../../cloudinary';

const TopBar = ({ toggleSidebar }) => {
  const { data: authData } = useSelector(state => state.auth);
  const theUser = authData.user;
  const handleLogout = async () => {
    const token = localStorage.getItem('aecp-ss-auth-token');
    try {
      const response = await axios.post('/auth/logout');
      if (response.status === 200) {
        axios.defaults.headers.common['Authorization'] = token;
        localStorage.removeItem('aecp-ss-auth-token');
        localStorage.removeItem('aecp-ss-refresh-token');
        delete axios.defaults.headers['Authorization'];
        window.location.replace('/');
      }
    } catch (err) {
      const { config, ...error } = err.toJSON();
    }
  };

  let avatar = <img src="/images/user-placeholder.png" alt="" height="110" className="rounded-circle me-2 thumb-sm" />;

  if (theUser.avatar) {
    const image = cloudinary.image(theUser.avatar);
    image.resize(fill().height(72));
    avatar = (
      <AdvancedImage
        cldImg={image}
        plugins={[placeholder()]}
        alt=""
        className="rounded-circle me-2 thumb-sm"
      />
    );
  }

  return (
    <AppContext.Consumer>
      {({ locale }) => (
        <div className="topbar">
          <nav className="navbar-custom" id="navbar-custom">
            <ul className="list-unstyled topbar-nav float-end mb-0">
              <li className="dropdown">
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} className=" arrow-none nav-icon">
                    <img className="thumb-xxs rounded-circle" src={`/images/flags/${locale.value}_flag.jpg`} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {['en', 'am'].map(_ => (
                      <NavDropdown.Item
                        key={_}
                        onClick={() => {
                          locale.setValue(_);
                          i18n.changeLanguage(_);
                        }}
                      >
                        <img src={`/images/flags/${_}_flag.jpg`} alt="" height="15" className="me-2" />
                        {t('languages.' + _)}
                      </NavDropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="dropdown">
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink} className="nav-user">
                    <div className="d-flex align-items-center">
                      {avatar}
                      <div>
                        <small className="d-none d-md-block font-11">{t('userRoles.' + theUser.role)}</small>
                        <span className="d-none d-md-block fw-semibold font-12">
                          {theUser.fullName} <i className="mdi mdi-chevron-down"></i>
                        </span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <NavDropdown.Item as="span">
                      <Link to={`/${locale.value}/me`}>
                        {' '}
                        <i className="ti ti-user font-16 me-1 align-text-bottom" /> {t('buttons.profile')}
                      </Link>
                    </NavDropdown.Item>
                    <div className="dropdown-divider mb-0" />
                    <NavDropdown.Item onClick={handleLogout}>
                      <i className="ti ti-power font-16 me-1 align-text-bottom" /> {t('buttons.logout')}
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <ul className="list-unstyled topbar-nav mb-0">
              <li>
                <NavLink as="button" className="button-menu-mobile nav-icon" onClick={toggleSidebar}>
                  <i className="ti ti-menu-2" />
                </NavLink>
              </li>
              {/* <li className="hide-phone app-search">
                <form role="search" action="#" method="get">
                  <input
                    type="search"
                    name="search"
                    className="form-control top-search mb-0"
                    placeholder="Type text..."
                  />
                  <button type="submit">
                    <i className="ti ti-search" />
                  </button>
                </form>
              </li> */}
            </ul>
          </nav>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default TopBar;
