import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { hasPermission } from '../../accessControl';
import axios from '../../axios';
import { CountryRegions } from '../../constants';
import { AppContext, ModalsContext, ToasterContext } from '../../context/index.ts';
import useQuery from '../../hooks/useQuery';
import Filter from '../../pages/Patient/Filter/Filter';
import CustomTable from '../CustomTable/CustomTable';
import LoadingIndicator from '../Loading/LoadingIndicator';
import ReferFMDModal from '../modals/ReferFMDModal/ReferFMDModal.tsx';
import ExportPatientsModal from '../modals/ExportPatientsModal/ExportPatientsModal.tsx';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import PageTitle from '../PageTitle/PageTitle';
import Pagination from '../Pagination/Pagination';
import PatientActionsMenu from '../PatientActionsMenu/PatientActionsMenu.tsx';
import { useDispatch } from 'react-redux';
import { loadData } from '../../store/actions';
import PendingPatientActionsMenu from '../PendingPatientActionsMenu/PendingPatientActionsMenu';
import AddExaminationMenu from '../AddExaminationMenu/AddExaminationMenu';

const PatientList = ({ title, type }) => {
  const { locale } = useContext(AppContext);
  const { confirmationModal } = useContext(ModalsContext);
  const { showSuccess, showError } = useContext(ToasterContext);
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const initialPage = query.get('page') ? parseInt(query.get('page') || '1') : 1;
  const [data, setData] = useState({ patients: [], pagingCount: 0, total: 0, limit: 20, page: 1 });
  const [pageNumber, setPage] = useState(initialPage);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPatientList = async () => {
    setLoading(true);

    if (pageNumber !== +(query.get('page') || '1')) {
      query.set('page', pageNumber + '');
    }

    params &&
      Object.keys(params).map(_ => {
        if (params[_] != undefined) {
          query.set(_, params[_]);
        }
      });

    history.push(`${location.pathname}?${query}`);
    const queryParams = { page: pageNumber, ...params };

    if (query.get('type')) {
      queryParams.type = query.get('type');
    } else if (type) {
      queryParams.type = type;
    }

    try {
      const response = await axios.get('/patient/list', { params: queryParams });
      if (response.status === 200) {
        setData({
          patients: response.data.data.patients,
          pagingCount: Math.ceil(response.data.data.total / response.data.data.limit),
          total: response.data.data.total,
          limit: response.data.data.limit,
          page: response.data.data.page,
        });
        setLoading(false);
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }

    dispatch(loadData());
  };

  const referFMDCallback = useCallback(() => {
    fetchPatientList();
  }, []);

  const updatePatientStatus = async (patient, status) => {
    try {
      const response = await axios.post(`/patient/${patient.id}/update`, {
        ...patient,
        status: status === -1 ? status : status ? 1 : 2,
      });
      if (response.status === 200) {
        setData(prevData => {
          const patients =
            status === -1
              ? prevData.patients.filter(_ => _.id !== patient.id)
              : prevData.patients.map(_ => {
                  if (_.id === patient.id) {
                    _.status = status ? 1 : 2;
                  }
                  return _;
                });

          const newData =
            status === -1
              ? {
                  ...prevData,
                  patients,
                  pagingCount: Math.ceil((prevData.total - 1) / prevData.limit),
                  total: prevData.total - 1,
                  limit: prevData.limit,
                  page: prevData.page,
                }
              : { ...prevData, patients };

          return newData;
        })
        ;

        showSuccess(t(`messages:${status === -1 ? 'patientDeleted' : 'patientStatusUpdated'}`));
      }
    } catch (err) {
      const error = err.response.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:patientStatusUpdateFailed'));
    }
  };

  const handlePatientDelete = patient => {
    confirmationModal.show({
      title: t('modals:confirmation.deletePatient.title'),
      message: t('modals:confirmation.deletePatient.message'),
      btnText: t('modals:confirmation.deletePatient.btnText'),
      action: () => updatePatientStatus(patient, -1),
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: t('patient:list.id'),
        accessor: 'uid',
        Cell: ({ row }) => (
          <NavLink to={`/${locale.value}/patient/${row?.original?.id}/view`} className="me-2">
            {row.original.uid}
          </NavLink>
        ),
      },
      {
        Header: t('patient:list.hasIssue'),
        accessor: 'hasIssue',
        disableSortBy: true,
        Cell: ({ value }) => value && <Badge bg="soft-danger">{t('examinations:visionState.hasIssue')}</Badge>,
      },
      {
        Header: t('patient:list.name'),
        accessor: 'fullName',
        Cell: ({ row }) => (
          <NavLink to={`/${locale.value}/patient/${row?.original?.id}/view`} className="me-2">
            {row.original.fullName}
          </NavLink>
        ),
      },
      {
        Header: t('patient:list.gender'),
        accessor: 'gender',
        disableSortBy: true,
        Cell: ({ row }) => <i className={classNames(['las align-middle font-22 ms-2', 'la-' + row.original.gender])} />,
      },
      {
        Header: t('patient:list.dob'),
        accessor: 'dob',
        disableSortBy: true,
      },
      {
        Header: t('patient:list.region'),
        accessor: 'region',
        Cell: ({ row }) => CountryRegions.find(_ => _.value === row?.original?.region)?.label,
      },
      {
        Header: t('patient:list.school'),
        accessor: 'school',
        disableSortBy: true,
        Cell: ({ row }) => row?.original?.school?.name || null,
      },
      {
        Header: t('patient:list:actions'),
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        headerClassName: 'text-end',
        Cell: ({ row }) => {
          const patientId = row?.original?.id;
          return (
            <>
              {hasPermission('readAny', 'patient') || hasPermission('readOwn', 'patient') ? (
                <NavLink to={`/${locale.value}/patient/${patientId}/view`} className="me-2">
                  <i className="las la-eye text-primary font-16" />
                </NavLink>
              ) : null}
              {hasPermission('updateAny', 'patient') || hasPermission('updateOwn', 'patient') ? (
                <NavLink to={`/${locale.value}/patient/${patientId}/edit`} className="me-1">
                  <i className="las la-pen text-primary font-16" />
                </NavLink>
              ) : null}
              {hasPermission('deleteAny', 'patient') ? (
                <Button variant="link" onClick={() => handlePatientDelete(row?.original)} className="p-0 me-2">
                  <i className="las la-trash-alt text-danger font-16" />
                </Button>
              ) : null}
              {(row?.original?.isPending.p1 && hasPermission('readOwn', 'pendingPatient1')) ||
              (row?.original?.isPending.p2 && hasPermission('readOwn', 'pendingPatient2')) ||
              (row?.original?.isPending.p3 && hasPermission('readOwn', 'pendingPatient3')) ? (
                <PendingPatientActionsMenu
                  patientId={patientId}
                  className="d-inline-block"
                  drop="start"
                  align="start"
                  variant="soft-success"
                  size="sm"
                  title={
                    <>
                      <i className="las la-plus font-14" />
                      <i className="mdi mdi-chevron-down" />
                    </>
                  }
                  appointmentId={row?.original?.appointmentId}
                />
              ) : (hasPermission('updateAny', 'patient') || hasPermission('updateOwn', 'patient')) &&
                hasPermission('createAny', 'schoolScreening') ? (
                <AddExaminationMenu
                  patientId={patientId}
                  className="d-inline-block"
                  drop="start"
                  align="start"
                  variant="soft-success"
                  size="sm"
                  title={
                    <>
                      <i className="las la-plus font-14" />
                      <i className="mdi mdi-chevron-down" />
                    </>
                  }
                />
              ) : null}
            </>
          );
        },
      },
    ],
    [locale, type]
  );

  useEffect(() => {
    async function fetchData() {
      await fetchPatientList();
    }
    fetchData();
  }, [pageNumber, params, type, query.get('type')]);

  const { exportPatientsModal } = useContext(ModalsContext);

  const handleExportList = () => {
    exportPatientsModal.show({
      filters: {},
    });
  };

  const exportPatientsCallback = useCallback(() => {
    fetchPatientList();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title + t('pageTitles:tail')}</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle title={title} />
        {hasPermission('readAny', 'patientExport') && (
          <Button className="btn-soft-primary" onClick={handleExportList}>
            <i className="las la-download font-16 me-1" />
            {t('buttons.export')}
          </Button>
        )}
      </div>

      {error ? (
        <div className="alert alert-outline-danger" role="alert">
          <strong>Oh snap!</strong> {error.message}.
        </div>
      ) : (
        <>
          <Row>
            <Col lg={9}>
              <Card>
                <Card.Body>
                  {loading ? (
                    <LoadingIndicator />
                  ) : !data?.patients?.length ? (
                    <NoDataMessage
                      actionButton={
                        hasPermission('createAny', 'patient')
                          ? {
                              title: `+ ${t('menu:patients.addPatient')}`,
                              to: `/${locale.value}/patients/create`,
                            }
                          : null
                      }
                    />
                  ) : (
                    <>
                      <CustomTable columns={columns} data={data.patients} />
                      <div className="row">
                        <div className="col">
                          <p className="">
                            <Trans
                              i18nKey="showingNItems"
                              components={{
                                start: data.limit * (data.page - 1) + 1,
                                end: data.limit * (data.page - 1) + data.patients.length,
                                total: data.total,
                              }}
                            />
                          </p>
                        </div>
                        <div className="col-auto">
                          {data.patients.length > 0 && (
                            <Pagination setPage={setPage} pagingCount={data.pagingCount} selectedPage={pageNumber} />
                          )}
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col-auto">
                          {hasPermission('createAny', 'patient') ? (
                            <NavLink
                              to={`/${locale.value}/patients/create`}
                              className="btn btn-de-success btn-sm px-4 "
                            >
                              + {t('menu:patients.addPatient')}
                            </NavLink>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Filter setParams={setParams} query={query} />
          </Row>
          <ReferFMDModal actionCallback={referFMDCallback} />
          <ExportPatientsModal actionCallback={exportPatientsCallback} params={params} />
        </>
      )}
    </>
  );
};
export default PatientList;
