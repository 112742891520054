import {combineReducers} from 'redux';
import auth from './auth';
// import user from './user';
import patient from './patient';
import app from './app';
import data from './data';

const rootReducer = combineReducers({
  auth,
  app,
  patient,
  data
});
export default rootReducer;
