import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Spinner } from 'react-bootstrap';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import { PasswordRegex } from '../../../constants';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm();
  const { loading: authLoading } = useSelector(state => state.auth);

  const handlePasswordChange = values => {
    const token = localStorage.getItem('aecp-ss-auth-token');
    setLoading(true);
    dispatch(resetPassword({ token, values }));
  };

  useEffect(() => {
    !authLoading && setLoading(false);
  }, [authLoading]);

  return (
    <Form onSubmit={handleSubmit(handlePasswordChange)}>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.changePassword.oldPassword')}
        </Form.Label>
        <CustomFormGroup
          error={errors.oldPassword}
          fixedLabel={true}
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          controlProps={{
            name: 'oldPassword',
            type: 'password',
            // disabled: loading,
            maxLength: 50,
            minLength: 6,
          }}
          register={register('oldPassword', {
            required: true,
            pattern: {
              value: PasswordRegex,
              message: 'invalidPassword_2',
            },
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.changePassword.newPassword')}
        </Form.Label>
        <CustomFormGroup
          error={errors.password}
          fixedLabel={true}
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          controlProps={{
            defaultValue: '',
            name: 'password',
            type: 'password',
          }}
          register={register('password', {
            required: true,
            pattern: {
              value: PasswordRegex,
              message: 'invalidPassword_2',
            },
            validate: value => value === watch('oldPassword') || 'passwordsNotMatch',
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.changePassword.repeatPassword')}
        </Form.Label>
        <CustomFormGroup
          fixedLabel={true}
          error={errors.confirmPassword}
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          controlProps={{
            defaultValue: '',
            name: 'confirmPassword',
            type: 'password',
          }}
          register={register('confirmPassword', {
            required: true,
            pattern: {
              value: PasswordRegex,
              message: 'invalidPassword_2',
            },
            validate: value => value === watch('password') || 'passwordsNotMatch',
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Col lg={9} xl={8} className="offset-lg-3">
          <Button type="submit" className="submit btn btn-de-primary">
            {loading && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="spinner" />
            )}
            {t('profile:settings.changePassword.save')}
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default ChangePassword;
