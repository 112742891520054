import {
    GET_ME_START, GET_ME_DONE, GET_ME_FAIL
  } from '../actionTypes';
  
  const INITIAL_STATE = {
    loading: true,
    error: undefined,
    data: {}
  };
  
  export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
    case GET_ME_START:
      return { ...state, loading: true, error: undefined };
    case GET_ME_DONE: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: { ...state.data }
      };
    }
    case GET_ME_FAIL: {
      return { ...state, loading: false, error: action.payload.error, data: { ...INITIAL_STATE.data } };
    }
    default:
      return state;
    }
  }
  