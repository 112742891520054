import moment from 'moment';
import React, { MouseEventHandler } from 'react';
import { AttachmentInfoType } from '../../hooks/useCloudinaryWidget';

type Props = {
  type: FileThumbnailType;
  file: AttachmentInfoType;
  actionIcon?: string;
  showActionIcon?: boolean;
  style?: object;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onActionClick?: MouseEventHandler<HTMLAnchorElement>;
};

export enum FileThumbnailType {
  square = 1,
  rect = 2,
}

function FileThumbnail({ type, file, actionIcon = 'la-download', showActionIcon = false, style, onClick, onActionClick }: Props) {
  switch (type) {
    case FileThumbnailType.rect:
      return (
        <div className="file-box-content">
          <div className="file-box cursor-pointer" onClick={onClick}>
            <div className="text-center d-flex">
              <i className="lar la-file-alt text-primary"></i>
              <h6>{file.name}</h6>
            </div>
          </div>
        </div>
      );
    case FileThumbnailType.square:
      return (
        <div className="file-box" key={file.id} style={style}>
          <a href="#" className="download-icon-link" onClick={onActionClick} style={showActionIcon? undefined : {visibility: 'hidden'}}>
            <i className={`las file-download-icon la-${actionIcon}`}></i>
          </a>
          <div className="text-center">
            <i
              className={`lar la-file text-info la-file-${file.format} la-file-${
                file.resourceType === 'raw' ? 'archive' : file.resourceType
              }`}
            ></i>
            <h6 className="text-truncate" title={file.name}>
              <a href={file.secureUrl} target="_blank" rel="noreferrer">{file.name}</a>
            </h6>
            <small className="text-muted">{moment(file.createdAt).format('D MMM, YYYY')}</small>
          </div>
        </div>
      );

    default:
      return null;
  }
}

FileThumbnail.propTypes = {};

export default FileThumbnail;
