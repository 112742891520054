import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import FilterMenu from '../../../components/FilterMenu/FilterMenu';

const Filter = ({ setParams, query, role }) => {
  const { t } = useTranslation();

  const userRoles = [
    { value: 'all', label: t('userRoles.all') },
    { value: 'admin', label: t('userRoles.admin') },
    { value: 'fmd', label: t('userRoles.fmd') },
    { value: 'regd', label: t('userRoles.regd') },
    { value: 'opht', label: t('userRoles.opht') },
    { value: 'nurse', label: t('userRoles.nurse') },
  ];

  const userStatuses = [
    { value: 'all', label: t('status.all') },
    { value: '1', label: t('status.active') },
    { value: '-1', label: t('status.inactive') },
  ];

  const [selectedRole, setSelectedRole] = useState({...userRoles[0]});
  const [selectedStatus, setSelectedStatus] = useState(userStatuses[0]);

  useEffect(() => {
    handleReset();
  }, [role]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
  } = useForm();

  const handleFilter = values => {
    selectedRole.value === 'all' ? delete values.role : (values.role = selectedRole.value);
    selectedStatus.value === 'all' ? delete values.status : (values.status = +selectedStatus.value === -1 ? 2 : 1);
    values.email === '' && delete values.email;
    values.name === '' && delete values.name;
    setParams(values);
  };

  const handleReset = (values, e) => {
    reset({
      name: '',
      email: '',
    });
    setSelectedStatus(userStatuses[0]);
    setSelectedRole(userRoles[0]);
    query.delete('name');
    query.delete('email');
    query.delete('role');
    query.delete('status');
    setParams({});
  };
  
  return (
    <FilterMenu>
      <Form onSubmit={handleSubmit(handleFilter)}>
        <ul className="list-unstyled components sd-body">
          <li>
            <CustomFormGroup
              label={t('staff:list.memberName')}
              groupProps={{ className: 'mb-3' }}
              error={errors.name}
              fixedLabel={true}
              controlProps={{
                id: 'name',
                name: 'name',
                maxLength: 100,
              }}
              register={register('name')}
            />
          </li>
          <li>
            <CustomFormGroup
              label={t('staff:list.email')}
              groupProps={{ className: 'mb-3' }}
              error={errors.email}
              fixedLabel={true}
              controlProps={{
                id: 'email',
                name: 'email',
                maxLength: 100,
              }}
              register={register('email')}
            />
          </li>
          {!role && (
            <li>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t('role')}</Form.Label>
                <Controller
                  name="role"
                  control={control}
                  value={selectedRole?.value}
                  render={() => {
                    return (
                      <CustomSelect
                        options={userRoles}
                        values={selectedRole ? [selectedRole] : []}
                        contentRenderer={() => (
                          <span className="text-size-smaller">
                            {selectedRole &&  t('userRoles.' + selectedRole.value)}
                          </span>
                        )}
                        onChange={selected => {
                          setSelectedRole(selected[0]);
                        }}
                      />
                    );
                  }}
                />
              </Form.Group>
            </li>
          )}
          <li>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>{t('staff:list.status')}</Form.Label>
              <Controller
                name="status"
                control={control}
                value={selectedStatus?.value}
                render={() => {
                  return (
                    <CustomSelect
                      options={userStatuses}
                      values={selectedStatus ? [selectedStatus] : []}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{selectedStatus && t('status.' + selectedStatus.value)}</span>
                      )}
                      onChange={selected => {
                        setSelectedStatus(selected[0]);
                      }}
                    />
                  );
                }}
              />
            </Form.Group>
          </li>
          <li>
            <FormGroup>
              <Button type="submit" className="submit btn btn-de-primary">
                {t('buttons.apply')}
              </Button>
              <Button as="button" className="btn btn-de-danger" onClick={handleReset}>
                {t('buttons.reset')}
              </Button>
            </FormGroup>
          </li>
        </ul>
      </Form>
    </FilterMenu>
  );
};

export default Filter;
