import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const AnotherIndication = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.anotherIndication.title')}</h5>
      <h6 className='mb-3 m-0'>{t('examinations:regdExam.anotherIndication.specify')}</h6>

      <Row>{[
        'medicines',
        'exercises',
        'other'
      ].map(indication => 
        <Col md={4} key={indication}>
          <CustomFormGroup
            label={t(`examinations:regdExam.anotherIndication.${indication}`)}
            register={{ ...register(`anotherIndication.${indication}`)}}
            error={ errors.anotherIndication?.[indication] }
            groupProps={{ className: 'col-md-12 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: `anotherIndication.${indication}`,
              name: `anotherIndication.${indication}`,
              defaultValue: data?.anotherIndication?.[indication]|| '',
              as: 'textarea'
            }}
          />
        </Col>
      )}
      </Row>
    </Row>
  );
};
export default AnotherIndication;
