import React from 'react';
import Separator from '../../../components/Separator/Separator';
import { Row } from 'react-bootstrap';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import { useTranslation } from 'react-i18next';

const MedicalRegistration = ({ register, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Separator text={`${t('patient:medicalRegistration.title')}`} />
      <Row>
        <CustomFormGroup
          register={{ ...register('medInstitutionName') }}
          label={t('patient:medicalRegistration.medInstitutionName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          fixedLabel={true}
          controlProps={{
            id: 'medInstitutionName',
            name: 'medInstitutionName',
            defaultValue: data?.medInstitutionName || ''
          }}
        />
        <CustomFormGroup
          register={{ ...register('familyDoctorName') }}
          label={t('patient:medicalRegistration.familyDoctorName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          fixedLabel={true}
          controlProps={{
            id: 'familyDoctorName',
            name: 'familyDoctorName',
            defaultValue: data?.familyDoctorName || ''
          }}
        />
      </Row>
    </>
  );

};
export default MedicalRegistration;
