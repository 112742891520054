import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Alert, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { useParams } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';

const Profile = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await axios.get(`/user/${id}`);
        if (response.status === 200) {
          setData(response.data.data.user);
        }
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  if (loading) {
    return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"
                    className="spinner" />;
  }

  if (error) {
    return (
      <Alert variant="danger">
        <p className="text-danger text-center m-2">{error.message}</p>
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:userProfile') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={t('pageTitles:userProfile')} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <div className="met-profile">
                <Row>
                  <Col md={6} className="align-self-center mb-3 mb-lg-0">
                    <div className="met-profile-main">
                      <Avatar avatarUrl={data?.avatar} />
                      <div className="met-profile_user-detail">
                        <h5 className="met-user-name">
                          {data?.fullName}{' '}
                          <small className="text-muted fw-normal fs-6">{t('userRoles.' + data?.role)}</small>
                        </h5>
                        <p className="mb-0 met-user-name-post">
                          {[data?.title, data?.company].filter(_ => !!_).join(', ')}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="ms-auto align-self-center">
                    <ul className="list-unstyled personal-detail mb-0">
                      <li className="">
                        <i className="las la-phone mr-2 text-secondary font-22 align-middle" />
                        <b> {t('profile:phone')} </b> : {data?.phone}
                      </li>
                      <li className="mt-2">
                        <i className="las la-envelope text-secondary font-22 align-middle mr-2" />
                        <b> {t('profile:email')} </b> : {data?.email}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

};
export default Profile;
