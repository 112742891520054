import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './config/locales/en/index';
import am from './config/locales/am/index';
import { UserRoles, Statuses, CountryRegions, YerevanCommunities, RelationToChild } from './constants';
import moment from 'moment';
import 'moment/locale/hy-am';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  ns: ['common', 'modals'],
  defaultNS: 'common',
  fallbackNS: 'common',
  resources: { en, am },
});

export const changeLanguage = lang => {
  i18n.changeLanguage(lang);
  moment.locale(lang);

  UserRoles.forEach(_ => {
    _.label = i18n.t('userRoles.' + _.value);
  });

  Statuses.forEach(_ => {
    _.label = i18n.t('status.' + _.key);
  });

  CountryRegions.forEach(_ => {
    _.label = i18n.t('address.region.' + _.key);
  });

  YerevanCommunities.forEach(_ => {
    _.label = i18n.t('address.community.' + _.key);
  });

  RelationToChild.forEach(_ => {
    _.label = i18n.t('patient:adultContacts.' + _.key);
  });
};

export default i18n;
