import React, { useCallback, Suspense, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import EditPatient from './EditPatient';
import PatientProfile from './PatientProfile';
import './Patient.scss';
import { AppContext } from '../../context/index.ts';

const Patient = ({ appLoading }) => {
  let { page, id } = useParams();
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);

  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
      case 'edit': {
        tab = <EditPatient/>;
        break;
      }
      case 'view': {
        tab = <PatientProfile />;
        break;
      }
      default: {
        tab = <Redirect to={`/${locale.value}/patient/${id}/view`} />;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:staff') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default Patient;
