import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Redirect, Route, useParams } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { lang } = useParams();
  return <Route {...rest} render={props => (
    localStorage.getItem('aecp-ss-auth-token')
      ? <Component {...props} />
      : <Redirect to={{ pathname: `/${lang}/sign-in`, state: { from: props.location } }} />
  )} />;
};


export default PrivateRoute;
