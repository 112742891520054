import { LOAD_PATIENT_START, LOAD_PATIENT_DONE, LOAD_PATIENT_FAIL } from '../actionTypes';

const INITIAL_STATE = {
  loading: false,
  error: undefined,
  data: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PATIENT_START:
      return { ...state, loading: true, error: undefined };
    case LOAD_PATIENT_DONE: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: { ...state.data, ...action.payload.data.patient },
      };
    }
    case LOAD_PATIENT_FAIL: {
      return { ...state, loading: false, error: action.payload.error, data: { ...INITIAL_STATE.data } };
    }
    default:
      return state;
  }
}
