import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const IntraocularPressure = ({ register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState<boolean>();
  const [lValue, setLValue] = useState<boolean>();
  const [sValue, setSValue] = useState<boolean>();

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.aboveTheNorm'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.aboveTheNorm'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );

  return (
    <>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.intraocularPressure.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'intraocularPressureState',
            control,
            defaultValue: data?.intraocularPressureState,
          }}
          onChange={v => {
            clearErrors('intraocularPressureState');
            setSValue(v as boolean);
            setValue('intraocularPressureState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.intraocularPressure.resultsOfPalpation')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'intraocularPressure.r.v',
              control,
              defaultValue: data?.intraocularPressure?.r?.v,
            }}
            onChange={v => {
              setRValue(v as boolean);
              setValue('intraocularPressure.r.v', v);
            }}
          />
          <CustomFormGroup
            register={{ ...register('intraocularPressure.r.result') }}
            label={t('examinations:regdExam.intraocularPressure.measurementResults')}
            error={errors.glassesPrescription?.intraocularPressure?.r?.result}
            groupProps={{ className: 'col-md-6 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'intraocularPressure.r.result',
              name: 'intraocularPressure.r.result',
              defaultValue: data?.intraocularPressure?.r?.result || '',
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.intraocularPressure.resultsOfPalpation')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'intraocularPressure.l.v',
              control,
              defaultValue: data?.intraocularPressure?.r?.v,
            }}
            onChange={v => {
              setLValue(v as boolean);
              setValue('intraocularPressure.l.v', v);
            }}
          />
          <CustomFormGroup
            register={{ ...register('intraocularPressure.l.result') }}
            label={t('examinations:regdExam.intraocularPressure.measurementResults')}
            error={errors.glassesPrescription?.intraocularPressure?.l?.result}
            groupProps={{ className: 'col-md-6 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'intraocularPressure.l.result',
              name: 'intraocularPressure.l.result',
              defaultValue: data?.intraocularPressure?.l?.result || '',
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default IntraocularPressure;
