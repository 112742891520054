import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function CustomCheckboxGroup({
  label,
  groupProps = {},
  labelProps = {},
  labelClass = '',
  inlineList = true,
  controlProps = {},
  items = [],
  onChange,
  disabled = false,
  inline = true,
}) {
  const { t } = useTranslation();

  const ItemContainer = useMemo(() => (inline ? React.Fragment : Form.Group), [inline]);

  return (
    <Form.Group {...groupProps}>
      {label && (
        <Form.Label {...labelProps} className={labelClass}>
          {label}
        </Form.Label>
      )}
      <Controller
        {...controlProps}
        onChange={onChange}
        render={({ field, fieldState: { invalid, error } }) => {
          // console.log('value', field);
          return (
            <>
              <div className={classnames({ 'is-invalid': invalid })}>
                {items.map((_, i) => (
                  <ItemContainer key={i}>
                    <Form.Check
                      {...field}
                      className={(inlineList ? 'd-inline-block' : '') + ' me-3'}
                      label={_.label}
                      checked={_.checked}
                      name={controlProps.name}
                      id={`${controlProps.name}-${i + 1}`}
                      onChange={() => onChange(_.value, !_.checked)}
                      type="checkbox"
                      disabled={_.disabled || disabled}
                      // inputRef={ref}
                    />
                  </ItemContainer>
                ))}
              </div>
              {error && (
                <Form.Control.Feedback type="invalid">
                  {(error.message && t(`errors:validationMessages.${error.message}`)) ||
                    (error.type === 'required' && (
                      <span id={`error-${controlProps.name}`}>{t('errors:validationMessages.required')}</span>
                    ))}
                </Form.Control.Feedback>
              )}
            </>
          );
        }}
      />
    </Form.Group>
  );
}

CustomCheckboxGroup.propTypes = {
  label: PropTypes.any,
  groupProps: PropTypes.object,
  labelProps: PropTypes.object,
  controlProps: PropTypes.object,
};
CustomCheckboxGroup.defaultProps = {};

export default CustomCheckboxGroup;
