import React, { useContext, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, FormGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../context/index.ts';
import { NavLink, withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import axios from '../../axios';
import SchoolForm from './SchoolForm/SchoolForm';

const AddSchool = ({ history }) => {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const { showSuccess, showError } = useContext(ToasterContext);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue
  } = useForm();

  const addSchool = async values => {
    try {
      setLoading(true);
      const response = await axios.post('school/create', values.school);
      showSuccess(t('messages:schoolCreated'));
      setTimeout(() => {
        history.push(`/${locale.value}/data/school/all`);
      }, 5000);
    } catch (err) {
      const error = err.response.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:schoolCreateFailed'));
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addSchool') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={t('pageTitles:addSchool')} />
      <Col className=" mx-auto new-patient">
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit(addSchool)}>
              <SchoolForm register={register} control={control} setValue={setValue} errors={errors} />
              <FormGroup className="form-group">
                <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="spinner"
                    />
                  )}
                  {t('buttons.create')}
                </Button>

                <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/data/school/all`}>
                  {t('buttons.cancel')}
                </NavLink>
              </FormGroup>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
export default withRouter(AddSchool);
