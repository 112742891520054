import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from '../../axios';
import { AppContext } from '../../context/index.ts';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import PageTitle from '../PageTitle/PageTitle';
import LoadingIndicator from '../Loading/LoadingIndicator';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import { Col, Card, Row } from 'react-bootstrap';
import { hasPermission } from '../../accessControl';
import Pagination from '../Pagination/Pagination';
import CustomTable from '../CustomTable/CustomTable';
import { CountryRegions } from '../../constants';
import Filter from '../../pages/School/Filter/Filter';

const SchoolList = ({ title, role }) => {
  const { locale } = useContext(AppContext);
  const query = useQuery();
  const { t } = useTranslation();
  const initailPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [data, setData] = useState({ schools: [], pagingCount: 0 });
  const [pageNumber, setPage] = useState(initailPage);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: t('school:list.name'),
        accessor: 'name',
        // Cell: ({ row }) => (
        //   <NavLink to={`/${locale.value}/data/school/${row?.original?.id}/view`} className="me-2">
        //     {row.original.name}
        //   </NavLink>
        // ),
      },
      {
        Header: t('school:list.region'),
        accessor: 'region',
        disableSortBy: true,
        Cell: ({ row }) => CountryRegions.find(_ => _.value === row?.original?.region).label,
      },
      {
        Header: t('school:list.settlement'),
        accessor: 'settlement',
      },
      {
        Header: t('school:list.settlementType'),
        accessor: 'settlementType',
        Cell: ({ value }) => t(`address.settlementType.${value}`),
      },
      {
        Header: t('school:list.address'),
        accessor: 'address',
        disableSortBy: true,
      },
      // {
      //   Header: t('patient:list:actions'),
      //   accessor: 'action',
      //   disableSortBy: true,
      //   className: 'text-end',
      //   headerClassName: 'text-end',
      //   Cell: ({ row }) => {
      //     const patientId = row?.original?.id;
      //     return (
      //       <>
      //         {hasPermission('readAny', 'patient') ? (
      //           <NavLink to={`/${locale.value}/data/${patientId}/view`} className="me-2">
      //             <i className="las la-eye text-primary font-16" />
      //           </NavLink>
      //         ) : null}
      //         {hasPermission('updateAny', 'patient') ? (
      //           <NavLink to={`/${locale.value}/data/${patientId}/edit`} className="me-1">
      //             <i className="las la-pen text-primary font-16" />
      //           </NavLink>
      //         ) : null}
      //       </>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);
  
      query.set('page', pageNumber);
  
      Object.keys(params).map(_ => {
        query.set(_, params[_]);
      });
  
      // history.push(`${location.pathname}?${query}`);
      const queryParams = { page: pageNumber, ...params };
      try {
        const response = await axios.get('/school/list', { params: queryParams });
        if (response.status === 200) {
          setData({
            schools: [...response.data.data.schools],
            pagingCount: Math.ceil(response.data.data.total / response.data.data.limit),
            total: response.data.data.total,
            limit: response.data.data.limit,
            page: response.data.data.page
          });
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
    fetchData();
  }, [pageNumber, params]);

  return (
    <>
      <Helmet>
        <title>{title + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={title} />
      <Row>
        <Col lg={9}>
          <Card>
            <Card.Body>
              {error ? (
                <div className="alert alert-outline-danger mb-0" role="alert">
                  <strong>Oh snap!</strong> {error.message}.
                </div>
              ) : loading ? (
                <LoadingIndicator />
              ) : !data?.schools?.length ? (
                <NoDataMessage
                  actionButton={{
                    title: `+ ${t('menu:other.school.addSchool')}`,
                    to: `/${locale.value}/data/school/create`,
                  }}
                />
              ) : (
                <>
                  <CustomTable columns={columns} data={data.schools} />
                  <div className="row">
                    <div className="col">
                      <p className=""><Trans i18nKey="showingNItems" components={{ start: data.limit*(data.page-1)+1, end: data.limit*(data.page - 1)+data.schools.length, total: data.total }} /></p>
                    </div>
                    <div className="col-auto">
                      {data.schools.length > 0 && <Pagination setPage={setPage} pagingCount={data.pagingCount} selectedPage={pageNumber}  />}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      {hasPermission('createAny', 'school') ? (
                        <NavLink to={`/${locale.value}/data/school/create`} className="btn btn-de-success btn-sm px-4 ">
                          + {t('menu:other.school.addSchool')}
                        </NavLink>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Filter setParams={setParams} query={query} />
      </Row>
    </>
  );
};
export default SchoolList;
