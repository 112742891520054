import {all} from 'redux-saga/effects';
import {watchAuth} from './auth';
import {watchPatient} from './patient';
import {watchData} from './data';


export function* watchAll() {
  yield all([
    watchAuth(),
    watchPatient(),
    watchData()
  ]);
}
