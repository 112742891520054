import React from 'react';
import classnames from 'classnames';
import { Col, Row, Spinner } from 'react-bootstrap';

function LoadingIndicator({ backdrop = false, cover = false }) {
  const style = {};
  if (cover) {
    style.top = 0;
    style.left = 0;
  }
  return (
    <Row
      className={classnames({
        'justify-content-center align-content-center p-5': true,
        'bg-backdrop': backdrop,
        'position-absolute w-100 h-100': cover
      })}
      style={style}
    >
      <Col sm="auto">
        <Spinner animation="border" role="status" variant="info">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  );
}

export default LoadingIndicator;
