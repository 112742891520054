import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import logoSmall from '../../assets/images/logo-light-sm.svg';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../context/index';
import './SidebarMenu.scss';
import { Badge, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DashboardMenu from './submenus/DashboardMenu';
import OtherMenu from './submenus/OtherMenu';
import StaffMenu from './submenus/StaffMenu';
import PatientsMenu from './submenus/PatientsMenu';
import { hasPermission } from '../../accessControl';
import { withRouter } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store';
import { AppContextType } from '../../context';

type SidebarItem = {
  id: string;
  accessible: boolean;
  icon: string;
  component: React.ReactNode;
  default: boolean;
  showBadge?: boolean | undefined;
  hasBadge?: boolean | undefined;
};

type Props = {
  location: any;
  toggleSidebar: (type: boolean) => void | undefined;
};

function SidebarMenu({ location, toggleSidebar }: Props) {
  const { locale } = useContext(AppContext) as AppContextType;
  const { t } = useTranslation();

  const { data } = useAppSelector<RootState>(state => state.data);

  const [sidebarItems, setSidebarItems] = useState<Array<SidebarItem>>([
    {
      id: 'dashboard',
      accessible: hasPermission('readOwn', 'dashboard'),
      icon: 'smart-home',
      component: <DashboardMenu />,
      default: location.pathname.includes('/dashboard/'),
    },
    {
      id: 'staff',
      accessible: hasPermission('readOwn', 'user'),
      icon: 'building-hospital',
      component: <StaffMenu />,
      default: location.pathname.includes('/staff/'),
    },
    {
      id: 'patients',
      accessible: hasPermission('readOwn', 'patient'),
      icon: 'users',
      component: <PatientsMenu />,
      default: location.pathname.includes('/patient') || location.pathname.includes('/screening'),
      hasBadge: true,
      showBadge: false,
    },
    {
      id: 'other',
      accessible: hasPermission('readAny', 'school'),
      icon: 'database',
      component: <OtherMenu />,
      default: location.pathname.includes('/data/'),
    },
  ]);

  useEffect(() => {
    const enableSidebarItems: Array<SidebarItem> = sidebarItems.filter(item => item.accessible);
    setSidebarItems(enableSidebarItems);
  }, []);

  useEffect(() => {
    const enableSidebarItems: Array<SidebarItem> = sidebarItems.filter(item => item.accessible).map(_ => ({
      ..._,
      showBadge:
        _.hasBadge && !!(data.badges.pendingPatients1 || data.badges.pendingPatients2 || data.badges.pendingPatients3),
    }));
    setSidebarItems(enableSidebarItems);
  }, [data]);

  return (
    <div className="leftbar-tab-menu">
      <Tab.Container
        id="leftbar-tabs"
        defaultActiveKey={'menu-tab-' + (sidebarItems.find(_ => !!_.default) || sidebarItems[0]).id}
        transition={false}
      >
        <div className="main-icon-menu">
          <NavLink to={`/${locale.value}/`} className="logo d-block text-center p-1 mt-n2">
            <img src={logoSmall} alt="logo-small" className="logo-sm logo-light" width="100%" />
          </NavLink>
          <div className="main-icon-menu-body">
            <div className="position-reletive h-100" data-simplebar style={{ overflowX: 'hidden' }}>
              <Nav variant="tabs">
                {sidebarItems.map(_ => (
                  <OverlayTrigger
                    key={_.id}
                    placement="right"
                    overlay={<Tooltip id={`tooltip-menu-${_.id}`}>{t(`menu:${_.id}.tooltip`)}</Tooltip>}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={`menu-tab-${_.id}`} onClick={() => toggleSidebar(true)}>
                        <i className={`ti ti-${_.icon} menu-icon`} />
                        {_.showBadge && (
                          <Badge
                            bg="transparent"
                            className="badge-dot online d-flex align-items-center position-absolute end-0 top-50"
                          />
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        <div className="main-menu-inner">
          <div className="topbar-left">
            <NavLink to={`/${locale.value}/`} className="logo">
              <img src={logo} alt="logo-large" className="logo-lg logo-dark" width="100%" />
            </NavLink>
          </div>
          <Tab.Content className="menu-body navbar-vertical">
            {sidebarItems.map(_ => (
              <Tab.Pane key={_.id} className="main-icon-menu-pane" eventKey={`menu-tab-${_.id}`}>
                {_.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}

SidebarMenu.propTypes = {};

export default withRouter(SidebarMenu);
