import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';
import { RegDExamSubProps } from '../AddREGDExam';

const GlassesPrescription = ({ register, control, setValue, errors, data } : RegDExamSubProps) => {
  const { t } = useTranslation();

  const [pValue, setPValue] = useState<number | undefined>();


  const mValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.glassesPrescription.no'),
        checked: pValue === 1,
        value: 1,
      },
      {
        label: t('examinations:regdExam.glassesPrescription.forNearOrFar'),
        checked: pValue === 2,
        value: 2,
      },
      {
        label: t('examinations:regdExam.glassesPrescription.forConstantWear'),
        checked: pValue === 3,
        value: 3,
      },
    ],
    [pValue]
  );

  return (
    <>
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.glassesPrescription.title')}</h5>

      <Row className="mb-3">
        <Col md={4} className="mb-3">
          <CustomRadioGroup
            labelClass="d-block"
            items={mValues}
            inline={false}
            controlProps={{
              name: 'glassesPrescription.value',
              control,
              rules: { validate: (v: number | undefined) => v !== undefined || 'required' },
            }}
            onChange={v => {
              setPValue(v as number);
              setValue('glassesPrescription.value', v);
            }}
          />
        </Col>
        <Col md={8}>
          <Row>
            {['od', 'os'].map(eye => 
              <Col key={eye} md={4}>
                <h5 className='mb-3 m-0'>{t(`examinations:regdExam.${eye}`)}</h5>
                {['sph', 'cyl', 'axis'].map((_ : string) => 
                  <Row key={_}>
                    <CustomFormGroup
                      key={_}
                      prependContent={t(`examinations:regdExam.${_}`)}
                      appendContent={null}
                      register={{ ...register(`glassesPrescription.${eye}.${_}`, { required: pValue === 2 || pValue === 3}) }}
                      // label={t('examinations:regdExam.glassesPrescription')}
                      error={errors.glassesPrescription?.[eye]?.[_]}
                      groupProps={{ className: 'mb-3' }}
                      fixedLabel={true}
                      controlProps={{
                        id: `glassesPrescription.${eye}.${_}`,
                        name: `glassesPrescription.${eye}.${_}`,
                        defaultValue: data?.glassesPrescription?.[eye]?.[_] || '',
                        disabled: !(pValue === 2 || pValue === 3)
                      }}
                    />
                  </Row>
                )}
              </Col>
            )}
            <Col md={4}>
              <h5 className='mb-3 m-0'>{t('examinations:regdExam.dpp')}</h5>
              <CustomFormGroup
                prependContent={null}
                appendContent={t('examinations:regdExam.mm')}
                register={{ ...register('glassesPrescription.dpp', { required: pValue === 2 || pValue === 3}) }}
                // label={t('examinations:regdExam.glassesPrescription')}
                error={errors.glassesPrescription?.dpp}
                groupProps={{ className: 'mb-3' }}
                fixedLabel={true}
                controlProps={{
                  id: 'glassesPrescription.dpp',
                  name: 'glassesPrescription.dpp',
                  defaultValue: data?.glassesPrescription?.dpp || '',
                  disabled: !(pValue === 2 || pValue === 3)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default GlassesPrescription;
