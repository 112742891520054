import React, { useMemo } from 'react';
import { Accordion } from 'react-bootstrap';
import ExaminationBody from './ExaminationBody';
import ExaminationHeader from './ExaminationHeader';

type Props = {
  item: ExaminationItemType;
};

export type ExaminationItemType = {
  type: string;
  data: ExaminationDataType;
  id: string;
};

export type ExaminationDataType = {
  id: string;
  date: string;
  nurse?: {
    [index: string]: string | number | boolean | object | null;
  };
  fmd?: {
    [index: string]: string | number | boolean | object | null;
  };
  regionalDoctor?: {
    [index: string]: string | number | boolean | object | null;
  };
  hasIssue?: boolean;
  editable: boolean;
};

type IconType = {
  [index: string]: string;
  schoolscreening: string;
  fmdexamination: string;
  regdexamination: string;
  ophthexamination: string;
};

function Examination({ item }: Props) {
  const icons = useMemo<IconType>(
    () => ({
      schoolscreening: 'graduation-cap',
      fmdexamination: 'user-nurse',
      regdexamination: 'glasses',
      ophthexamination: 'glasses',
    }),
    []
  );

  return (
    <Accordion.Item eventKey={item.id} className="border-0">
      <div className="activity-info">
        <div className="icon-info-activity">
          <i className={`las la-${icons[item.type]} bg-soft-primary`}></i>
        </div>
        <div className="activity-info-text">
          <ExaminationHeader item={item} />
          <ExaminationBody item={item} />
        </div>
      </div>
    </Accordion.Item>
  );
}

Examination.propTypes = {};

export default Examination;
