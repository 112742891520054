import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../context/index.ts';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../accessControl';

function AddExaminationMenu({
  patientId,
  drop = 'down',
  align = 'start',
  className,
  variant = 'primary',
  size = 'md',
  title = '',
  appointmentId
}) {
  const { locale } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Dropdown className={className} drop={drop} align={align}>
      <Dropdown.Toggle variant={variant} size={size}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {hasPermission('createAny', 'schoolScreening') ? (
          <Dropdown.Item as={NavLink} to={`/${locale.value}/patient/${patientId}/screening/create`}>
            <i className="las la-graduation-cap me-2"></i>
            {t('examinations:addScreening')}
          </Dropdown.Item>
        ) : null}

        {hasPermission('createAny', 'fmdExamination') ? (
          <Dropdown.Item as={NavLink} to={`/${locale.value}/patient/${patientId}/fmd-exam/create?${appointmentId ? 'appointment=' + appointmentId : ''}`}>
            <i className="las la-user-nurse me-2"></i>
            {t('examinations:addFMDExam')}
          </Dropdown.Item>
        ) : null}
        {hasPermission('createAny', 'regdExamination') ? (
          <Dropdown.Item as={NavLink}
          to={`/${locale.value}/patient/${patientId}/regd-exam/create?${appointmentId ? 'appointment=' + appointmentId : ''}`
          }>
            <i className="las la-glasses me-2"></i>
            {t('examinations:addRegExam')}
          </Dropdown.Item>
        ) : null}
        {hasPermission('createAny', 'ophthExamination') ? (
          <Dropdown.Item as={NavLink} to={`/${locale.value}/patient/${patientId}/ophth-exam/create`}>
            <i className="las la-glasses me-2"></i>
            {t('examinations:addOphtExam')}
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

AddExaminationMenu.propTypes = {};

export default AddExaminationMenu;
