import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const ColorSensitivityTest = ({ register, errors, data, clearErrors, setValue, control }) => {
  const { t } = useTranslation();

  const [sValue, setSValue] = useState();
  const [bValue, setBValue] = useState();

  const values = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: bValue === true,
        value: true,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: bValue === false,
        value: false,
      },
    ],
    [bValue]
  );

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  return (
    <Row className="mb-3">
      <Col className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.colorSensitivityTest.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'colorSensitivityTestState',
            control,
            defaultValue: data?.colorSensitivityTestState,
          }}
          onChange={v => {
            clearErrors('colorSensitivityTestState');
            setSValue(v);
            setValue('colorSensitivityTestState', v);
          }}
        />
      </Col>
      <Col className="mb-3">
        <h5 className='text-white'>placeholder</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.status')}
          labelClass="d-block"
          items={values}
          controlProps={{
            name: 'colorSensitivityTest',
            control,
            defaultValue: data?.colorSensitivityTest,
          }}
          onChange={v => {
            clearErrors('colorSensitivityTest');
            clearErrors('colorSensitivityTest');
            setBValue(v);
            setValue('colorSensitivityTest', v);
          }}
        />
      </Col>
    </Row>
  );
};
export default ColorSensitivityTest;
