import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadPatient } from '../store/actions';

/**
 * @typedef {Object} CurrentPatient
 * @property {string} patient
 * @property {boolean} loading
 * @property {object} error
 */

/**
 *  A custom hook that Loads the selected patient if not present in store
 * @returns {Object} CurrentPatient
 * @returns {string} CurrentPatient.patient
 * @returns {boolean} CurrentPatient.loading
 * @returns {object} CurrentPatient.error
 */
function usePatient() {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.patient);

  useEffect(() => {
    if (patientId && !loading) {
      dispatch(loadPatient({ id: patientId }));
    }
  }, [patientId]);

  return { patient: data, loading, error };
}

export default usePatient;
