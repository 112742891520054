import { all, put, takeLatest } from '@redux-saga/core/effects';
import axios from '../../axios';
import * as actionTypes from '../actionTypes';
import { loadDataDone, loadDataFail, loadDataListDone, loadDataListFail } from '../actions';

export function* watchData() {
  yield all([takeLatest(actionTypes.LOAD_DATA_START, loadData)]);
  yield all([takeLatest(actionTypes.LOAD_DATA_LIST_START, loadDataList)]);
}

function* loadData(action) {
  try {
    const response = yield axios.get('/data');

    if (response.status === 200) {
      yield put(loadDataDone({ data: { ...response.data.data } }));
    } else {
      yield put(loadDataFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = (err.toJSON && err.toJSON()) || {};
    delete errJson.config;
    // eslint-disable-next-line
    yield put(loadDataFail({ error: err.response?.data || errJson || err }));
  }
}

function* loadDataList(action) {
  try {
    const response = yield axios.get(`/${action.payload.type}/list`, {
      params: { role: action.payload.role, all: true },
    });

    if (response.status === 200) {
      const items = response.data.data.users.map(_ => ({
        value: _.id,
        label: _.fullName,
        info: { company: _.company, title: _.title, avatar: _.avatar },
      }));

      yield put(loadDataListDone({ data: { items, type: action.payload.role } }));
    } else {
      yield put(loadDataListFail({ error: response.data }));
    }
  } catch (err) {
    const errJson = (err.toJSON && err.toJSON()) || {};
    delete errJson.config;
    // eslint-disable-next-line
    yield put(loadDataListFail({ error: err.response?.data || errJson || err }));
  }
}
