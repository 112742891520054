import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store/hooks';
import { loadDataList } from '../store/actions';

/**
 * @typedef {Object} DataUserList
 * @property {Array} list
 * @property {boolean} loading
 * @property {Object} error
 */

/**
 *  A custom hook that Loads the requested role user list
 * @returns {Object} DataUserList
 * @returns {string} DataUserList.patient
 * @returns {boolean} DataUserList.loading
 * @returns {Object} DataUserList.error
 */
function useUserList(role: string) {
  const dispatch = useDispatch();
  const { data, loading, error } = useAppSelector(state => state.data);

  useEffect(() => {
    if (!data.lists[role]) {
      dispatch(loadDataList({ role, type: 'user' }));
    }
  }, [role, data.lists]);

  return { list: data.lists[role] || [], loading, error };
}

export default useUserList;
