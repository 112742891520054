import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const EyeExamination = ({ register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();
  const [sValue, setSValue] = useState<boolean>();

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );


  const deviations = [
    {
      'k': 'theColorOfTheOpticDisc', 
      'd': [
        'lightPink',
        'paleInPart',
        'paleAllOver'
      ]
    },
    {
      'k': 'limitsOfTheOpticNerveDisc', 
      'd': [
        'theBoundariesAreClear',
        'innerBordersFaint',
        'completelyDim'
      ]
    },
    {
      'k': 'discOfTheOpticNerveVascularBundle', 
      'd': [
        'central',
        'deviatedToTheNose',
        'excavation'
      ]
    },
    {
      'k': 'courseOfVessels', 
      'd': [
        'normal',
        'changed',
      ]
    },
    {
      'k': 'pulses', 
      'd': [
        'normal',
        'distressed',
        'expanded',
      ]
    },
    {
      'k': 'veins', 
      'd': [
        'normal',
        'expanded',
      ]
    },
    {
      'k': 'macularReflex', 
      'd': [
        'highlighted',
        'flattened',
      ]
    },
    {
      'k': 'retina', 
      'd': [
        'norma',
        'exfoliatedPartial',
        'exfoliatedCompletely',
        'exfoliatedWithMacularInvolvement',
        'otherRetinopathies',
      ]
    }
  ];

  const [rDeviationsValues, setRDeviationsValues] = useState(
    Object.assign(...deviations.map(_ => ({ [_.k]:
      {...Object.assign(..._.d.map(dev => ({ [dev]: false })))}
    })))
  );

  const [lDeviationsValues, setLDeviationsValues] = useState(
    Object.assign(...deviations.map(_ => ({ [_.k]:
      {...Object.assign(..._.d.map(dev => ({ [dev]: false })))}
    })))
  );

  useEffect(()=>{
    setValue('eyeExamination.r.deviations', rDeviationsValues);
  }, [rDeviationsValues]);

  useEffect(()=>{
    setValue('eyeExamination.l.deviations', lDeviationsValues);
  }, [lDeviationsValues]);


  return (
    <>
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.eyeExamination.title')}</h5>
      <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'eyeExaminationState',
            control,
            defaultValue: data?.eyeExaminationState,
            rules: { validate: (v: string) => v !== undefined || 'required' },

          }}
          onChange={v => {
            clearErrors('eyeExaminationState');
            setSValue(v);
            setValue('eyeExaminationState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {deviations.map((_, i) => 
            <React.Fragment key={'ex1-' + i}>
              <CustomCheckboxGroup
                label={t(`examinations:regdExam.eyeExamination.${_.k}`)}
                labelClass="d-block"
                items={
                  [..._.d.map(d => { return {
                    'label': t(`examinations:regdExam.eyeExamination.${d}`),
                    'checked': rDeviationsValues[_.k][d as keyof typeof rDeviationsValues],
                    'value': d
                  };})]
                }
                inline={false}
                controlProps={{
                  name: `eyeExamination.r.deviations.${_.k}.v`,
                  control,
                  defaultValue: data?.eyeExamination?.r?.deviations?.[_.k].v
                }}
                onChange={(v, c) => {
                  clearErrors('eyeExamination');
                  setRDeviationsValues({
                    ...rDeviationsValues,
                    [_.k]: {
                      ...rDeviationsValues[_.k],
                      [v]: c
                    }
                  });
                }}
              />
              { _.k === 'discOfTheOpticNerveVascularBundle' && 
              <CustomFormGroup
                prependContent={t('examinations:regdExam.eyeExamination.eS')}
                register={{ ...register(`eyeExamination.r.deviations.${_.k}.excavationDetails`) }}
                error={errors.eyeExamination?.r?.deviations?.[_.k]?.excavationDetails}
                groupProps={{ className: 'col-md-6 mb-3' }}
                fixedLabel={true}
                controlProps={{
                  id: `eyeExamination.r.deviations.${_.k}.excavationDetails`,
                  name: `eyeExamination.r.deviations.${_.k}.excavationDetails`,
                  defaultValue: data?.eyeExamination?.r?.deviations?.[_.k]?.excavationDetails || '',
                  disabled: !rDeviationsValues[_.k].excavation
                }}
              />}
            </React.Fragment>
          )}
        </Col>
        <Col md={6}>
          {deviations.map((_, i) => 
            <React.Fragment key={'ex2-' + i}>
              <CustomCheckboxGroup
                label={t(`examinations:regdExam.eyeExamination.${_.k}`)}
                labelClass="d-block"
                items={
                  [..._.d.map(d => { return {
                    'label': t(`examinations:regdExam.eyeExamination.${d}`),
                    'checked': lDeviationsValues[_.k][d as keyof typeof lDeviationsValues],
                    'value': d
                  };})]
                }
                inline={false}
                controlProps={{
                  name: `eyeExamination.l.deviations.${_.k}.v`,
                  control,
                  defaultValue: data?.eyeExamination?.l?.deviations?.[_.k].v
                }}
                onChange={(v, c) => {
                  clearErrors('eyeExamination');
                  setLDeviationsValues({
                    ...lDeviationsValues,
                    [_.k]: {
                      ...lDeviationsValues[_.k],
                      [v]: c
                    }
                  });
                }}
              />
              { _.k === 'discOfTheOpticNerveVascularBundle' && 
              <CustomFormGroup
                prependContent={t('examinations:regdExam.eyeExamination.eS')}
                register={{ ...register(`eyeExamination.l.deviations.${_.k}.excavationDetails`) }}
                error={errors.eyeExamination?.l?.deviations?.[_.k]?.excavationDetails}
                groupProps={{ className: 'col-md-6 mb-3' }}
                fixedLabel={true}
                controlProps={{
                  id: `eyeExamination.l.deviations.${_.k}.excavationDetails`,
                  name: `eyeExamination.l.deviations.${_.k}.excavationDetails`,
                  defaultValue: data?.eyeExamination?.l?.deviations?.[_.k]?.excavationDetails || '',
                  disabled: !lDeviationsValues[_.k].excavation
                }}
              />}
            </React.Fragment>
          )}
        </Col>
        
      </Row>
    </>
  );
};
export default EyeExamination;
