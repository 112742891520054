import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SymptomsFormValues({ data, className }) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const split = Object.keys(data).length > 10 ? 6 : 5;

  return (
    <Row className={className}>
      <Col md={6} className=" mb-3 mb-lg-0">
        {Object.entries(data)
          .slice(0, split)
          .map(([k, v]) => (
            <div key={k}>
              <FormLabel className="text-muted me-1">
                {t('patient:symptoms.' + k)}
                {t('formLabelSeparator')}
              </FormLabel>
              <span className="text-dark text-capitalize">{data[k] ? t('yes') : t('no')}</span>
            </div>
          ))}
      </Col>
      <Col md={6} className=" mb-3 mb-lg-0">
        {Object.entries(data)
          .slice(split)
          .map(([k, v]) => (
            <div key={k}>
              <FormLabel className="text-muted me-1">
                {t('patient:symptoms.' + k)}
                {t('formLabelSeparator')}
              </FormLabel>
              <span className="text-dark text-capitalize">{k === 'se12' ? v : (data[k] ? t('yes') : t('no'))}</span>
            </div>
          ))}
      </Col>
    </Row>
  );
}

SymptomsFormValues.propTypes = {};

export default SymptomsFormValues;
