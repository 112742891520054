import React, { useCallback, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import AddUser from './AddUser';
import UsersList from '../../components/UserList/UsersList';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { UserRoles } from '../../constants';

const Staff = ({ appLoading }) => {
  let { page } = useParams();
  const { t } = useTranslation();
  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
      case 'create': {
        tab = <AddUser />;
        break;
      }
      case 'all': {
        tab = <UsersList title={t('pageTitles:allUsers')} />;
        break;
      }
      case 'admins': {
        tab = <UsersList role="admin" title={t('pageTitles:admins')} />;
        break;
      }
      case 'nurses': {
        tab = <UsersList role="nurse" title={t('pageTitles:nurses')} />;
        break;
      }
      case 'family-doctors': {
        tab = <UsersList role="fmd" title={t('pageTitles:familyDoctors')} />;
        break;
      }
      case 'regional-doctors': {
        tab = <UsersList role='regd' title={t('pageTitles:regionalDoctors')} />;
        break;
      }
      case 'ophthalmologists': {
        tab = <UsersList role="opht" title={t('pageTitles:ophthalmologists')} />;
        break;
      }
      default: {
        tab = <UsersList />;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:staff') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default Staff;
