import React, { useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import AddREGDExam from './AddREGDExam';
import Helmet from 'react-helmet';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
import { Spinner } from 'react-bootstrap';

type Props = {
  appLoading: boolean;
};

function REGDExams({ appLoading }: Props) {
  const { page } = useParams();
  const { t } = useTranslation();
  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
      case 'create': {
        tab = <AddREGDExam />;
        break;
      }
      default: {
        tab = <AddREGDExam />;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:pendingPatients3') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
}

REGDExams.propTypes = {};

export default REGDExams;
