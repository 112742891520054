import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, ToasterContext } from '../../../../context';
import usePatient from '../../../../hooks/usePatient';
import moment from 'moment';
import axios from '../../../../axios';
import { Alert, Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { NavLink, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
import General from './ExaminationForm/General';
import { useDispatch } from 'react-redux';
import { loadData } from '../../../../store/actions';

type Props = { location: { search: string }; history: { push: (p: string) => void } };

function AddFMDExam({ location, history }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { patient, loading: patientLoading, error: patientError } = usePatient();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
  } = useForm();

  const { showSuccess, showError } = useContext(ToasterContext);

  const addNewExamination = async values => {
    try {
      setLoading(true);
      const query = new URLSearchParams(location.search);
      values.appointment = !query.get('appointment') ? undefined : query.get('appointment');
      values.patient = patient.id;
      values.date = moment(values.date).format('DD/MM/yyyy');
      if (values.dateRange) {
        
        values.dateRange = `${moment(values.dateRange[0]).format('DD/MM/yyyy')} - ${moment(values.dateRange[1]).format('DD/MM/yyyy')}`;
        // values.dateRange[1] = moment(values.dateRange[1]).format('DD/MM/yyyy');
      }
      if (values.nextAppointmentDate) {
        values.nextAppointmentDate = `${moment(values.nextAppointmentDate[0]).format('DD/MM/yyyy')} - ${moment(values.nextAppointmentDate[1]).format('DD/MM/yyyy')}`;
        // values.nextAppointmentDate[0] = moment(values.nextAppointmentDate[0]).format('DD/MM/yyyy');
        // values.nextAppointmentDate[1] = moment(values.nextAppointmentDate[1]).format('DD/MM/yyyy');
      }


      await axios.post('fmd-exam/create', values);

      showSuccess(t('messages:referenceCreated'));
      setTimeout(() => {
        dispatch(loadData());
        history.push(`/${locale.value}/patients/pending`);
      }, 5000);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:screeningCreateFailed'));
    }
    setLoading(false);
  };

  if (patientError) {
    return (
      <Alert variant="danger" className="mt-3">
        <p className="text-danger text-center m-2">{patientError.message}</p>
      </Alert>
    );
  }

  const pageTitle = (
    <>
      {t('examinations:addFMDExam')}:
      <NavLink className="ms-1 text-primary" to={`/${locale.value}/patient/${patient?.id}/view`}>
        {patient?.fullName}
      </NavLink>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addFMDExamination') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={pageTitle} />
      <Col className=" mx-auto new-examination">
        <Card>
          <Card.Body>
            {patientLoading ? (
              <LoadingIndicator />
            ) : (
              <Form onSubmit={handleSubmit(addNewExamination)}>
                <General register={register} control={control} setValue={setValue} errors={errors} />
                <Row className="mt-3">
                  <Col>
                    <FormGroup className="form-group">
                      <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                        <>
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="spinner"
                            />
                          )}
                          {t('buttons.create')}
                        </>
                      </Button>

                      <NavLink className="btn btn-de-danger" to={`/${locale.value}/patient/${patient?.id}/view`}>
                        {t('buttons.cancel')}
                      </NavLink>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

AddFMDExam.propTypes = {};

export default withRouter(AddFMDExam);
