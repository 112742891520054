import React from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import VisualAcuityAndRefraction from './StatusOculorum/VisualAcuityAndRefraction';
import MovementsOfTheEyeballs from './StatusOculorum/MovementsOfTheEyeballs';
import DelusionTest from './StatusOculorum/DelusiontTest';
import BinocularVisionTest from './StatusOculorum/BinocularVisionTest';
import CooperativeEyeWorkTest from './StatusOculorum/CooperativeEyeWorkTest';
import ColorSensitivityTest from './StatusOculorum/ColorSensitivityTest';
import AmountOfAccommodation from './StatusOculorum/AmountOfAccommodation';
import Accommodation from './StatusOculorum/Accommodation';
import CloseUpRefraction from './StatusOculorum/CloseUpRefraction';
import Eyelids from './StatusOculorum/Eyelids';
import LacrimalSystem from './StatusOculorum/LacrimalSystem';
import Shagkapeni from './StatusOculorum/Shagkapeni';
import Cornea from './StatusOculorum/Cornea';
import FrontChamber from './StatusOculorum/FrontChamber';
import Iris from './StatusOculorum/Iris';
import Pupil from './StatusOculorum/Pupil';
import Lens from './StatusOculorum/Lens';
import VitreousBody from './StatusOculorum/VitreousBody';
import Skiascope from './StatusOculorum/Skiascope';
import EyeExamination from './StatusOculorum/EyeExamination';
import IntraocularPressure from './StatusOculorum/IntraocularPressure';
import Autorefractometry from './StatusOculorum/Autorefractometry';
import StillLampExam from './StatusOculorum/StillLampExam';
import { RegDExamSubProps } from '../AddREGDExam';

const StatusOculorum = ({ patientId, register, control, setValue, errors, data, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Separator text={`${t('examinations:regdExam.statusOculorum')}`} />
      <VisualAcuityAndRefraction register={register} errors={errors}/>
      <MovementsOfTheEyeballs register={register} errors={errors} control={control} setValue={setValue}/>
      <DelusionTest register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <CooperativeEyeWorkTest register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <BinocularVisionTest register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <ColorSensitivityTest register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <AmountOfAccommodation register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Accommodation register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <CloseUpRefraction register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>

      {/* Slit-lamp examination */}
      <hr/>
      <StillLampExam register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <br/>
      <Eyelids register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <LacrimalSystem register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Shagkapeni register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Cornea register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <FrontChamber register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Iris register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Pupil register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Lens register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <VitreousBody register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <hr/>
      <Skiascope register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <Autorefractometry register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors} patientId={patientId}/>
      <IntraocularPressure register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>
      <EyeExamination register={register} errors={errors} control={control} setValue={setValue} clearErrors={clearErrors}/>

    </>
  );
};
export default StatusOculorum;
