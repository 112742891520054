import React, { useContext, useEffect, useState } from 'react';
import '../../App.scss';
import './SignInPage.scss';
import { Alert, Button, Card, Col, Container, Form, Image, Nav, NavItem, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../store/actions';
import { Redirect, useLocation } from 'react-router-dom';
import { AppContext } from '../../context/index.ts';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import CustomFormGroup from '../../components/CustomFormGroup/CustomFormGroup';
import i18n from '../../i18n';
import logo from '../../assets/images/logo.svg';
import { EmailRegex } from '../../constants';

const year = new Date().getFullYear();

const SignInPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useContext(AppContext);
  const { locale } = state;
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { loading, error, data: authData } = useSelector(state => state.auth);

  const handleSignIn = values => {
    dispatch(signIn(values));
  };

  useEffect(() => {
    if (localStorage.getItem('aecp-ss-auth-token')) {
      setRedirectToReferrer(true);
    }
  }, [authData]);

  if (redirectToReferrer) {
    return (
      <Redirect
        to={
          location.state && location.state.from.pathname !== `/${locale.value}/sign-in`
            ? '/'
            : { pathname: `/${locale.value}` }
        }
      />
    );
  }

  return (
    <div className="auth-page">
      <Helmet>
        <title>{t('pageTitles:login') + t('pageTitles:tail')}</title>
      </Helmet>
      <Container className=" container-md">
        <Row className="vh-100 justify-content-center">
          <Col md={12} className="align-self-center">
            <Card.Body>
              <Row>
                <Col lg={6} className="mx-auto text-center">
                  <NavLink to="/" className="logo logo-admin">
                    <Image src={logo} height="70" alt="logo" className="auth-logo mb-4" />
                  </NavLink>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mx-auto">
                  <Nav className="justify-content-end lang">
                    <NavItem>
                      <Nav.Link
                        className={`p-2 ${locale.value === 'en' ? 'active' : ''}`}
                        onClick={() => {
                          locale.setValue('en');
                          i18n.changeLanguage('en');
                        }}
                      >
                        <Image src="/images/flags/en_flag.jpg" alt="" height="15" className="me-2" />
                        {t('languages.en')}
                      </Nav.Link>
                    </NavItem>
                    <NavItem className="nav-link p-2">|</NavItem>
                    <NavItem>
                      <Nav.Link
                        className={`p-2 ${locale.value === 'am' ? 'active' : ''}`}
                        onClick={() => {
                          locale.setValue('am');
                          i18n.changeLanguage('am');
                        }}
                      >
                        <Image src="/images/flags/am_flag.jpg" alt="" height="15" className="me-2" />
                        {t('languages.am')}
                      </Nav.Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mx-auto">
                  <Card>
                    <Card.Body className="p-0 auth-header-box">
                      <div className="text-center p-3">
                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{t('auth:login.title')}</h4>
                        <p className="text-muted  mb-0">{t('auth:login.subtitle')}</p>
                      </div>
                    </Card.Body>
                    <Card.Body className="pt-0">
                      <Form className="my-4" onSubmit={handleSubmit(handleSignIn)}>
                        <div>
                          <CustomFormGroup
                            disabled={loading}
                            fixedLabel={true}
                            label={t('auth:email')}
                            error={errors.email}
                            groupProps={{ className: 'mb-2' }}
                            controlProps={{
                              id: 'email',
                              name: 'email',
                              disabled: loading,
                              placeholder: t('auth:emailPlaceholder'),
                            }}
                            register={register('email', {
                              required: true,
                              pattern: {
                                value: EmailRegex,
                                message: 'invalidEmail',
                              },
                            })}
                          />
                        </div>
                        <CustomFormGroup
                          disabled={loading}
                          fixedLabel={true}
                          label={t('auth:password')}
                          error={errors.password}
                          controlProps={{
                            id: 'password',
                            name: 'password',
                            type: 'password',
                            disabled: loading,
                            placeholder: t('auth:passwordPlaceholder'),
                          }}
                          register={register('password', { required: true })}
                        />
                        <Form.Group className="mt-3">
                          <Row>
                            <Col className="text-end">
                              <NavLink to={`/${locale.value}/forgot-password`} className="text-muted font-13">
                                <i className="dripicons-lock" /> {t('auth:login.forgotPassword')}
                              </NavLink>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group className="mb-0">
                          <Row>
                            <Col>
                              <div className="d-grid mt-3">
                                {error && (
                                  <p className="text-danger text-center m-2">{t('errors:codes.' + error.code)}</p>
                                )}
                                <Button className="btn btn-primary" disabled={loading} type="submit">
                                  {loading && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="spinner"
                                    />
                                  )}
                                  {t('auth:login.login')}
                                  <i className="fas fa-sign-in-alt ms-1" />
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                    <div className="card-body bg-light-alt text-center">
                      &copy;
                      {year}
                      <span className="m-2">{t('aecp')}</span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SignInPage;
