import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './Avatar.scss';
import { AdvancedImage, placeholder } from '@cloudinary/react';
import cloudinary from '../../cloudinary';
import { fill } from '@cloudinary/url-gen/actions/resize';

const Avatar = ({ avatarUrl, setAvatarUrl }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  let avatar = <img src={'/images/user-placeholder.png'} alt="" height="110" className="rounded-circle" />;
  if (avatarUrl) {
    const image = cloudinary.image(avatarUrl);
    image.resize(fill().height(220));
    avatar = <AdvancedImage cldImg={image} plugins={[placeholder()]} alt="" height="110" className="rounded-circle" />;
  }

  const showWidget = () => {
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'vectar',
        uploadPreset: 'thnyi16e',
        folder: 'aecp-school-screenings/avatars',
        cropping: true,
        sources: ['local'],
        language: lang,
        styles: {
          palette: {
            window: 'rgb(82,48,89)',
            sourceBg: '#FFFFFF',
            tabIcon: '#FFFFFF',
            menuIcons: '#D7D7D8',
            link: '#0b51b7',
            action: '#FFFFFF',
            error: '#FFFFFF'
          }
        },
        text: {
          [lang]: {
            or: t('cloudinary:widget.or'),
            back: t('cloudinary:widget.back'),
            close: t('cloudinary:widget.clode'),
            no_results: t('cloudinary:widget.no_results'),
            menu: {
              files: t('cloudinary:widget.files')
            },
            actions: {
              upload: t('cloudinary:widget.upload'),
              next: t('cloudinary:widget.next')
            },
            local: {
              browse: t('cloudinary:widget.browse'),
              dd_title_single: t('cloudinary:widget.dd_title_single'),
              dd_title_multi: t('cloudinary:widget.dd_title_multi'),
              drop_title_single: t('cloudinary:widget.drop_title_single'),
              drop_title_multiple: t('cloudinary:widget.drop_title_multiple')
            },
            crop: {
              title: t('cloudinary:widget.crop_title'),
              crop_btn: t('cloudinary:widget.crop_title'),
              skip_btn: t('cloudinary:widget.skip_btn'),
              reset_btn: t('cloudinary:widget.reset_btn'),
              close_btn: t('cloudinary:widget.close_btn'),
              close_prompt: t('cloudinary:widget.close_prompt'),
              image_error: t('cloudinary:widget.image_error'),
              corner_tooltip: t('cloudinary:widget.corner_tooltip'),
              handle_tooltip: t('cloudinary:widget.handle_tooltip')
            }
          }
        }
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setAvatarUrl(`${result.info.public_id}.${result.info.format}`);
        }
      }
    );
    widget.open();
  };

  return (
    <div className="met-profile-main-pic">
      {avatar}
      {setAvatarUrl &&
      <span className="met-profile_main-pic-change" onClick={showWidget}><i
        className="fas fa-camera" /></span>
      }
    </div>
  );

};

Avatar.propTypes = {
  avatar: PropTypes.string,
  setAvatarUrl: PropTypes.func.isRequired
};
Avatar.defaultProps = {};

export default Avatar;
