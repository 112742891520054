import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';

const ColorVisionCheck = ({ data, control, setValue }) => {
  const { t } = useTranslation();
  const [bValue, setBValue] = useState(data?.colorVisionCheck?.b);

  const values = useMemo(
    () => [
      {
        label: t('yes'),
        checked: bValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: bValue === false,
        value: false,
      },
    ],
    [bValue]
  );

  useEffect(() => {
    setValue('colorVisionCheck.b', data?.colorVisionCheck?.b);
  }, []);

  return (
    <>
      <Separator text={t('examinations:screenings.colorVisionCheck.title')} hint={t('examinations:screenings.colorVisionCheck.hint')}/>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:bothEyes')}</h5>
          <CustomRadioGroup
            label={t('examinations:screenings.colorVisionCheck.identifiesNumbers')}
            labelClass="d-block"
            items={values}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'colorVisionCheck.b',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              // defaultValue: data?.colorVisionCheck?.b,
            }}
            onChange={v => {
              setBValue(v);
              setValue('colorVisionCheck.b', v);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ColorVisionCheck;
