import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const Skiascope = ({ register, errors, data, clearErrors, setValue, control }: RegDExamSubProps) => {
  const { t } = useTranslation();
  const [sValue1, setSValue1] = useState<boolean>();
  const [sValue2, setSValue2] = useState<boolean>();

  const stateValues1 = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue1 === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue1 === false,
        value: false,
      },
    ],
    [sValue1]
  );
  const stateValues2 = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue2 === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue2 === false,
        value: false,
      },
    ],
    [sValue2]
  );
  return (
    <>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.skiascopeNarrowAperture.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues1}
          controlProps={{
            name: 'skiascopeNarrowApertureState',
            control,
            defaultValue: data?.skiascopeNarrowApertureState,
          }}
          onChange={v => {
            clearErrors('skiascopeNarrowApertureState');
            setSValue1(v as boolean);
            setValue('skiascopeNarrowApertureState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        {['rightEyeOD', 'leftEyeOS'].map(eye => (
          <Col key={eye}>
            <h5 className="mb-4 m-0">{t(`examinations:regdExam.${eye}`)}</h5>
            <CustomFormGroup
              register={{ ...register(`skiascope.${eye}.narrow`) }}
              label={t('examinations:regdExam.skiascopeNarrowAperture.visionCorrection')}
              error={errors.glassesPrescription?.skiascope?.[eye]?.narrow}
              groupProps={{ className: 'col-md-6 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: `skiascope.${eye}.narrow`,
                name: `skiascope.${eye}.narrow`,
                defaultValue: data?.glassesPrescription?.skiascope?.[eye]?.narrow || '',
              }}
            />
          </Col>
        ))}
      </Row>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.skiascopeWideAperture.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues2}
          controlProps={{
            name: 'skiascopeWideApertureState',
            control,
            defaultValue: data?.skiascopeWideApertureState,
          }}
          onChange={v => {
            clearErrors('skiascopeWideApertureState');
            setSValue2(v as boolean);
            setValue('skiascopeWideApertureState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        {['rightEyeOD', 'leftEyeOS'].map(eye => (
          <Col key={eye}>
            <h5 className="mb-4 m-0">{t(`examinations:regdExam.${eye}`)}</h5>
            <CustomFormGroup
              register={{ ...register(`skiascope.${eye}.wide`) }}
              label={t('examinations:regdExam.skiascopeWideAperture.visionCorrection')}
              error={errors.glassesPrescription?.skiascope?.[eye]?.wide}
              groupProps={{ className: 'col-md-6 mb-3' }}
              fixedLabel={true}
              controlProps={{
                id: `skiascope.${eye}.wide`,
                name: `skiascope.${eye}.wide`,
                defaultValue: data?.glassesPrescription?.skiascope?.[eye]?.wide || '',
              }}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
export default Skiascope;
