import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './FilterMenu.scss';

function FilterMenu({ children }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className={'filter-menu ' + (open ? 'open' : '')}>
      <Button variant="primary" className={`btn-square rounded-start ${open ? 'hidden-sm' : ''}`} onClick={toggleMenu}>
        <i className="ti ti-filter" />
        <span>{t('staff:list.filter')}</span>
      </Button>
      <div className="menu bg-white h-100 p-4 border-start">
        <h5 className="mt-0 mb-3">{t('staff:list.filter')}</h5>

        <Button
          type="button"
          className={'btn-close text-reset p-0 mb-3 align-self-center float-end'}
          aria-label="Close"
          onClick={toggleMenu}
        />
        {children}
      </div>
    </div>
  );
}

FilterMenu.propTypes = {};

export default FilterMenu;
