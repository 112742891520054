import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, ToasterContext } from '../../../../context';
import usePatient from '../../../../hooks/usePatient';
import moment from 'moment';
import axios from '../../../../axios';
import { Alert, Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { Control, Controller, useForm } from 'react-hook-form';
import { NavLink, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Separator from '../../../../components/Separator/Separator';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
// import General from './ExaminationForm/General';
import ExaminationDetails from './ExaminationForm/ExaminationDetails';
import Complaints from './ExaminationForm/Deseases';
import { useDispatch } from 'react-redux';
import { loadData } from '../../../../store/actions';
import SubjectiveSymptoms from './ExaminationForm/SubjectiveSymptoms';
import Deseases from './ExaminationForm/Deseases';
import Surgeries from './ExaminationForm/Surgeries';
import GlassesPrescription from './ExaminationForm/GlassesPrescription';
import StatusOculorum from './ExaminationForm/StatusOculorum';
import Diagnosis from './ExaminationForm/Diagnosis';
import ReferOpht from './ExaminationForm/ReferOpht';
import { REGDExaminationDataType } from './REGDExamValues/REGDExamValues';

export type RegDExamSubProps = {
  patientId?: string;
  register: (name: string, rules?: { required?: boolean }) => object;
  control: Control;
  errors: any;
  setValue: (key: string, value: any) => void;
  clearErrors: (key: string) => void
  data?: REGDExaminationDataType;
};

type Props = { location: { search: string }; history: { push: (p: string) => void } };

function AddREGDExam({ location, history }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { patient, loading: patientLoading, error: patientError } = usePatient();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    clearErrors
  } = useForm();

  const { showSuccess, showError } = useContext(ToasterContext);

  const addNewExamination = async values => {
    try {
      setLoading(true);
      
      const query = new URLSearchParams(location.search);
      values.appointment = query.get('appointment') || undefined;
      values.patient = patient.id;
      values.date = moment(values.date).format('DD/MM/yyyy');
      if (values.dateRange) {
        
        values.dateRange = `${moment(values.dateRange[0]).format('DD/MM/yyyy')} - ${moment(values.dateRange[1]).format('DD/MM/yyyy')}`;
        // values.dateRange[1] = moment(values.dateRange[1]).format('yyyy-MM-DD');
      }
      if (values.nextAppointmentDate) {
        values.nextAppointmentDate = `${moment(values.nextAppointmentDate[0]).format('DD/MM/yyyy')} - ${moment(values.nextAppointmentDate[1]).format('DD/MM/yyyy')}`;
        // values.nextAppointmentDate[0] = moment(values.nextAppointmentDate[0]).format('yyyy-MM-DD');
        // values.nextAppointmentDate[1] = moment(values.nextAppointmentDate[1]).format('yyyy-MM-DD');
      }

      delete values.deseasesFirstGroup;
      delete values.deseasesSecondGroup;
      
      console.log(values);

      const response = await axios.post('regd-exam/create', values);

      showSuccess(t('messages:examinationCreated'));
      setTimeout(() => {
        dispatch(loadData());
        history.push(`/${locale.value}/patients/pending`);
      }, 5000);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:examinationCreateFailed'));
    }
    setLoading(false);
  };

  if (patientError) {
    return (
      <Alert variant="danger" className="mt-3">
        <p className="text-danger text-center m-2">{patientError.message}</p>
      </Alert>
    );
  }

  const pageTitle = (
    <>
      {t('examinations:addRegExam')}:
      <NavLink className="ms-1 text-primary" to={`/${locale.value}/patient/${patient?.id}/view`}>
        {patient?.fullName}
      </NavLink>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addFMDExamination') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={pageTitle} />
      <Col className=" mx-auto new-examination">
        <Card>
          <Card.Body>
            {patientLoading ? (
              <LoadingIndicator />
            ) : (
              <Form onSubmit={handleSubmit(addNewExamination)}>
                <ExaminationDetails register={register} control={control} setValue={setValue} errors={errors} />
                <SubjectiveSymptoms control={control} setValue={setValue} type="regd"/>
                <Deseases register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors}/>
                <Surgeries register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors}/>
                <GlassesPrescription register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors}/>
                <StatusOculorum register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors} patientId={patient?.id}/> 
                <Diagnosis register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors}/>
                <ReferOpht register={register} control={control} setValue={setValue} errors={errors} clearErrors={clearErrors}/>

                <Row className="mt-3">
                  <Col>
                    <FormGroup className="form-group">
                      <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                        <>
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="spinner"
                            />
                          )}
                          {t('buttons.create')}
                        </>
                      </Button>

                      <NavLink className="btn btn-de-danger" to={`/${locale.value}/patient/all`}>
                        {t('buttons.cancel')}
                      </NavLink>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

AddREGDExam.propTypes = {};

export default withRouter(AddREGDExam);
