import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';
import { REGDExaminationDataVitreousBodySubType } from '../../REGDExamValues/REGDExamValues';

const VitreousBody = ({ register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();

  const [rValue, setRValue] = useState<boolean>();
  const [lValue, setLValue] = useState<boolean>();

  const lValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: lValue === false,
        value: false,
      },
      {
        label: t('examinations:regdExam.hasIssue'),
        checked: lValue === true,
        value: true,
      },
    ],
    [lValue]
  );

  const rValues = useMemo(
    () => [
      {
        label: t('examinations:regdExam.norm'),
        checked: rValue === false,
        value: false,
      },
      { 
        label: t('examinations:regdExam.hasIssue'),
        checked: rValue === true,
        value: true,
      },
    ],
    [rValue]
  );

  const deviations = [
    'cells',
    'hemophthalmus',
    'destruction',
    'other'
  ];


  const [rDeviationsValues, setRDeviationsValues] = useState<REGDExaminationDataVitreousBodySubType>(
    deviations.reduce((o, key) => ({ ...o, [key]: {v: false, status: ''}}), {})
  );

  const [lDeviationsValues, setLDeviationsValues] = useState<REGDExaminationDataVitreousBodySubType>(
    deviations.reduce((o, key) => ({ ...o, [key]: {v: false, status: ''}}), {})
  );

  useEffect(()=>{
    setValue('vitreousBody.r.deviations', rDeviationsValues);
  }, [rDeviationsValues]);

  useEffect(()=>{
    setValue('vitreousBody.l.deviations', lDeviationsValues);
  }, [lDeviationsValues]);


  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.vitreousBody.title')}</h5>
      <Row>
        <Col md={6}>
          <h5>{t('examinations:regdExam.rightEyeOD')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={rValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'vitreousBody.r.v',
              control,
              rules: { validate: (v: string) => control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required' },
              defaultValue: data?.vitreousBody?.r?.v,
            }}
            onChange={v => {
              setRValue(v as boolean);
              setValue('vitreousBody.r.v', v);
            }}
          />
        </Col>
        <Col md={6}>
          <h5>{t('examinations:regdExam.leftEyeOS')}</h5>
          <CustomRadioGroup
            label={t('examinations:regdExam.status')}
            labelClass="d-block"
            items={lValues}
            groupProps={{ className: 'mb-3' }}
            disabled={control._formValues.stillLampExamState === false}
            controlProps={{
              name: 'vitreousBody.l.v',
              control,
              rules: { validate: (v: string) => control._formValues.stillLampExamState === false || control._formValues.stillLampExamState === true && v !== undefined || 'required' },
              defaultValue: data?.vitreousBody?.l?.v,
            }}
            onChange={v => {
              setLValue((v as boolean));
              setValue('vitreousBody.l.v', v);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h6>{t('examinations:regdExam.ifDeviation')}</h6>
          {deviations.map(d => 
            <React.Fragment key={'r' + d}>
              <CustomCheckboxGroup key={d}
                labelClass="d-block"
                items={
                  [{
                    'label': t(`examinations:regdExam.vitreousBody.${d}`),
                    'checked': rDeviationsValues[d as keyof typeof rDeviationsValues].v,
                    'value': d
                  }]
                }
                inline={false}
                disabled={!rValue || control._formValues.stillLampExamState === false}
                controlProps={{
                  name: `vitreousBody.r.deviations.${d}.v`,
                  control,
                  defaultValue: data?.vitreousBody?.r?.deviations?.[d].v
                }}
                onChange={(v, c) => {
                  clearErrors('vitreousBody');
                  setRDeviationsValues({
                    ...rDeviationsValues,
                    [v]: {
                      ...rDeviationsValues[v],
                      v: c
                    }
                  });
                }}
              />
              {d === 'other' ? 
                <CustomFormGroup 
                  register={{ ...register(`vitreousBody.r.deviations.${d}.details`) }}
                  error={errors.vitreousBody?.r?.deviations?.[d]?.details}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  fixedLabel={true}
                  controlProps={{
                    id: `vitreousBody.r.deviations.${d}.details`,
                    name: `vitreousBody.r.deviations.${d}.details`,
                    defaultValue: data?.vitreousBody?.r?.deviations?.[d]?.details || '',
                    disabled: !rDeviationsValues[d].v || control._formValues.stillLampExamState === false
                  }}
                />
                : 
                (d === 'cells' || d === 'destruction') ? 
                  <CustomRadioGroup
                    label={t('examinations:regdExam.manifestation')}
                    labelClass="d-block"
                    items={[
                      ...(d === 'cells' ? ['+','++', '+++'] : ['weak', 'medium', 'strong']).map(s => {
                        return {
                          label: t(`examinations:regdExam.statuses.${s}`),
                          checked: rDeviationsValues[d].status === s,
                          value: s,
                        };
                      })
                    ]}
                    groupProps={{ className: 'mb-3' }}
                    controlProps={{
                      name: `vitreousBody.r.deviations.${d}.status`,
                      control,
                      defaultValue: data?.vitreousBody?.r?.deviations?.[d]?.status,
                    }}
                    disabled={!rValue || !rDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                    onChange={v => {
                      setRDeviationsValues({
                        ...rDeviationsValues,
                        [d]: {
                          ...rDeviationsValues[d],
                          status: v
                        }
                      });
                      setValue(`vitreousBody.r.deviations.${d}.status`, v);
                    }}
                  /> : 
                  <>
                    <CustomRadioGroup
                      label={t('examinations:regdExam.manifestation')}
                      labelClass="d-block"
                      items={[
                        ...['partial', 'total'].map(s => {
                          return {
                            label: t(`examinations:regdExam.statuses.${s}`),
                            checked: rDeviationsValues[d].status === s,
                            value: s,
                          };
                        })
                      ]}
                      groupProps={{ className: 'mb-3' }}
                      controlProps={{
                        name: `vitreousBody.r.deviations.${d}.status`,
                        control,
                        defaultValue: data?.vitreousBody?.r?.deviations?.[d]?.status,
                      }}
                      disabled={!rValue || !rDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                      onChange={v => {
                        setRDeviationsValues({
                          ...rDeviationsValues,
                          [d]: {
                            ...rDeviationsValues[d],
                            status: v
                          }
                        });
                        setValue(`vitreousBody.r.deviations.${d}.status`, v);
                      }}
                    />
                    <CustomRadioGroup
                      label={t('examinations:regdExam.vitreousBody.antiquity')}
                      labelClass="d-block"
                      items={[
                        ...['fresh', 'old'].map(s => {
                          return {
                            label: t(`examinations:regdExam.statuses.${s}`),
                            checked: rDeviationsValues[d].antiquity === s,
                            value: s,
                          };
                        })
                      ]}
                      groupProps={{ className: 'mb-3' }}
                      controlProps={{
                        name: `vitreousBody.r.deviations.${d}.antiquity`,
                        control,
                        defaultValue: data?.vitreousBody?.r?.deviations?.[d]?.antiquity,
                      }}
                      disabled={!rValue || !rDeviationsValues[d].v}
                      onChange={v => {
                        setRDeviationsValues({
                          ...rDeviationsValues,
                          [d]: {
                            ...rDeviationsValues[d],
                            antiquity: v
                          }
                        });
                        setValue(`vitreousBody.r.deviations.${d}.antiquity`, v);
                      }}
                    />
                  </>
              }
            </React.Fragment>
          )}
        </Col>
        <Col md={6}>
          <h6 className='mb-3 m-0'>{t('examinations:regdExam.ifDeviation')}</h6>
          {deviations.map(d => 
            <React.Fragment key={'l' + d}>
              <CustomCheckboxGroup
                labelClass="d-block"
                items={
                  [{
                    'label': t(`examinations:regdExam.vitreousBody.${d}`),
                    'checked': lDeviationsValues[d as keyof typeof lDeviationsValues].v,
                    'value': d
                  }]
                }
                inline={false}
                disabled={!lValue || control._formValues.stillLampExamState === false}
                controlProps={{
                  name: `vitreousBody.l.deviations.${d}.v`,
                  control,
                  defaultValue: data?.vitreousBody?.l?.deviations?.[d].v
                }}
                onChange={(v, c) => {
                  clearErrors('vitreousBody');
                  setLDeviationsValues({
                    ...lDeviationsValues,
                    [v]: {
                      ...lDeviationsValues[v],
                      v: c
                    }
                  });
                }}
              />
              {d === 'other' ? 
                <CustomFormGroup
                  register={{ ...register(`vitreousBody.l.deviations.${d}.details`) }}
                  error={errors.vitreousBody?.l?.deviations?.[d]?.details}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  fixedLabel={true}
                  controlProps={{
                    id: `vitreousBody.l.deviations.${d}.details`,
                    name: `vitreousBody.l.deviations.${d}.details`,
                    defaultValue: data?.vitreousBody?.l?.deviations?.[d]?.details || '',
                    disabled: !lDeviationsValues[d].v || control._formValues.stillLampExamState === false
                  }}
                />
                : 
                (d === 'cells' || d === 'destruction') ? 
                  <CustomRadioGroup
                    label={t('examinations:regdExam.manifestation')}
                    labelClass="d-block"
                    items={[
                      ...(d === 'cells' ? ['+','++', '+++'] : ['weak', 'medium', 'strong']).map(s => {
                        return {
                          label: t(`examinations:regdExam.statuses.${s}`),
                          checked: lDeviationsValues[d].status === s,
                          value: s,
                        };
                      })
                    ]}
                    groupProps={{ className: 'mb-3' }}
                    controlProps={{
                      name: `vitreousBody.l.deviations.${d}.status`,
                      control,
                      defaultValue: data?.vitreousBody?.l?.deviations?.[d]?.status,
                    }}
                    disabled={!lValue || !lDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                    onChange={v => {
                      setLDeviationsValues({
                        ...lDeviationsValues,
                        [d]: {
                          ...lDeviationsValues[d],
                          status: v
                        }
                      });
                      setValue(`vitreousBody.l.deviations.${d}.status`, v);
                    }}
                  /> : 
                  <>
                    <CustomRadioGroup
                      label={t('examinations:regdExam.manifestation')}
                      labelClass="d-block"
                      items={[
                        ...['partial', 'total'].map(s => {
                          return {
                            label: t(`examinations:regdExam.statuses.${s}`),
                            checked: lDeviationsValues[d].status === s,
                            value: s,
                          };
                        })
                      ]}
                      groupProps={{ className: 'mb-3' }}
                      controlProps={{
                        name: `vitreousBody.l.deviations.${d}.status`,
                        control,
                        defaultValue: data?.vitreousBody?.l?.deviations?.[d]?.status,
                      }}
                      disabled={!lValue || !lDeviationsValues[d].v || control._formValues.stillLampExamState === false}
                      onChange={v => {
                        setLDeviationsValues({
                          ...lDeviationsValues,
                          [d]: {
                            ...lDeviationsValues[d],
                            status: v
                          }
                        });
                        setValue(`vitreousBody.l.deviations.${d}.status`, v);
                      }}
                    />
                    <CustomRadioGroup
                      label={t('examinations:regdExam.vitreousBody.antiquity')}
                      labelClass="d-block"
                      items={[
                        ...['fresh', 'old'].map(s => {
                          return {
                            label: t(`examinations:regdExam.statuses.${s}`),
                            checked: lDeviationsValues[d].antiquity === s,
                            value: s,
                          };
                        })
                      ]}
                      groupProps={{ className: 'mb-3' }}
                      controlProps={{
                        name: `vitreousBody.l.deviations.${d}.antiquity`,
                        control,
                        defaultValue: data?.vitreousBody?.l?.deviations?.[d]?.antiquity,
                      }}
                      disabled={!lValue || !lDeviationsValues[d].v}
                      onChange={v => {
                        setLDeviationsValues({
                          ...lDeviationsValues,
                          [d]: {
                            ...lDeviationsValues[d],
                            antiquity: v
                          }
                        });
                        setValue(`vitreousBody.l.deviations.${d}.antiquity`, v);
                      }}
                    />
                  </>
              }
            </React.Fragment>
          )}
        </Col>
      </Row>
    </Row>
  );
};
export default VitreousBody;
