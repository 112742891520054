import React from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../../components/Separator/Separator';
import SymptomsFormFields from '../../../../../components/SymptomsForm/SymptomsForm';

const SubjectiveSymptoms = ({ control, setValue, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Separator text={`${t('examinations:screenings.subjectiveSymptoms')}`} />
      <SymptomsFormFields control={control} setValue={setValue} data={data} />
    </>
  );
};
export default SubjectiveSymptoms;
