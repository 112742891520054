import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form, FormGroup, Spinner, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../context/index.ts';
import { NavLink, useParams, withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import ParentEvaluation from './PatientForm/ParentEvaluation';
import PersonalInformation from './PatientForm/PersonalInformation';
import LivingAddress from './PatientForm/LivingAddress';
import MedicalRegistration from './PatientForm/MedicalRegistration';
import AdultContacts from './PatientForm/AdultContacts';
import axios from '../../axios';
import Attachments from './PatientForm/Attachments/Attachments';
import moment from 'moment';

const EditPatient = ({ history }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [attacheFile, setAttachFile] = useState();
  const [attachFileError, setAttachFileError] = useState(false);
  const { showSuccess, showError } = useContext(ToasterContext);
  const [data, setData] = useState();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    getValues,
    setError,
    clearErrors,
    unregister,
    setValue,
  } = useForm();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await axios.get(`/patient/${id}`);
        if (response.status === 200) {
          setData(response.data.data.patient);
        }
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const editPatient = async values => {
    if (!data && !attacheFile) {
      setAttachFileError(true);
    } else {
      values.parentConcent = attacheFile;
      try {
        setLoading(true);
        values.dob = moment(values.dob).format('DD/MM/yyyy');
        const response = await axios.post(`patient/${id}/update`, values);
        showSuccess(t('messages:patientUpdated'));
        // setTimeout(() => {
        //   history.push(`/${locale.value}/patients/all`);
        // }, 5000);
      } catch (err) {
        const error = err.response.data;
        showError(error.code ? t('errors:codes.' + error.code) : t('messages:patientUpdateFailed'));
      }
    }
    setLoading(false);
  };

  return (
    <>
      {data && (
        <>
          <Helmet>
            <title>{t('pageTitles:editPatient') + t('pageTitles:tail')}</title>
          </Helmet>
          <PageTitle title={t('pageTitles:editPatient')} />
          <Col className=" mx-auto new-patient">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit(editPatient)}>
                  <PersonalInformation
                    data={data}
                    register={register}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    clearErrors={clearErrors}
                    setError={setError}
                  />
                  <LivingAddress
                    data={data}
                    register={register}
                    control={control}
                    unregister={unregister}
                    setValue={setValue}
                    errors={errors}
                  />

                  <MedicalRegistration register={register} data={data} />
                  <AdultContacts register={register} control={control} errors={errors} data={data} />
                  <ParentEvaluation register={register} control={control} setValue={setValue} data={data} />
                  <Attachments setAttachFile={setAttachFile} error={attachFileError} data={data?.parentConcent} />
                  <Row>
                    <Col xs={12} className="mt-4">
                      <FormGroup className="form-group">
                        <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="spinner"
                            />
                          )}
                          {t('buttons.save')}
                        </Button>

                        <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/patient/${id}/view`}>
                          {t('buttons.cancel')}
                        </NavLink>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};

export default withRouter(EditPatient);
