import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function PageTitle({ title, breadcrumb }) {
  return (
    <div className="page-title-box">
      <div className="float-end">
        {breadcrumb && (
          <ol className="breadcrumb">
            {breadcrumb.map((_, index) => (
              <li key={index} className={`breadcrumb-item ${_.active ? 'active' : ''}`}>
                <NavLink to={_.target}>{_.text}</NavLink>
              </li>
            ))}
          </ol>
        )}
      </div>
      {title && <h4 className="page-title">{title}</h4>}
    </div>
  );
}

PageTitle.propTypes = {};

export default PageTitle;
