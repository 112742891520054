import React, { useEffect, useState } from 'react';
import { Form, FormGroup, InputGroup } from 'react-bootstrap';
import { useCombobox } from 'downshift';
import { useTranslation } from 'react-i18next';

const CustomAutocomplete = ({
  name,
  labelField,
  valueField = 'id',
  valueFieldName = 'id',
  controlProps = {},
  register,
  error,
  selected,
  onSelect,
  onChange,
  setValue,
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selected);

  
  useEffect(() => {
    onSelect(selectedItem);
    setValue(valueFieldName, selectedItem ? selectedItem[valueField] : null);
  }, [selectedItem]);

  const stateReducer = (state, actionAndChanges) => {
    const { type, changes } = actionAndChanges;
    switch (type) {
      case useCombobox.stateChangeTypes.InputChange:
        return {
          ...changes,
          inputValue: changes.inputValue,
        };
      // also on selection.
      case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.InputBlur: {
        setSelectedItem(changes.selectedItem);
        return {
          ...changes,
          ...(state.highlightedIndex > -1 && {
            inputValue: changes.inputValue,
          }),
        };
      }
      default:
        return changes; // otherwise business as usual.
    }
  };
  const { isOpen, getMenuProps, getInputProps, getComboboxProps, getItemProps } = useCombobox({
    async onInputValueChange({ inputValue }) {
      try {
        setLoading(true);
        setItems(await onChange(inputValue));
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    selectedItem: selected,
    items,
    itemToString(item) {
      return item ? item[labelField] : '';
    },
    stateReducer,
  });

  return (
    <FormGroup className="col-md-6 mb-3">
      <Form.Label>{t('examinations:screenings.schoolName')}</Form.Label>
      <div {...getComboboxProps()} className={'form-group form-group-custom-autocomplete position-relative z-index-1'}>
        <InputGroup>
          <Form.Control
            className={`${error ? 'is-invalid' : ''}  ${isOpen && !!items.length ? 'expanded' : ''}`}
            {...getInputProps({ name, ...register(name, controlProps.rules) })}
          />
          {!isOpen && error && (
            <Form.Control.Feedback type="invalid">
              {(error.type === 'required' || error.type === 'validate' && error.message === 'required') && <span id={'error-date'}>{t('errors:validationMessages.required')}</span>}
            </Form.Control.Feedback>
          )}
        </InputGroup>

        <div {...getMenuProps()}>
          {isOpen && !!items.length && (
            <ul className={'custom-autocomplete list-unstyled position-absolute w-100'}>
              {items.map((item, index) => (
                <li
                  className="pe-3 ps-3"
                  key={item.id}
                  {...getItemProps({
                    key: item.id,
                    index,
                    item,
                    style: { cursor: 'pointer' },
                  })}
                >
                  {item[labelField]}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </FormGroup>
  );
};
export default CustomAutocomplete;
