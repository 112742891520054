import React from 'react';
import PropTypes from 'prop-types';
import icon from '../../assets/images/icon.png';
import { Toast as BSToast, Button } from 'react-bootstrap';

function Toast({ toast, type = 'message', onClose }) {
  if (!toast) return null;

  const { id, title, message, info } = toast;

  return (
    <BSToast
      className={type !== 'message' && 'd-flex align-items-center text-white border-0'}
      onClose={() => onClose(toast)}
      bg={type === 'error' ? 'danger' : type}
    >
      {title ? (
        <BSToast.Header>
          <img src={icon} height="20" className="me-1" alt="" />
          <strong className="me-auto">
            {id} {title}
          </strong>
          <small className="text-muted">{info}</small>
        </BSToast.Header>
      ) : null}
      <BSToast.Body>{message}</BSToast.Body>
      {!title ? <button className="btn-close btn-close-white ms-auto me-2" onClick={() => onClose(toast)} /> : null}
    </BSToast>
  );
}

Toast.propTypes = {
  type: PropTypes.oneOf(['message', 'success', 'error']),
  toast: PropTypes.object.isRequired,
};

export default Toast;
