import React, { useContext, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../context/index.ts';
import { NavLink, withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import ParentEvaluation from './PatientForm/ParentEvaluation';
import PersonalInformation from './PatientForm/PersonalInformation';
import LivingAddress from './PatientForm/LivingAddress';
import MedicalRegistration from './PatientForm/MedicalRegistration';
import AdultContacts from './PatientForm/AdultContacts';
import axios from '../../axios';
import Attachments from './PatientForm/Attachments/Attachments';
import moment from 'moment';

const AddPatient = ({ history }) => {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const { showSuccess, showError } = useContext(ToasterContext);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    getValues,
    setError,
    clearErrors,
    unregister,
    setValue,
  } = useForm();

  const addNewPatient = async values => {

    try {
      setLoading(true);
      values.dob = moment(values.dob).format('DD/MM/yyyy');

      await axios.post('patient/create', values);

      showSuccess(t('messages:patientCreated'));
      setTimeout(() => {
        history.push(`/${locale.value}/patients/all`);
      }, 5000);
    } catch (err) {
      const error = err.response.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:patientCreateFailed'));
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addPatient') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={t('pageTitles:addPatient')} />
      <Col className=" mx-auto new-patient">
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit(addNewPatient)}>
              <PersonalInformation
                register={register}
                control={control}
                getValues={getValues}
                errors={errors}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />
              <LivingAddress
                register={register}
                control={control}
                unregister={unregister}
                setValue={setValue}
                errors={errors}
              />

              <MedicalRegistration register={register} />
              <AdultContacts register={register} control={control} errors={errors} />
              <ParentEvaluation register={register} control={control} setValue={setValue} />
              <Controller
                control={control}
                name="parentConcent"
                rules={{required: true}}
                render={({ field: { onChange, value }, fieldState: {error} }) => (
                  <Attachments setAttachFile={onChange} error={error} data={value} />
                )}
              />

              <Row>
                <Col>
                  <FormGroup className="form-group mt-4">
                    <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="spinner"
                        />
                      )}
                      {t('buttons.create')}
                    </Button>

                    <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/patients/all`}>
                      {t('buttons.cancel')}
                    </NavLink>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
export default withRouter(AddPatient);
