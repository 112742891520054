import React from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../../../components/Separator/Separator';
import SymptomsFormFields from '../../../../../components/SymptomsForm/SymptomsForm';

const SubjectiveSymptoms = ({ control, setValue, data, type }) => {
  const { t } = useTranslation();
  return (
    <>
      <Separator text={`${t('examinations:regdExam.complaints')}`} />
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.complaintsInfo')}</h5>
      <SymptomsFormFields control={control} setValue={setValue} data={data} type={type}/>
    </>
  );
};
export default SubjectiveSymptoms;
