import React, { useEffect } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomDateTimePicker from '../../../../../components/CustomDateTimePicker/CustomDateTimePicker';
import { RegDExamSubProps } from '../AddREGDExam';

const ExaminationDetailsDetails = ({ control, setValue, errors, data }: RegDExamSubProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const dateParts = data?.date.split('/');
      setValue('date', dateParts && new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]));
    }
  }, [data]);

  const dateParts = data?.date.split('/');

  return (
    <>
      <Separator text={`${t('examinations:regdExam.title')}`} />
      <Row>
        <Col md={6}>
          <Form.Group className="mb-2">
            <Form.Label>{t('examinations:fmdExam.general.date')}</Form.Label>
            <CustomDateTimePicker
              controlProps={{
                control,
                name: 'date',
                rules: { validate: (v : string | undefined) => v !== undefined || 'required' },
                defaultValue: dateParts && new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]),
              }}
              error={errors?.date}
              maxDate={new Date()}
              placeholderText='dd/MM/yyyy'
              format="dd/MM/yyyy"
              onChange={v => {
                setValue('date', v);
              }}
            />
            {errors?.date && (
              <Form.Control.Feedback type="invalid">
                {(errors?.date.message && t(`errors:validationMessages.${errors?.date.message}`)) ||
                  (errors?.date.type === 'required' && (
                    <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                  ))}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
export default ExaminationDetailsDetails;
