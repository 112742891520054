import React from 'react';
import { REGDExaminationDataType } from './REGDExamValues';
import Separator from '../../../../../components/Separator/Separator';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import Autorefractometry from './StatusOculorum/Autorefractometry';

type Props = {
  data: REGDExaminationDataType;
};

const fields = [
  'distanceVisionWithoutCorrection',
  'distanceVisionCorrection',
  'closeUpVisionWithoutCorrection',
  'nearVisionCorrection',
];

function StatusOculorum({ data }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Separator text={`${t('examinations:regdExam.statusOculorum')}`} variant="light" />
      <h5>{t('examinations:regdExam.visualAcuityAndRefraction.title')}</h5>
      <Row>
        <Col>
          <Form.Group>
            <h6>{t('examinations:rightEye')}</h6>
            {fields.map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.visualAcuityAndRefraction.${field}`)}
                  {t('formLabelSeparator')}{' '}
                </FormLabel>
                <span className="text-dark text-capitalize">
                  {data?.visualAcuityAndRefraction?.rightEyeOD?.[field]}
                </span>
              </div>
            ))}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <h6>{t('examinations:leftEye')}</h6>
            {fields.map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.visualAcuityAndRefraction.${field}`)}
                  {t('formLabelSeparator')}{' '}
                </FormLabel>
                <span className="text-dark text-capitalize">{data?.visualAcuityAndRefraction?.leftEyeOS?.[field]}</span>
              </div>
            ))}
          </Form.Group>
        </Col>
      </Row>
      <br />
      <h5 className="mb-3 m-0">{t('examinations:regdExam.movementsOfTheEyeballs.title')}</h5>
      {/* {t('examinations:regdExam.status')} */}
      <Row>
        <Col>
          <Form.Group>
            <h6>{t('examinations:rightEye')}</h6>
            {['v', 'note'].map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.${field === 'v' ? 'status' : 'movementsOfTheEyeballs.details'}`)}
                  {t('formLabelSeparator')}
                </FormLabel>
                <span className="text-dark text-capitalize">
                  {field === 'v'
                    ? t(`examinations:regdExam.${data?.movementsOfTheEyeballs?.r?.[field] ? 'hasIssue' : 'norm'}`)
                    : data?.movementsOfTheEyeballs?.r?.[field]}
                </span>
              </div>
            ))}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <h6>{t('examinations:leftEye')}</h6>
            {['v', 'note'].map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.${field === 'v' ? 'status' : 'movementsOfTheEyeballs.details'}`)}
                  {t('formLabelSeparator')}
                </FormLabel>
                <span className="text-dark text-capitalize">
                  {field === 'v'
                    ? t(`examinations:regdExam.${data?.movementsOfTheEyeballs?.l?.[field] ? 'hasIssue' : 'norm'}`)
                    : data?.movementsOfTheEyeballs?.l?.[field]}
                </span>
              </div>
            ))}
          </Form.Group>
        </Col>
      </Row>
      <br />
      <h5 className="mb-3 m-0">{t('examinations:regdExam.delusionTest.title')}</h5>
      <Row>
        <Col>
          <Form.Group>
            <h6>{t('examinations:rightEye')}</h6>
            {['v', 'deviations'].map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.${field === 'v' ? 'status' : 'delusionTest.specificDeviation'}`)}
                  {t('formLabelSeparator')}
                </FormLabel>
                <span className="text-dark text-capitalize">
                  {field === 'v'
                    ? t(`examinations:regdExam.${data?.delusionTest?.r?.[field] ? 'hasIssue' : 'norm'}`)
                    :  Object.entries(data?.delusionTest?.r.deviations)
                      .filter(_ => (_[1] === true || typeof _[1] === 'string') && _[0] !== 'notIdentifield')
                      .map(_ => (_[0] === 'other' ? _[1] : t(`examinations:regdExam.delusionTest.${_}`)))
                      .join(', ')}
                </span>
              </div>
            ))}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <h6>{t('examinations:leftEye')}</h6>
            {['v', 'deviations'].map(field => (
              <div key={field}>
                <FormLabel className="me-1">
                  {t(`examinations:regdExam.${field === 'v' ? 'status' : 'delusionTest.specificDeviation'}`)}
                  {t('formLabelSeparator')}
                </FormLabel>
                <span className="text-dark text-capitalize">
                  {field === 'v'
                    ? t(`examinations:regdExam.${data?.delusionTest?.l?.[field] ? 'hasIssue' : 'norm'}`)
                    :  Object.entries(data?.delusionTest?.l.deviations)
                      .filter(_ => (_[1] === true || typeof _[1] === 'string') && _[0] !== 'notIdentifield' || _[0] === 'other')
                      .map(_ => (_[0] === 'other' ? _[1].details : t(`examinations:regdExam.delusionTest.${_[0]}`)))
                      .join(', ')}
                </span>
              </div>
            ))}
          </Form.Group>
        </Col>
      </Row>
      <Autorefractometry data={data}/>
    </>
  );
}

export default StatusOculorum;
