import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalsContext } from '../../../context/index.ts';

function ConfirmationModal() {
  const { t } = useTranslation();

  const { confirmationModal } = useContext(ModalsContext);

  const handleClose = () => confirmationModal.hide();
  const handleAction = () => {
    confirmationModal.data.action && confirmationModal.data.action();
    confirmationModal.hide();
  };

  return (
    <Modal show={confirmationModal.open} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton closeVariant="white" className={`bg-${confirmationModal.data.variant || 'danger'}`}>
        <Modal.Title as="h6" className="m-0 text-white">
          <i className="fas fa-exclamation-circle me-2" />
          {confirmationModal.data.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmationModal.data.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="de-dark" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button variant={`de-${confirmationModal.data.variant || 'danger'}`} onClick={handleAction}>
          {confirmationModal.data.btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {};

export default ConfirmationModal;
