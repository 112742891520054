import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const DiagnosisDescription = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-3">
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.diagnosisDescription.title')}</h5>
      <Row>{[
        'od',
        'os',
        'ou'
      ].map(eye => 
        <Col md={4} key={eye}>
          <CustomFormGroup
            label={t(`examinations:regdExam.${eye}`)}
            register={{ ...register(`diagnosisDescription.${eye.charAt(0)}`)}}
            error={ errors.diagnosisDescription?.[eye] }
            groupProps={{ className: 'col-md-12 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: `diagnosisDescription.${eye}`,
              name: `diagnosisDescription.${eye}`,
              defaultValue: data?.diagnosisDescription?.[eye]|| '',
              as: 'textarea'
            }}
          />
        </Col>
      )}
      </Row>
    </Row>
  );
};
export default DiagnosisDescription;
