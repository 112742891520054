import React, { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const CloseUpRefraction = ({ register, errors, data, clearErrors, setValue, control }: RegDExamSubProps) => {
  const { t } = useTranslation();
  const [sValue, setSValue] = useState<boolean>();

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  return (
    <>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.closeUpRefraction.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'closeUpRefractionState',
            control,
            defaultValue: data?.closeUpRefractionState,
          }}
          onChange={v => {
            clearErrors('closeUpRefractionState');
            setSValue(v as boolean);
            setValue('closeUpRefractionState', v);
          }}
        />
      </Row>
      <Row className="mb-3">
        <h5>{t('examinations:regdExam.bothEyesOU')}</h5>
        {['closeUpADD', 'closeUpView'].map(_ => (
          <CustomFormGroup
            appendContent={null}
            prependContent={null}
            key={_}
            register={{ ...register(`closeUpRefraction.b.${_}`) }}
            label={t(`examinations:regdExam.closeUpRefraction.${_}`)}
            error={errors.closeUpRefraction?.b?.[_]}
            groupProps={{ className: 'mb-3 col' }}
            fixedLabel={true}
            controlProps={{
              id: `closeUpRefraction.b.${_}`,
              name: `closeUpRefraction.b.${_}`,
              defaultValue: data?.closeUpRefraction?.b?.[_] || '',
            }}
          />
        ))}
      </Row>
    </>
  );
};
export default CloseUpRefraction;
