import React, { useCallback, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import SchoolList from '../../components/SchoolList/SchoolList';
import AddSchool from './AddSchool';

const School = ({ appLoading }) => {
  let { page } = useParams();
  const { t } = useTranslation();
  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
    case 'create': {
      tab = <AddSchool />;
      break;
    }
    case 'all': {
      tab = <SchoolList title={t('pageTitles:allSchools')} />;
      break;
    }
    default: {
      tab = <SchoolList />;
    }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:school') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default School;
