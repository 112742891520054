import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Alert, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import AddExaminationMenu from '../../../components/AddExaminationMenu/AddExaminationMenu';
import LoadingIndicator from '../../../components/Loading/LoadingIndicator';
import Examination from './Examination/Examination.tsx';
import './Examinations.scss';
import NoDataMessage from '../../../components/NoDataMessage/NoDataMessage';

const Examinations = ({ patientId, appointmentId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const controller = abortControllerRef.current;

    async function fetchData() {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`/patient/${patientId}/examinations`);
        if (response.status === 200) {
          setData(response.data.data.examinations.reverse());
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  const ddText = (
    <>
      + {t('examinations:addNew')}
      <i className="mdi mdi-chevron-down ms-2"></i>
    </>
  );

  return (
    <Card className="patient-examinations">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <Card.Title>{t('examinations:title')}</Card.Title>
          </Col>
          <Col md="auto">
            <AddExaminationMenu patientId={patientId} title={ddText} variant="success" appointmentId={appointmentId}/>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {error ? (
          <Alert variant="danger" className="mt-3">
            <p className="text-danger text-center m-2">{error.message}</p>
          </Alert>
        ) : null}

        {loading || (!data && !error) ? (
          <LoadingIndicator />
        ) : Array.isArray(data) && data.length < 1 ? (
          <NoDataMessage />
        ) : (
          <Accordion flush>
            <div className="slimscroll activity-scroll">
              <div className="activity">{data ? data.map(_ => <Examination item={_} key={_.id} />) : null}</div>
            </div>
          </Accordion>
        )}
      </Card.Body>
    </Card>
  );
};
export default Examinations;
