import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Separator.scss';

const Separator = ({ text, variant = '', hint }) => {
  return (
    <>
      <Row>
        <Col lg={12} className={'mx-auto ' + (!hint ? 'my-3' : 'mt-3 mb-2')}>
          <div className={!hint ? 'mb-4' : ''}>
            <hr data-content={`${text}`} className={['hr-text', variant].join(' ')} />
          </div>
        </Col>
      </Row>
      {hint && (
        <p className="text-center text-muted mb-4">
          <em>{hint}</em>
        </p>
      )}
    </>
  );
};
export default Separator;
