import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import axios from '../../../../axios';
import LoadingIndicator from '../../../../components/Loading/LoadingIndicator';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../../../context';
import usePatient from '../../../../hooks/usePatient';
import Result from './Result';
import ColorVisionCheck from './ScreeningForm/ColorVisionCheck';
import EyeCooperativeWorkCheck from './ScreeningForm/EyeCooperativeWorkCheck';
import EyeFrontalZoneEvaluation from './ScreeningForm/EyeFrontalZoneEvaluation';
import EyeSynchronousMovementCheck from './ScreeningForm/EyeSynchronousMovementCheck';
import School from './ScreeningForm/School';
import ScreeningDetails from './ScreeningForm/ScreeningDetails';
import SubjectiveSymptoms from './ScreeningForm/SubjectiveSymptoms';
import VisionAcuityInCloseDistance from './ScreeningForm/VisionAcuityInCloseDistance';
import VisionAcuityInFarDistance from './ScreeningForm/VisionAcuityInFarDistance';
import WelchAllynDeviceDiagnostics from './ScreeningForm/WelchAllynDeviceDiagnostics';
import _3DVisionCheck from './ScreeningForm/_3DVisionCheck';
import { ScreeningExaminationDataType } from './ScreeningValues/ScreeningValues';

const EditScreening = () => {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { patient, loading: patientLoading, error: patientError } = usePatient();
  const [data, setData] = useState<ScreeningExaminationDataType | undefined>();
  const [mode, setMode] = useState('form');
  const [hasIssue, setHasIssue] = useState(false);
  const match = useRouteMatch();
  const { id } = match.params;

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    clearErrors,
  } = useForm();

  const { showSuccess, showError } = useContext(ToasterContext);

  const getScreening = async () => {
    try {
      setDataLoading(true);
      const response = await axios.get(`/screening/${id}`);
      setData(response.data.data.screening);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:screeningCreateFailed'));
    }
    setDataLoading(false);
  };

  const editScreening = async values => {
    try {
      setLoading(true);
      values.full = true;
      values.patient = patient.id;
      values.date = moment(values.date).format('DD/MM/yyyy');

      const response = await axios.post(`screening/${id}/update`, values);
      showSuccess(t('messages:screeningUpdated'));
      setTimeout(() => {
        setHasIssue(response.data.data.screening.hasIssue);
        setData(response.data.data.screening);
        setMode('result');
      }, 2000);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:screeningUpdateFailed'));
    }
    setLoading(false);
  };

  useEffect(() => {
    getScreening();
  }, []);

  const checkKeyDown = e => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const pageTitle = (
    <>
      {t('pageTitles:editScreening')}
      {t('formLabelSeparator')}
      <NavLink className="ms-1 text-primary" to={`/${locale.value}/patient/${patient?.id}/view`}>
        {patient?.fullName} (#{patient?.uid})
      </NavLink>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:editScreening') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={pageTitle} />
      <Row>
        <Col className=" mx-auto new-examination">
          <Card>
            <Card.Body>
              {patientLoading || dataLoading ? (
                <LoadingIndicator />
              ) : mode === 'form' ? (
                <Form onSubmit={handleSubmit(editScreening)} onKeyDown={e => checkKeyDown(e)}>
                  <School
                    register={register}
                    control={control}
                    setValue={(...args) => {
                      setValue(...args);
                    }}
                    errors={errors}
                    data={data?.school}
                  />
                  <ScreeningDetails
                    register={register}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    data={data}
                  />
                  <SubjectiveSymptoms control={control} setValue={setValue} data={data?.symptoms} />
                  <WelchAllynDeviceDiagnostics
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    data={data}
                  />
                  <VisionAcuityInFarDistance
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    data={data}
                  />
                  <VisionAcuityInCloseDistance
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    data={data}
                  />
                  <EyeFrontalZoneEvaluation
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    data={data}
                  />
                  <_3DVisionCheck
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    data={data}
                  />
                  <EyeSynchronousMovementCheck register={register} control={control} setValue={setValue} data={data} />
                  <EyeCooperativeWorkCheck register={register} control={control} setValue={setValue} data={data} />
                  <ColorVisionCheck register={register} control={control} setValue={setValue} data={data} />
                  <Row className="mt-3">
                    <Col>
                      <FormGroup className="form-group">
                        <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="spinner"
                            />
                          )}
                          {t('buttons.edit')}
                        </Button>

                        <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/patient/${patient?.id}/view`}>
                          {t('buttons.cancel')}
                        </NavLink>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <Result hasIssue={hasIssue} patientId={patient.id} screeningId={id} onEdit={() => setMode('form')} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditScreening;
