import * as actionTypes from '../actionTypes';

export const signIn = payload => {
  return {
    type: actionTypes.SIGN_IN_START,
    payload
  };
};

export const signInDone = payload => {
  return {
    type: actionTypes.SIGN_IN_DONE,
    payload
  };
};

export const signInFail = payload => {
  return {
    type: actionTypes.SIGN_IN_FAIL,
    payload
  };
};

export const resetPassword = payload => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
    payload
  };
};

export const resetPasswordDone = payload => {
  return {
    type: actionTypes.RESET_PASSWORD_DONE,
    payload
  };
};

export const resetPasswordFail = payload => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    payload
  };
};


export const getAuthUser = payload => {
  return {
    type: actionTypes.GET_ME_START,
    payload
  };
};

export const getAuthUserDone = payload => {
  return {
    type: actionTypes.GET_ME_DONE,
    payload
  };
};

export const getAuthUserFail = payload => {
  return {
    type: actionTypes.GET_ME_FAIL,
    payload
  };
};


export const updateUserInfo = payload => {
  return {
    type: actionTypes.UPDATE_USER_INFO_START,
    payload
  };
};

export const updateUserInfoDone = payload => {
  return {
    type: actionTypes.UPDATE_USER_INFO_DONE,
    payload
  };
};

export const updateUserInfoFail = payload => {
  return {
    type: actionTypes.UPDATE_USER_INFO_FAIL,
    payload
  };
};


export const setLanguage = payload => {
  return {
    type: actionTypes.CHANGE_LANGUAGE_START,
    payload
  };
};

export const setLanguageDone = payload => {
  return {
    type: actionTypes.CHANGE_LANGUAGE_DONE,
    payload
  };
};

export const setLanguageFail = payload => {
  return {
    type: actionTypes.CHANGE_LANGUAGE_FAIL,
    payload
  };
};
