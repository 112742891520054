import {
  SIGN_IN_START,
  SIGN_IN_DONE,
  SIGN_IN_FAIL,
  GET_ME_START,
  GET_ME_DONE,
  GET_ME_FAIL,
  RESET_PASSWORD_START,
  RESET_PASSWORD_DONE,
  RESET_PASSWORD_FAIL,
  CHANGE_LANGUAGE_START,
  CHANGE_LANGUAGE_DONE,
  CHANGE_LANGUAGE_FAIL,
  UPDATE_USER_INFO_START,
  UPDATE_USER_INFO_DONE,
  UPDATE_USER_INFO_FAIL
} from '../actionTypes';

const INITIAL_STATE = {
  loading: false,
  error: undefined,
  data: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
  case SIGN_IN_START:
    return { ...state, loading: true, error: undefined };
  case SIGN_IN_DONE: {
    return {
      ...state,
      loading: false,
      error: undefined,
      data: { ...state.data, ...action.payload.data }
    };
  }
  case SIGN_IN_FAIL: {
    return { ...state, loading: false, error: action.payload.error, data: { ...INITIAL_STATE.data } };
  }
  case RESET_PASSWORD_START:
    return { ...state, loading: true, error: undefined };
  case RESET_PASSWORD_DONE: {
    return {
      ...state,
      loading: false,
      error: undefined,
      resetSuccess: true
    };
  }
  case RESET_PASSWORD_FAIL: {
    return { ...state, loading: false, error: action.payload.error, data: {} };
  }

  case UPDATE_USER_INFO_START:
    return { ...state, loading: true, error: undefined };
  case UPDATE_USER_INFO_DONE: {
    return {
      ...state,
      loading: false,
      error: undefined,
      data: { ...state.data, ...action.payload.data }
    };
  }
  case UPDATE_USER_INFO_FAIL: {
    return { ...state, loading: false, error: action.payload.error };
  }

  case GET_ME_DONE: {
    return {
      ...state,
      loading: false,
      error: undefined,
      data: { ...state.data, ...action.payload.data }
    };
  }
  case CHANGE_LANGUAGE_START:
    return { ...state, loading: true, error: undefined };
  case CHANGE_LANGUAGE_DONE: {
    return {
      ...state,
      loading: false,
      error: undefined,
      data: { ...state.data, user: { ...state.data.user, lang: action.payload } }
    };
  }
  case CHANGE_LANGUAGE_FAIL: {
    return { ...state, loading: false, error: action.payload.error, data: { ...INITIAL_STATE.data } };
  }
  default:
    return state;
  }
}
