import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Image, Nav, NavItem, NavLink, Row, Spinner } from 'react-bootstrap';
import i18n from '../../i18n';
import CustomFormGroup from '../../components/CustomFormGroup/CustomFormGroup';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/index.ts';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import axios from '../../axios';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../store/actions';
import { PasswordRegex } from '../../constants';
import logo from '../../assets/images/logo.svg';

const year = new Date().getFullYear();

const ResetPassword = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useContext(AppContext);
  const { locale } = state;
  const [passwordResetError, setPasswordResetError] = useState();
  const [passwordResetLoading, setPasswordResetLoading] = useState(true);
  const [token, setToken] = useState('');
  const { handleSubmit, formState: { errors }, register, watch } = useForm();
  const { resetSuccess, data: authData, loading } = useSelector(state => state.auth);

  useEffect(() => {
    async function fetchData() {
      const authToken = localStorage.getItem('aecp-ss-auth-token');
      if (!authToken) {
        try {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          setPasswordResetLoading(true);
          const response = await axios.post('/auth/password-reset', {
            key: urlParams.get('key')
          });
          if (response.status === 200) {
            setToken(response.data.data.token);
          } else {
            setPasswordResetError(response.error);
          }
        } catch (err) {
          console.log(err);
          setPasswordResetError(err);
        }
      } else {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        setToken(authToken);
      }
      setPasswordResetLoading(false);
    } 
  fetchData();
  }, []);

  if (resetSuccess) {
    history.replace(`/${locale.value}/sign-in`);
  }

  const handleReset = async values => {
    dispatch(resetPassword({ token, values }));
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>{t('pageTitles:resetPassword') + t('pageTitles:tail')}</title>
      </Helmet>
      <Container>
        <Row className="vh-100 justify-content-center">
          <Col md={12} className="align-self-center">
          <Row>
                <Col lg={6} className="mx-auto text-center">
                      <NavLink to="/" className="logo logo-admin">
                          <Image src={logo} height="70" alt="logo"
                                 className="auth-logo mb-4" />
                        </NavLink>
                </Col>
              </Row>
            <Row>
              <Col lg={6} className="mx-auto">
                <Nav className="justify-content-end lang">
                  <NavItem>
                    <NavLink className={`p-2 ${locale.value === 'en' ? 'active' : ''}`} onClick={() => {
                      locale.setValue('en');
                      i18n.changeLanguage('en');
                    }}><Image src="/images/flags/en_flag.jpg"
                              alt=""
                              height="15" className="me-2" />English</NavLink>
                  </NavItem>
                  <NavItem className="nav-link p-2">|</NavItem>
                  <NavItem>
                    <NavLink className={`p-2 ${locale.value === 'am' ? 'active' : ''}`} onClick={() => {
                      locale.setValue('am');
                      i18n.changeLanguage('am');
                    }}><Image src="/images/flags/am_flag.jpg"
                              alt=""
                              height="15" className="me-2" />Armenian</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mx-auto">
                <Card>
                  <Card.Body className={'p-0 auth-header-box'}>
                    <div className="text-center p-3">
                      <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{t('auth:changePassword.title')}</h4>
                      <p className="text-muted  mb-0">{t('auth:changePassword.subtitle')}</p>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    {(passwordResetLoading) ?
                      <LoadingIndicator /> :
                      passwordResetError ? <Alert variant="danger">{t('auth:changePassword.wrongUrl')}</Alert>
                        : <Form className="my-4" onSubmit={handleSubmit(handleReset)}>
                          <CustomFormGroup
                            disabled={loading}
                            fixedLabel={true}
                            label={t('auth:changePassword.newPassword')}
                            error={errors.newPassword}
                            groupProps={{ as: Col, className: 'col-sm-12 mb-2' }}
                            controlProps={{
                              name: 'password',
                              type: 'password',
                              disabled: loading,
                              maxLength: 50,
                              minLength: 6
                            }}
                            register={register('password', {
                              required: true,
                              pattern: {
                                value: PasswordRegex,
                                message: 'invalidPassword_2'
                              },
                              validate: value => value === watch('confirmNewPassword') || 'passwordsNotMatch'
                            })}
                          />

                          <CustomFormGroup
                            disabled={loading}
                            fixedLabel={true}
                            label={t('auth:changePassword.confirmPassword')}
                            error={errors.confirmNewPassword}
                            groupProps={{ as: Col, className: 'col-sm-12 mb-2' }}
                            controlProps={{
                              name: 'confirmNewPassword',
                              type: 'password',
                              disabled: loading,
                              maxLength: 50,
                              minLength: 6
                            }}
                            register={register('confirmNewPassword', {
                              required: true,
                              pattern: {
                                value: PasswordRegex,
                                message: 'invalidPassword_2'
                              },
                              validate: value => value === watch('password') || 'passwordsNotMatch'
                            })}
                          />

                          <Form.Group className="mt-3">
                            <Row>
                              <Col>
                                <Button className="btn btn-primary w-100"
                                        type="submit" disabled={loading}>
                                  {loading && (
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"
                                             className="spinner" />
                                  )}
                                  {t('auth:changePassword.save')}
                                  <i
                                    className="fas fa-sign-in-alt ms-1" />
                                </Button>
                              </Col>
                            </Row>
                          </Form.Group>
                        </Form>
                    }
                  </Card.Body>
                  <div className="card-body bg-light-alt text-center">
                    &copy;
                    {year}
                    <span className="m-2">{t('aecp')}</span>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>

    </div>
  );
};
export default ResetPassword;
