import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { ModalsContext, ToasterContext } from '../../../context/index';
import useUserList from '../../../hooks/useFMDList';
import CustomSelect from '../../CustomSelect/CustomSelect';
import axios from '../../../axios';
import DatePicker from 'react-datepicker';
import CustomDateTimePicker from '../../CustomDateTimePicker/CustomDateTimePicker';
import moment from 'moment';

type FormValues =
  | {
      date: string;
      fmd: string;
      patient: string;
    }
  | Record<string, any>;

type Props = {
  actionCallback?: () => void | undefined
}

function ReferFMDModal({actionCallback}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { referFMDModal } = useContext(ModalsContext);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('patient', referFMDModal.data.patientId);
  }, [referFMDModal.data.patientId]);

  const handleClose = () => referFMDModal.hide();

  const handleAction = () => {
    setLoading(false);
    referFMDModal.data.action && referFMDModal.data.action();
    referFMDModal.hide();
    actionCallback && actionCallback();
  };

  const { showSuccess, showError } = useContext(ToasterContext);

  const referFMD = async (values: FormValues) => {
    try {
      setLoading(true);

      const data = {
        ...values,
        date: [moment(values.date[0]).format('DD/MM/yyyy'), moment(values.date[1]).format('DD/MM/yyyy')].join(' - '),
      };

      console.log(values);

      const response = await axios.post('/patient/refer-fmd', data);

      if (response.status === 200) {
        const appointment = response.data.data.appointment;
        console.log(appointment);
        showSuccess(t('messages:referenceCreated'));
      }

      handleAction();
    } catch (err: any) {
      const error = err.response?.data;
      console.log(error);
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:referFMDFailed'));
    }
    setLoading(false);
  };

  const { list: fmdList, loading: fmdListLoading } = useUserList('fmd');

  return (
    <Modal show={referFMDModal.open} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton closeVariant="white" className="bg-primary">
        <Modal.Title as="h6" className="m-0 text-white">
          <>
            <i className="fas fa-exclamation-circle me-2" />
            {t('examinations:referFMD')}
          </>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(referFMD)}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>{t('userRoles.fmd')}</Form.Label>
            {fmdList.length > 0 && (
              <Controller
                name="fmd"
                control={control}
                defaultValue={fmdList[0].value}
                render={({ field: { onChange } }) => {
                  return (
                    <CustomSelect
                      register={{ ...register('fmd') }}
                      name="fmd"
                      style={{ textAlign: 'left' }}
                      options={fmdList}
                      value={fmdList[0].value ? fmdList[0].value : ''}
                      keepSelectedInList={true}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{fmdList[0].value && fmdList[0].label}</span>
                      )}
                      onChange={(val: Array<any>) => {
                        onChange(val[0].value);
                      }}
                    />
                  );
                }}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('examinations:screenings.date')}</Form.Label>
            <CustomDateTimePicker
              controlProps={{
                control,
                name: 'date',
                rules: { validate: v => v !== undefined || 'required' },
                // defaultValue: new Date(),
              }}
              error={errors?.date}
              minDate={new Date()}
              format="dd/MM/yyyy"
              range={true}
              onChange={v => {
                setValue('date', v);
              }}
              placeholderText="dd/mm/yyyy - dd/mm/yyyy"
            />
            {errors?.date && (
              <Form.Control.Feedback type="invalid">
                {(errors?.date.message && t(`errors:validationMessages.${errors?.date.message}`)) ||
                  (errors?.date.type === 'required' && (
                    <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                  ))}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="de-dark" onClick={handleClose}>
            {t('cancel') as string}
          </Button>
          <Button variant={`de-${referFMDModal.data.variant || 'success'}`} type="submit">
            <>
              {loading && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="spinner" />
              )}
              {t('buttons.refer') as string}
            </>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ReferFMDModal.propTypes = {};

export default ReferFMDModal;
