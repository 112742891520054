import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, Button, Row } from 'react-bootstrap';
import axios from '../../axios';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { AppContext, ModalsContext, ToasterContext } from '../../context/index.ts';
import PageTitle from '../PageTitle/PageTitle';
import { Trans, useTranslation } from 'react-i18next';
import { AdvancedImage, placeholder } from '@cloudinary/react';
import cloudinary from '../../cloudinary';
import Pagination from '../Pagination/Pagination';
import { fill } from '@cloudinary/url-gen/actions/resize';
import LoadingIndicator from '../Loading/LoadingIndicator';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import { useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../accessControl';
import Filter from '../../pages/Staff/Filter/Filter';

const UsersList = ({ role, title }) => {
  const { locale } = useContext(AppContext);
  const { confirmationModal } = useContext(ModalsContext);
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation();
  const location = useLocation();

  const initailPage = query.get('page') ? parseInt(query.get('page')) : 1;

  const [data, setData] = useState({ users: [], pagingCount: 0 });
  const [pageNumber, setPage] = useState(initailPage);
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { showSuccess, showError } = useContext(ToasterContext);

  const { data: authData } = useSelector(state => state.auth);
  const theUser = authData.user;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      query.set('page', pageNumber);
      Object.keys(params).map(_ => {
        query.set(_, params[_]);
      });
      history.push(`${location.pathname}?${query}`);
      const queryParams = { page: pageNumber, ...params };

      if (!queryParams.role) {
        queryParams.role = role;
      }
      try {
        const response = await axios.get('/user/list', { params: queryParams });
        if (response.status === 200) {
          const revertUserList = response.data.data.users.reverse();
          setData({
            users: [...revertUserList],
            pagingCount: Math.ceil(response.data.data.total / response.data.data.limit),
            total: response.data.data.total,
            limit: response.data.data.limit,
            page: response.data.data.page,
          });
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
    fetchData();
  }, [pageNumber, role, params]);

  const updateUserStatus = async (user, status) => {
    try {
      const response = await axios.post(`/user/${user.id}/update`, {
        ...user,
        status: status === -1 ? status : status ? 1 : 2,
      });
      if (response.status === 200) {
        setData(prevData => {
          const users =
            status === -1
              ? prevData.users.filter(_ => _.id !== user.id)
              : prevData.users.map(_ => {
                  if (_.id === user.id) {
                    _.status = status ? 1 : 2;
                  }
                  return _;
                });

          return { ...data, users };
        });

        showSuccess(t(`messages:${status === -1 ? 'userDeleted' : 'userStatusUpdated'}`));
      }
    } catch (err) {
      const error = err.response.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:userStatusUpdateFailed'));
    }
  };
  const handleUserDelete = user => {
    confirmationModal.show({
      title: t('modals:confirmation.deleteUser.title'),
      message: t('modals:confirmation.deleteUser.message'),
      btnText: t('modals:confirmation.deleteUser.btnText'),
      action: () => updateUserStatus(user, -1),
    });
  };

  return (
    <>
      <Helmet>
        <title>{title + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={title} />
      {error ? (
        <div className="alert alert-outline-danger" role="alert">
          <strong>Oh snap!</strong> {error.message}.
        </div>
      ) : (
        <Row>
          <Col lg={9}>
            <Card>
              <Card.Body>
                {loading ? (
                  <LoadingIndicator />
                ) : !data.users.length ? (
                  <NoDataMessage
                    actionButton={{
                      title: `+ ${t('staff:addNewUser')}`,
                      to: `/${locale.value}/staff/create`,
                    }}
                  />
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th colSpan="2">{t('staff:list.memberName')}</th>
                            <th>{t('staff:list.email')}</th>
                            <th>{t('staff:list.phone')}</th>
                            <th>{t('staff:list.job')}</th>
                            <th>{t('staff:list.role')}</th>
                            <th>{t('staff:list.status')}</th>
                            <th className="text-right">{t('staff:list.actions')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.users.map((_, index) => (
                            <tr key={_.id}>
                              <td>
                                {_.avatar ? (
                                  <AdvancedImage
                                    cldImg={cloudinary.image(_.avatar).resize(fill().height(72))}
                                    plugins={[placeholder()]}
                                    alt=""
                                    className="thumb-sm rounded-circle me-2"
                                  />
                                ) : (
                                  <img
                                    src="/images/user-placeholder.png"
                                    alt=""
                                    className="thumb-sm rounded-circle me-2"
                                  />
                                )}
                              </td>
                              <td>
                                <NavLink to={`/${locale.value}/staff/${_.id}/view`} className="me-2">
                                  <span className="ml-2">{_.fullName}</span>
                                </NavLink>
                              </td>
                              <td>{_.email}</td>
                              <td>{_.phone}</td>
                              <td>{[_?.title, _?.company].filter(_ => !!_).join(', ')}</td>
                              <td>{t(`userRoles.${_.role}`)}</td>
                              <td>
                                <Form.Check
                                  type="switch"
                                  className="form-switch-success"
                                  id={_.status}
                                  onChange={() => updateUserStatus(_, _.status === 2)}
                                  checked={_.status === 1}
                                />
                              </td>
                              <td className="text-end">
                                {hasPermission('readAny', 'user') ? (
                                  <NavLink to={`/${locale.value}/staff/${_.id}/view`} className="me-2">
                                    <i className="las la-eye text-primary font-16" />
                                  </NavLink>
                                ) : null}

                                {theUser.id !== _.id ? (
                                  <>
                                    {hasPermission('updateAny', 'user') ? (
                                      <NavLink to={`/${locale.value}/staff/${_.id}/edit`} className="me-1">
                                        <i className="las la-pen text-primary font-16" />
                                      </NavLink>
                                    ) : null}

                                    {hasPermission('deleteAny', 'user') ? (
                                      <Button variant="link" onClick={() => handleUserDelete(_)} className="p-0">
                                        <i className="las la-trash-alt text-danger font-16" />
                                      </Button>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p className="">
                          <Trans
                            i18nKey="showingNItems"
                            components={{
                              start: data.limit * (data.page - 1) + 1,
                              end: data.limit * (data.page - 1) + data.users.length,
                              total: data.total,
                            }}
                          />
                        </p>
                      </div>
                      <div className="col-auto">
                        {data.users.length > 0 && (
                          <Pagination setPage={setPage} pagingCount={data.pagingCount} selectedPage={pageNumber} />
                        )}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-auto">
                        {hasPermission('createAny', 'user') ? (
                          <NavLink to={`/${locale.value}/staff/create`} className="btn btn-de-success btn-sm px-4 ">
                            + {t('staff:addNewUser')}
                          </NavLink>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Filter setParams={setParams} query={query} role={role} />
        </Row>
      )}
    </>
  );
};

export default UsersList;
