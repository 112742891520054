import React from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/Separator/Separator';
import SymptomsFormFields from '../../../components/SymptomsForm/SymptomsForm';

const ParentEvaluation = ({ control, setValue, data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Separator text={`${t('patient:symptoms.title')}`} />
      <SymptomsFormFields control={control} setValue={setValue} data={data?.symptoms} />
    </>
  );
};
export default ParentEvaluation;
