import React, { useCallback, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';

const Dashboard = ({ appLoading }) => {
  const { t } = useTranslation();
  let { page } = useParams();

  const renderActiveTab = useCallback(() => {
    let tab;
    switch (page) {
      default: {
        tab = null;
      }
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Helmet>
          <title>{t('pageTitles:dashboard') + t('pageTitles:tail')}</title>
        </Helmet>
        {tab}
      </Suspense>
    );
  }, [page]);

  if (appLoading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  return renderActiveTab();
};
export default Dashboard;
