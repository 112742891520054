import React, { useEffect, useRef, useState } from 'react';
import './Profile.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Settings from './Settings/Settings';
import { updateUserInfo } from '../../store/actions';
import PageTitle from '../../components/PageTitle/PageTitle';
import Avatar from '../../components/Avatar/Avatar';

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: authData } = useSelector(state => state.auth);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const theUser = authData.user;

  useEffect(() => {
    if (theUser) {
      setAvatarUrl(theUser.avatar);
    }
  }, []);

  useEffect(() => {
    if (avatarUrl) {
      let values = { userId: theUser.id, avatar: avatarUrl };
      // dispatch(updateUserInfo(values));
    }
  }, [avatarUrl]);

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:myProfile') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={t('pageTitles:myProfile')} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <div className="met-profile">
                <Row>
                  <Col md={6} className="align-self-center mb-3 mb-lg-0">
                    <div className="met-profile-main">
                      <Avatar avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} />
                      <div className="met-profile_user-detail">
                        <h5 className="met-user-name">
                          {theUser.fullName}{' '}
                          <small className="text-muted fw-normal fs-6">{t('userRoles.' + theUser.role)}</small>
                        </h5>
                        <p className="mb-0 met-user-name-post">
                          {[theUser.title, theUser.company].filter(_ => !!_).join(', ')}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="ms-auto align-self-center">
                    <ul className="list-unstyled personal-detail mb-0">
                      <li className="">
                        <i className="las la-phone mr-2 text-secondary font-22 align-middle" />
                        <b> {t('profile:phone')} </b> : {theUser.phone}
                      </li>
                      <li className="mt-2">
                        <i className="las la-envelope text-secondary font-22 align-middle mr-2" />
                        <b> {t('profile:email')} </b> : {theUser.email}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Card.Body>
            <Settings />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Profile;
