import axios from 'axios';

let apiUrl;

switch (process.env.REACT_APP_ENV) {
  case 'production':
  case 'demo':
    apiUrl = process.env.REACT_APP_API_URL;
    break;

  default:
    apiUrl = process.env.REACT_APP_API_URL;
    break;
}

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
});
export default instance;
