import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../accessControl';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { AlignType } from 'react-bootstrap/esm/types';
import { AppContext, ModalsContext } from '../../context/index';

type Props = {
  patientId: string;
  drop: DropDirection | undefined;
  align: AlignType | undefined;
  className: string | undefined;
  variant: string | undefined;
  size: 'sm' | 'lg' | undefined;
  title: string;
  appointmentId: string;
};

function PendingPatientActionsMenu({
  patientId,
  drop = 'down',
  align = 'start',
  className,
  variant = 'primary',
  size = 'sm',
  title = '',
  appointmentId,
}: Props) {
  const { referFMDModal } = useContext(ModalsContext);
  const { locale } = useContext(AppContext);

  const { t } = useTranslation();

  const handleReferFMD = () => {
    referFMDModal.show({
      patientId,
    });
  };

  return (
    <Dropdown className={className} drop={drop} align={align}>
      <Dropdown.Toggle variant={variant} size={size}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {hasPermission('updateOwn', 'pendingPatient1') ? (
          <Dropdown.Item onClick={handleReferFMD}>
            <>
              <i className="las  la-user-nurse me-2"></i>
              {t('examinations:referFMD')}
            </>
          </Dropdown.Item>
        ) : null}
        {hasPermission('createAny', 'fmdExamination') ? (
          <Dropdown.Item
            as={NavLink}
            to={`/${locale.value}/patient/${patientId}/fmd-exam/create?appointment=${appointmentId}`}
          >
            <>
              <i className="las la-user-nurse me-2"></i>
              {t('examinations:addFMDExam')}
            </>
          </Dropdown.Item>
        ) : null}
        {hasPermission('createAny', 'regdExamination') ? (
          <Dropdown.Item
            as={NavLink}
            to={`/${locale.value}/patient/${patientId}/regd-exam/create?appointment=${appointmentId}`}
          >
            <>
              <i className="las la-user-nurse me-2"></i>
              {t('examinations:addRegExam')}
            </>
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

PendingPatientActionsMenu.propTypes = {};

export default PendingPatientActionsMenu;
