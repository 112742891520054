import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Header from '../components/Header/Header';
import SidebarMenu from '../components/SidebarMenu/SidebarMenu.tsx';
import Footer from '../components/Footer/Footer';
import Profile from './Profile/Profile';
import Staff from './Staff/Staff';
import EditProfile from './Staff/EditProfile';
import UserProfile from './Staff/Profile';
import Toaster from '../components/Toaster/Toaster';
import ConfirmationModal from '../components/modals/ConfirmationModal/ConfirmationModal';
import Patient from './Patient/Patient';
import Screenings from './Patient/Examinations/Screenings/Screenings';
import Patients from './Patient/Patients';
import School from './School/School';
import FMDExams from './Patient/Examinations/FMDExam/FMDExams';
import REGDExams from './Patient/Examinations/REGDExam/REGDExams';
import EditScreening from './Patient/Examinations/Screenings/EditScreening';


function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div className={sidebarOpen ? '' : 'enlarge-menu'}>
      <SidebarMenu toggleSidebar={(v) => setSidebarOpen(v)} />
      <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <Toaster />
            <Switch>
              <Route exact path="/:lang/dashboard/:page" component={Dashboard} />
              <Route exact path="/:lang/me" component={Profile} />
              <Route exact path="/:lang/staff/:page" component={Staff} />
              <Route exact path="/:lang/staff/:id/view" component={UserProfile} />
              <Route exact path="/:lang/staff/:id/edit" component={EditProfile} />
              <Route exact path="/:lang/patients/:page" component={Patients} />
              <Route exact path="/:lang/patient/:id" component={Patient} />
              <Route exact path="/:lang/patient/:id/:page" component={Patient} />
              <Route exact path="/:lang/patient/:patientId/screening/:id/edit" component={EditScreening} />
              <Route exact path="/:lang/patient/:patientId/screening/:page" component={Screenings} />
              <Route exact path="/:lang/patient/:patientId/fmd-exam/:page" component={FMDExams} />
              <Route exact path="/:lang/patient/:patientId/regd-exam/:page" component={REGDExams} />
              <Route exact path="/:lang/screening/:id/edit" component={EditScreening} />
              <Route exact path="/:lang/data/school/:page" component={School} />
            </Switch>
          </div>
          <Footer />
        </div>
        <ConfirmationModal />
      </div>
    </div>
  );
}

Layout.propTypes = {};

export default Layout;
