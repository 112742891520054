import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Spinner } from 'react-bootstrap';
import { EmailRegex, PhoneRegex } from '../../../constants';
import { useForm } from 'react-hook-form';
import { updateUserInfo } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import { ToasterContext } from '../../../context/index.ts';

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = useContext(ToasterContext);
  
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { data: authData, loading: authLoading, error } = useSelector(state => state.auth);

  const handleUpdate = values => {
    values.userId = authData.user.id;
    values.role = authData.user.role;

    setLoading(true);
    dispatch(updateUserInfo(values));
  };

  useEffect(() => {
    !authLoading && setLoading(false);
  }, [authLoading]);

  useEffect(() => {
    error && showError(error.code ? t('errors:codes.' + error.code) : t('messages:profileUpdateFailed'));
  }, [error]);

  return (
    <Form onSubmit={handleSubmit(handleUpdate)}>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.firstName')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.firstName}
          fixedLabel={true}
          controlProps={{
            id: 'firstName',
            name: 'firstName',
            defaultValue: authData.user.firstName,
            maxLength: 100,
          }}
          register={register('firstName', {
            required: true,
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.lastName')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.lastName}
          fixedLabel={true}
          controlProps={{
            id: 'lastName',
            name: 'lastName',
            defaultValue: authData.user.lastName,
            maxLength: 100,
          }}
          register={register('lastName', {
            required: true,
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.phone')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.phone}
          fixedLabel={true}
          prependContent={<i className="las la-phone" />}
          controlProps={{
            id: 'phone',
            name: 'phone',
            defaultValue: authData.user.phone,
            maxLength: 100,
          }}
          register={register('phone', {
            pattern: {
              value: PhoneRegex,
              message: 'invalidPhoneNumber',
            },
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.email')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.email}
          prependContent={<i className="las la-at" />}
          fixedLabel={true}
          controlProps={{
            id: 'email',
            name: 'email',
            defaultValue: authData.user.email,
            maxLength: 100,
          }}
          register={register('email', {
            required: true,
            pattern: {
              value: EmailRegex,
              message: 'invalidEmail',
            },
          })}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.jobTitle')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.title}
          fixedLabel={true}
          controlProps={{
            id: 'title',
            name: 'title',
            defaultValue: authData.user.title,
            maxLength: 100,
          }}
          register={register('title')}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Form.Label className={'col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center'}>
          {t('profile:settings.info.company')}
        </Form.Label>
        <CustomFormGroup
          groupProps={{ className: 'col-lg-9 col-xl-8' }}
          error={errors.company}
          fixedLabel={true}
          controlProps={{
            id: 'company',
            name: 'company',
            defaultValue: authData.user.company,
            maxLength: 100,
          }}
          register={register('company')}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3 row">
        <Col lg={9} xl={8} className="offset-lg-3">
          <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
            {loading && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="spinner" />
            )}
            {t('profile:settings.info.updateProfile')}
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};
export default UpdateProfile;
