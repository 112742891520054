/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

type UploadResultType = {
  info: { files: ResultFilesType[] };
  event: string;
};

type ResultFilesType = {
  uploadInfo: FileType;
  id: string;
  name: string;
  type: string;
};

type FileType = {
  asset_id: string;
  original_filename: string;
  thumbnail_url: string;
  url: string;
  secure_url: string;
  format: string;
  bytes: number;
  created_at: string;
  resource_type: string;
  [Symbol.iterator]: () => Iterator<FileType>;
};

export type AttachmentInfoType = {
  id: string;
  thumbnailUrl: string;
  url: string;
  secureUrl: string;
  format: string;
  bytes: number;
  createdAt: string;
  resourceType: string;
  type: string;
  name: string;
};

function useCloudinaryWidget({ folder = '', sources = ['local', 'camera'] }) {
  const { lang } = useParams();
  const { t } = useTranslation();
  const widget = useRef<{ open: () => void }>();
  const filesRef = useRef<AttachmentInfoType[]>();
  const [tick, setTick] = useState(0);

  useEffect(() => {
    filesRef.current = [];
  }, []);

  const showWidget = useCallback(() => {
    if (!widget.current) {
      //@ts-ignore
      widget.current = window.cloudinary.createUploadWidget(
        {
          cloudName: 'vectar',
          uploadPreset: 'thnyi16e',
          folder: `aecp-school-screenings/${folder}`,

          sources: sources,
          language: lang,
          styles: {
            palette: {
              window: 'rgb(82,48,89)',
              sourceBg: '#FFFFFF',
              tabIcon: '#FFFFFF',
              menuIcons: '#D7D7D8',
              link: '#0b51b7',
              action: '#FFFFFF',
              error: '#FFFFFF',
            },
          },
          text: {
            //@ts-ignore
            [lang]: {
              or: t('cloudinary:widget.or'),
              back: t('cloudinary:widget.back'),
              close: t('cloudinary:widget.clode'),
              no_results: t('cloudinary:widget.no_results'),
              menu: {
                files: t('cloudinary:widget.files'),
              },
              actions: {
                upload: t('cloudinary:widget.upload'),
                next: t('cloudinary:widget.next'),
              },
              local: {
                browse: t('cloudinary:widget.browse'),
                dd_title_single: t('cloudinary:widget.dd_title_single'),
                dd_title_multi: t('cloudinary:widget.dd_title_multi'),
                drop_title_single: t('cloudinary:widget.drop_title_single'),
                drop_title_multiple: t('cloudinary:widget.drop_title_multiple'),
              },
              crop: {
                title: t('cloudinary:widget.crop_title'),
                crop_btn: t('cloudinary:widget.crop_title'),
                skip_btn: t('cloudinary:widget.skip_btn'),
                reset_btn: t('cloudinary:widget.reset_btn'),
                close_btn: t('cloudinary:widget.close_btn'),
                close_prompt: t('cloudinary:widget.close_prompt'),
                image_error: t('cloudinary:widget.image_error'),
                corner_tooltip: t('cloudinary:widget.corner_tooltip'),
                handle_tooltip: t('cloudinary:widget.handle_tooltip'),
              },
            },
          },
        },
        (error: any, result: UploadResultType) => {
          if (!error && result && result.event === 'queues-end') {
            console.log(result.info.files);

            const formatedFiles: AttachmentInfoType[] = result.info.files.map(file => ({
              id: file.uploadInfo.asset_id,
              thumbnailUrl: file.uploadInfo.thumbnail_url,
              url: file.uploadInfo.url,
              secureUrl: file.uploadInfo.secure_url,
              format: file.uploadInfo.format,
              bytes: file.uploadInfo.bytes,
              createdAt: file.uploadInfo.created_at,
              resourceType: file.uploadInfo.resource_type,
              type: file.type,
              name: file.name,
            }));

            filesRef.current && filesRef.current.push(...formatedFiles);
            setTick(tick => tick + 1);
          }
        }
      );
    }

    filesRef.current = [];

    widget.current && widget.current.open();
  }, [lang, filesRef.current, widget.current]);

  return { showWidget, files: filesRef.current, tick };
}

export default useCloudinaryWidget;
