import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function CustomFormGroup({
  fixedLabel = false,
  label = undefined,
  prependContent = undefined,
  appendContent = undefined,
  groupProps = {},
  labelProps = {},
  labelClass = '',
  controlProps = {},
  register = {},
  error = undefined
}) {
  const [activated, setActivated] = useState(fixedLabel);
  const [value, setValue] = useState();
  const { t } = useTranslation();
  const activate = () => {
    setActivated(true);
  };

  const deactivate = e => {
    if (e.target.value === '') {
      setActivated(false);
    }
  };

  const handleChange = e => {
    setValue(e.target.value);
    if (register.onChange) {
      register.onChange(e);
    }
  };
  const handleKeyPress = (evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (controlProps.type === 'number' && (charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();
    }
  };

  return (
    <Form.Group {...groupProps}>
      {label &&
      <Form.Label
        {...labelProps}
        className={classnames(labelClass, {
          invisible:
            !activated &&
            (controlProps.defaultValue === undefined || controlProps.defaultValue === null || value === '')
        })}
      >
        {label}
      </Form.Label>
      }
      <InputGroup className={classnames({ 'is-invalid': !!error })}>
        {prependContent && (
          <InputGroup.Text>{prependContent}</InputGroup.Text>
        )}
        <Form.Control
          name={groupProps.controlId}
          {...controlProps}
          {...register}
          placeholder={fixedLabel ? controlProps.placeholder : activated ? '' : controlProps.placeholder || label}
          isInvalid={!!error}
          onChange={handleChange}
          onFocus={!fixedLabel ? activate : undefined}
          onBlur={!fixedLabel ? deactivate : undefined}
          onKeyPress={handleKeyPress}
        />
        {appendContent && (
          <InputGroup.Text>{appendContent}</InputGroup.Text>
        )}
      </InputGroup>
      {error && (
        <Form.Control.Feedback type="invalid">
          {(error.message && t(`errors:validationMessages.${error.message}`)) ||
          (error.type === 'required' &&
            <span id={`error-${controlProps.name}`}>{t('errors:validationMessages.required')}</span>)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

CustomFormGroup.propTypes = {
  label: PropTypes.any,
  groupProps: PropTypes.object,
  labelProps: PropTypes.object,
  controlProps: PropTypes.object,
  error: PropTypes.object
};
CustomFormGroup.defaultProps = {};

export default CustomFormGroup;
