import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/index';
import { NavLink } from 'react-router-dom';

function StaffMenu() {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);

  return (
    <>
      <div className="title-box">
        <h6 className="menu-title">{t('menu:staff.title')}</h6>
      </div>

      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/staff/all`}>
            {t('menu:staff.allUsers')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/staff/admins`}>
            {t('menu:staff.admins')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/staff/nurses`}>
            {t('menu:staff.nurses')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/staff/family-doctors`}>
            {t('menu:staff.familyDoctors')}
          </NavLink>
        </li>
        <li className="nav-item mt-2">
          <NavLink className="nav-link" to={`/${locale.value}/staff/regional-doctors`}>
            {t('menu:staff.regionalDoctors')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={`/${locale.value}/staff/ophthalmologists`}>
            {t('menu:staff.ophthalmologists')}
          </NavLink>
        </li>
        <li className="nav-item pt-3">
          <NavLink className="btn btn-soft-success d-grid ml-2" to={`/${locale.value}/staff/create`}>
            {t('menu:staff.addUser')}
          </NavLink>
        </li>
      </ul>
    </>
  );
}

StaffMenu.propTypes = {};

export default StaffMenu;
