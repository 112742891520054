import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../../components/CustomFormGroup/CustomFormGroup';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';

const Diagnosis = ({ register, setValue, data, control, errors, clearErrors }) => {
  const { t } = useTranslation();

  const diseases = [
    'healthy',
    'eyelidDiseases',
    'structuralChangeOfTheEyeballMicrophthalmiaAnophthalmiaBuphthalmiaEtc',
    'inflammatoryDiseasesOfTheEyeballEyelids',
    'inflammatoryDiseasesOfTheInnerMembranesOfTheEyeball',
    'neoplasmsOfEyelidsAndEyeball',
    'myopia',
    'foresight',
    'astigmatism',
    'delusionWithVariousClassifications',
    'diseasesOfTheNasalSystem',
    'cornealDiseases',
    'cataract',
    'glaucoma',
    'diseasesOfTheVideo',
    'surgeryMyopia',
    'deliriumSurgery',
    'astigmatismSurgery',
    'angioretinopathyIncludingRetinopathyOfPrematurity',
    'congenitalAndAcquiredLesionsOfTheEye',
    'congenitalAndAcquiredLesionsOfTheCornea',
    'cataractAphakiaSurgery',
    'cataractSurgeryArtiphakia',
    'cataractSurgeryPosteriorCapsuleFibrosis',
    'retinopathyOfPrematurityOperatedOn',
    'operativeGlaucoma',
    'surgicalCornea'
  ];


  const [rDeviationsValues, setRDeviationsValues] = useState(
    diseases.reduce((o, key) => ({ ...o, [key]: false}), {})
  );

  const [lDeviationsValues, setLDeviationsValues] = useState(
    diseases.reduce((o, key) => ({ ...o, [key]: false}), {})
  );

  useEffect(()=>{
    setValue('diagnosis.r.diseases', rDeviationsValues);
  }, [rDeviationsValues]);

  useEffect(()=>{
    setValue('diagnosis.l.diseases', lDeviationsValues);
  }, [lDeviationsValues]);


  return (
    <Row className="mb-3">
      <Row>{[
        'rightEyeOD',
        'leftEyeOS'
      ].map(eye => 
        <Col md={6} key={eye}>
          <h5>{t(`examinations:regdExam.${eye}`)}</h5>
          <CustomCheckboxGroup
            label={t('examinations:regdExam.diagnosis.diseaseType')}
            labelClass="d-block"
            items={
              [...diseases.map(d => { return {
                'label': t(`examinations:regdExam.diagnosis.${d}`),
                'checked': eye === 'rightEyeOD' ? rDeviationsValues[d as keyof typeof rDeviationsValues].v : lDeviationsValues[d as keyof typeof lDeviationsValues].v,
                'value': d
              };})]
            }
            inline={false}
            controlProps={{
              name: `diagnosis.${eye.charAt(0)}.diseases`,
              control,
              defaultValue: data?.diagnosis?.r?.diseases?.v
            }}
            onChange={(v, c) => {
              clearErrors('diagnosis');
              eye === 'rightEyeOD' && setRDeviationsValues({
                ...rDeviationsValues,
                [v]: c
              });
              eye === 'leftEyeOS' && setLDeviationsValues({
                ...lDeviationsValues,
                [v]: c
              });
            }}
          />
          <CustomFormGroup
            prependContent={t('examinations:regdExam.diagnosis.other')}
            register={{ ...register(`diagnosis.${eye.charAt(0)}.diseases.other`) }}
            error={errors.diagnosis?.[eye.charAt(0)]?.diseases?.other}
            groupProps={{ className: 'col-md-6 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: `diagnosis.${eye.charAt(0)}.diseases.other`,
              name: `diagnosis.${eye.charAt(0)}.diseases.other`,
              defaultValue: data?.diagnosis?.[eye.charAt(0)]?.diseases?.other || '',
              as: 'textarea'
            }}
          />
        </Col>
      )}
      </Row>
    </Row>
  );
};
export default Diagnosis;
