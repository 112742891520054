import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import CustomCheckboxGroup from '../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';
// import CustomDateTimePicker from '../../../../../components/CustomDateTimePicker/CustomDateTimePicker';

const Surgeries = ({ register, control, setValue, errors, data, clearErrors }) => {
  const { t } = useTranslation();
  
  const options = [
    'delusion',
    'cataract',
    'glaucoma',
    'vitreoretinalSurgery',
    'correctionOfPtosisInversionInversionOfEyelids',
    'probingDrainageOfTheLacrimalSystem',
    'laserInterventions',
    'notIdentifield'
  ];

  const [values, setValues] = useState(Object.assign(...options.map(k => ({ [k]: false }))));

  const mValues = useMemo(
    () => [...options.map(_=> {
      return {
        'label': t(`examinations:regdExam.surgeries.${_}`),
        'checked': values[_ as keyof typeof values],
        'value': _
      };
    })],
    [values]
  );

  useEffect(()=>{
    setValue('surgeries', values);
  }, [values]);

  return (
    <>
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.surgeries.title')}</h5>
      <Row className="mb-3">
        <Col md={4} className="mb-3">
          <CustomCheckboxGroup
            labelClass="d-block"
            items={mValues}
            inline={false}
            controlProps={{
              name: 'surgeries',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.surgeries
            }}
            onChange={(v, c) => {
              clearErrors('surgeries');
              setValues({
                ...values,
                [v]: c
              });
            }}
          />
          <CustomFormGroup
            key={'other'}
            prependContent={t('examinations:regdExam.specify')}
            register={{ ...register('surgeries.other') }}
            error={errors.surgeries?.other}
            groupProps={{ className: 'col-md-12 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'surgeries.other',
              name: 'surgeries.other',
              defaultValue: data?.surgeries?.other || '',
              disabled: !values.notIdentifield

            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default Surgeries;
