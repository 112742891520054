export const ErrorCodes = {
  ok: 1,
  wrongParams: 2,
  serverError: 3,
  needPasswordChange: 4,
  tokenExpired: 5,
  wrongCredentials: 6,
  wrongRefreshToken: 7,
  resourceNotFound: 8,
};

export const UserRoles = [
  { value: 'admin', label: 'userRoles.admin' },
  { value: 'fmd', label: 'userRoles.fmd' },
  { value: 'regd', label: 'userRoles.regd' },
  { value: 'opht', label: 'userRoles.opht' },
  { value: 'nurse', label: 'userRoles.nurse' },
];

export const Statuses = [
  { value: 1, key: 'active', label: 'status.active' },
  { value: 0, key: 'inactive', label: 'status.inactive' },
  { value: -1, key: 'deleted', label: 'status.deleted' },
];

export const PasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PhoneRegex = /^\+?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{3,6}$/;

export const Yerevan = 'Երևան';

export const CountryRegions = [
  { value: 1, key: 'yerevan', label: 'address.region.yerevan' },
  { value: 2, key: 'aragatsotn', label: 'address.region.aragatsotn' },
  { value: 3, key: 'ararat', label: 'address.region.ararat' },
  { value: 4, key: 'armavir', label: 'address.region.armavir' },
  { value: 5, key: 'gegharkunik', label: 'address.region.gegharkunik' },
  { value: 6, key: 'kotayk', label: 'address.region.kotayk' },
  { value: 7, key: 'lori', label: 'address.region.lori' },
  { value: 8, key: 'shirak', label: 'address.region.shirak' },
  { value: 9, key: 'syunik', label: 'address.region.syunik' },
  { value: 10, key: 'tavush', label: 'address.region.tavush' },
  { value: 11, key: 'vayotsDzor', label: 'address.region.vayotsDzor' },
];

export const YerevanCommunities = [
  { value: 7, key: 'kentron', label: 'address.community.kentron' },
  { value: 1, key: 'ajapnyak', label: 'address.community.ajapnyak' },
  { value: 2, key: 'arabkir', label: 'address.community.arabkir' },
  { value: 3, key: 'avan', label: 'address.community.avan' },
  { value: 4, key: 'davtashen', label: 'address.community.davtashen' },
  { value: 5, key: 'erebuni', label: 'address.community.erebuni' },
  { value: 6, key: 'kanakerZeytun', label: 'address.community.kanakerZeytun' },
  { value: 8, key: 'malatiaSebastia', label: 'address.community.malatiaSebastia' },
  { value: 9, key: 'norkMarash', label: 'address.community.norkMarash' },
  { value: 10, key: 'norNork', label: 'address.community.norNork' },
  { value: 11, key: 'nubarashen', label: 'address.community.nubarashen' },
  { value: 12, key: 'shengavit', label: 'address.community.shengavit' },
];

export const RelationToChild = [
  { value: 'mother', key: 'mother', label: 'patient:adultContacts.mother' },
  { value: 'father', key: 'father', label: 'patient:adultContacts.father' },
  { value: 'other', key: 'other', label: 'patient:adultContacts.other' },
];

export const FMDExaminationResult = {
  referedToRegionalDoctor: 1,
  treatedByFMD: 2,
  wrongScreeningResults: 3,
};

export const REGDExaminationResult = {
  referedToOphthalmologist: 1,
  treatedByREGD: 2,
  wrongScreeningResults: 3,
};

export const OphtExaminationResult = {
  referedToOphthalmologist: 1,
  treatedByOPHT: 2
};
