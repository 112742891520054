import React, { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';

const StillLampExam = ({ data, clearErrors, setValue, control, errors }: RegDExamSubProps) => {
  const { t } = useTranslation();
  const [sValue, setSValue] = useState<boolean>();

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  return (
    <>
      <Row className="mb-3">
        <h5 className="mb-3 m-0">{t('examinations:regdExam.stillLampExam.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'stillLampExamState',
            control,
            defaultValue: data?.stillLampExamState,
            rules: { validate: (v: string) => v !== undefined || 'required' },
          }}
          onChange={v => {
            clearErrors('stillLampExamState');
            setSValue(v as boolean);
            setValue('stillLampExamState', v);
          }}
        />
      </Row>
    </>
  );
};
export default StillLampExam;
