import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Avatar from '../../components/Avatar/Avatar';
import CustomFormGroup from '../../components/CustomFormGroup/CustomFormGroup';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { EmailRegex, PhoneRegex, UserRoles } from '../../constants';
import axios from '../../axios';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AppContext, ToasterContext } from '../../context/index.ts';
import { NavLink, withRouter } from 'react-router-dom';
import RoleSelect from '../../components/RoleSelect/RoleSelect';

const AddUser = ({ history }) => {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [selectedRole, setSelectedRole] = useState(UserRoles[0]);
  const [loading, setLoading] = useState(false);

  const { showSuccess, showError } = useContext(ToasterContext);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm();

  const addNewUser = async values => {
    if (avatarUrl) {
      values.url = avatarUrl;
    }
    values.role = selectedRole.value;
    try {
      setLoading(true);
      const response = await axios.post('user/create', values);

      if (response.status !== 201) {
        throw new Error(response.status);
      }

      showSuccess(t('messages:userCreated'));

      setTimeout(() => {
        history.push(`/${locale.value}/staff/all`);
      }, 5000);
    } catch (err) {
      const error = err.response?.data;
      showError(error.code ? t('errors:codes.' + error.code) : t('messages:userCreateFailed'));
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t('pageTitles:addUser') + t('pageTitles:tail')}</title>
      </Helmet>
      <PageTitle title={t('pageTitles:addUser')} />
      <Col className=" mx-auto new-user">
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit(addNewUser)}>
              <FormGroup className="mb-3 met-profile">
                <div className="met-profile-main" style={{ minWidth: 100 }}>
                  <Avatar avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} />
                </div>
              </FormGroup>
              <FormGroup className="row">
                <CustomFormGroup
                  label={t('profile:settings.info.firstName')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.firstName}
                  fixedLabel={true}
                  controlProps={{
                    id: 'firstName',
                    name: 'firstName',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('firstName', {
                    required: true,
                  })}
                />
                <CustomFormGroup
                  label={t('profile:settings.info.lastName')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.lastName}
                  fixedLabel={true}
                  controlProps={{
                    id: 'lastName',
                    name: 'lastName',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('lastName', {
                    required: true,
                  })}
                />
              </FormGroup>
              <FormGroup className="row">
                <CustomFormGroup
                  label={t('profile:settings.info.phone')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.phone}
                  fixedLabel={true}
                  prependContent={<i className="las la-phone" />}
                  controlProps={{
                    id: 'phone',
                    name: 'phone',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('phone', {
                    pattern: {
                      value: PhoneRegex,
                      message: 'invalidPhoneNumber',
                    },
                  })}
                />
                <CustomFormGroup
                  label={t('profile:settings.info.email')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.email}
                  prependContent={<i className="las la-at" />}
                  fixedLabel={true}
                  controlProps={{
                    id: 'email',
                    name: 'email',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('email', {
                    required: true,
                    pattern: {
                      value: EmailRegex,
                      message: 'invalidEmail',
                    },
                  })}
                />
              </FormGroup>
              <FormGroup className="row">
                <CustomFormGroup
                  label={t('profile:settings.info.jobTitle')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.title}
                  fixedLabel={true}
                  controlProps={{
                    id: 'title',
                    name: 'title',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('title', {})}
                />
                <CustomFormGroup
                  label={t('profile:settings.info.company')}
                  groupProps={{ className: 'col-md-6 mb-3' }}
                  error={errors.company}
                  fixedLabel={true}
                  controlProps={{
                    id: 'company',
                    name: 'company',
                    defaultValue: '',
                    maxLength: 100,
                  }}
                  register={register('company', {})}
                />
              </FormGroup>
              <Row>
                <RoleSelect
                  groupProps={{ as: Col, md: 6, className: 'mb-3' }}
                  onChange={setSelectedRole}
                  control={control}
                />
              </Row>
              <Row>
                <FormGroup className="form-group mt-5">
                  <Button type="submit" className="submit btn btn-de-primary" disabled={loading}>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="spinner"
                      />
                    )}
                    {t('buttons.create')}
                  </Button>
                  <NavLink as="button" className="btn btn-de-danger" to={`/${locale.value}/staff/all`}>
                    {t('buttons.cancel')}
                  </NavLink>
                </FormGroup>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
export default withRouter(AddUser);
