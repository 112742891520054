import React, { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomCheckboxGroup from '../../../../../components/CustomCheckboxGroup/CustomCheckboxGroup';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';
import { RegDExamSubProps } from '../AddREGDExam';

const Deseases = ({ register, control, setValue, errors, data, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();
  
  const options_1 = [
    'myopia',
    'foresight',
    'astigmatism',
    'inflammationOfTheMembranesOfTheEye',
    'eyelidEyeballNeoplasm',
    'congenitalAndAcquiredLesionsOfEyelidsOrbit',
  ];
  const options_2 = [
    'delusion',
    'cataract',
    'glaucoma',
    'retinopathyOfPrematurity',
    'diseasesOfTheEye',
    'notIdentifield',
  ];

  const [values, setValues] = useState([...options_1, ...options_2].reduce((o, key) => ({ ...o, [key]: false}), {}));

  const mValues_1 = useMemo(
    () => [
      ...options_1.map(_=> {
        return {
          'label': t(`examinations:regdExam.deseases.${_}`),
          'checked': values[_ as keyof typeof values],
          'value': _
        };
      })
    ],
    [values]
  );
  const mValues_2 = useMemo(
    () => [
      ...options_2.map(_=> {
        return {
          'label': t(`examinations:regdExam.deseases.${_}`),
          'checked': values[_ as keyof typeof values],
          'value': _
        };
      })
    ],
    [values]
  );

  useEffect(()=>{
    setValue('deseases', values);
  }, [values]);

  return (
    <>
      <Separator text={`${t('examinations:regdExam.deseases.history')}`} />
      <h5 className='mb-3 m-0'>{t('examinations:regdExam.deseases.title')}</h5>

      <Row className="mb-3">
        <Col md={4} className="mb-3">
          <CustomCheckboxGroup
            labelClass="d-block"
            items={mValues_1}
            inline={false}
            controlProps={{
              name: 'deseasesFirstGroup',
              control,
              defaultValue: data?.deseases,
              disabled: values.notIdentifield
            }}
            onChange={(v, c) => {
              clearErrors('deseases');
              setValues({
                ...values,
                [v]: c
              });
            }}
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomCheckboxGroup
            labelClass="d-block"
            items={mValues_2}
            inline={false}
            controlProps={{
              name: 'deseasesSecondGroup',
              control,
              defaultValue: data?.deseases,
              disabled: values.notIdentifield
            }}
            onChange={(v, c) => {
              clearErrors('deseases');
              setValues({
                ...values,
                [v]: c
              });
            }}
          />
          <CustomFormGroup
            key={'other'}
            prependContent={t('examinations:regdExam.specify')}
            register={{ ...register('deseases.other') }}
            error={errors.deseases?.other}
            groupProps={{ className: 'col-md-12 mb-3' }}
            fixedLabel={true}
            controlProps={{
              id: 'deseases.other',
              name: 'deseases.other',
              defaultValue: data?.deseases?.other || '',
              disabled: !values.notIdentifield

            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default Deseases;
