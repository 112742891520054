import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ReferFMDModal from '../../../../components/modals/ReferFMDModal/ReferFMDModal';
import { AppContext, ModalsContext } from '../../../../context';

type Props = {
  hasIssue: boolean;
  patientId: string;
  screeningId: string;
  onEdit?: () => void;
};

const Result = ({ hasIssue, patientId, screeningId, onEdit }: Props) => {
  const { t } = useTranslation();
  const { locale } = useContext(AppContext);
  const { referFMDModal } = useContext(ModalsContext);

  const handleReferFMD = () => {
    referFMDModal.show({
      patientId,
    });
  };

  if (!hasIssue) {
    return (
      <div className="alert mb-0 text-center" role="alert">
        <h4 className="alert-heading font-18 text-success">{t('examinations:screenings.result.success.title')}</h4>
        <p>{t('examinations:screenings.result.success.message')}</p>
        <p>
          {onEdit !== undefined ? (
            <Button variant="primary" className="px-4 mx-2" onClick={onEdit}>
              {t('examinations:screenings.result.hasIssue.links.editScreening')}
            </Button>
          ) : (
            <NavLink
              to={`/${locale.value}/patient/${patientId}/screening/${screeningId}/edit`}
              className="btn btn-primary px-4 mx-2"
            >
              {t('examinations:screenings.result.hasIssue.links.editScreening')}
            </NavLink>
          )}
        </p>
        <p>{t('examinations:screenings.result.or')}</p>
        <p>
          <NavLink to={`/${locale.value}/patients/all`} className="btn btn-de-primary px-4 mx-2">
            {t('examinations:screenings.result.success.links.patients')}
          </NavLink>
          <NavLink to={`/${locale.value}/patient/${patientId}/view`} className="btn btn-de-primary px-4 mx-2">
            {t('examinations:screenings.result.success.links.patient')}
          </NavLink>
          <NavLink to={`/${locale.value}/patients/create`} className="btn btn-de-primary px-4 mx-2">
            {t('examinations:screenings.result.success.links.addPatient')}
          </NavLink>
        </p>
      </div>
    );
  }
  return (
    <div className="alert mb-0 text-center" role="alert">
      <h4 className="alert-heading font-18 text-danger">{t('examinations:screenings.result.hasIssue.title')}</h4>
      <p>{t('examinations:screenings.result.hasIssue.message')}</p>
      <p>
        <Button onClick={handleReferFMD}>{t('examinations:referFMD')}</Button>{' '}
        {onEdit !== undefined ? (
          <Button variant="primary" className="px-4 mx-2" onClick={onEdit}>
            {t('examinations:screenings.result.hasIssue.links.editScreening')}
          </Button>
        ) : (
          <NavLink
            to={`/${locale.value}/patient/${patientId}/screening/${screeningId}/edit`}
            className="btn btn-primary px-4 mx-2"
          >
            {t('examinations:screenings.result.hasIssue.links.editScreening')}
          </NavLink>
        )}
      </p>
      <p>{t('examinations:screenings.result.or')}</p>
      <p>
        <NavLink to={`/${locale.value}/patients/all`} className="btn btn-soft-primary px-4 mx-2">
          {t('examinations:screenings.result.hasIssue.links.referLater')}
        </NavLink>
      </p>
      <ReferFMDModal />
    </div>
  );
};

export default Result;
