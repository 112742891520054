import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

function Error(props) {
  return (
    <Container className=" container-md">
      <Row className="vh-100 justify-content-center">
        <Col md={12} className="align-self-center">
          <Card.Body>
            <Row>
              <Col lg={6} className="mx-auto">
                <Card>
                  <Card.Body className="p-0 auth-header-box">
                    <div className="text-center p-3">
                      <NavLink to="/" className="logo logo-admin">
                        <Image src={logo} height="70" alt="logo" className="auth-logo mb-4" />
                      </NavLink>
                      <h4 className="mt-3 mb-1 fw-semibold text-white font-18">Oops! Sorry page does not found</h4>
                      <p className="text-muted  mb-0">Back to dashboard of Metrica.</p>
                    </div>
                  </Card.Body>
                  <Card.Body className="pt-0">
                    <div className="ex-page-content text-center">
                      {/* <img src="assets/images/error.svg" alt="0" className="" height="170" /> */}
                      <h1 className="mt-5 mb-4">404!</h1>
                      <h5 className="font-16 text-muted mb-5">Somthing went wrong</h5>
                    </div>
                    <a className="btn btn-primary w-100" href="index.html">
                      Back to Dashboard <i className="fas fa-redo ml-1"></i>
                    </a>
                  </Card.Body>
                  <div className="card-body bg-light-alt text-center">
                    © <script>document.write(new Date().getFullYear())</script>2022 Metrica
                  </div>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Container>
  );
}

export default Error;
