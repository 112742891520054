import './App.scss';
import { changeLanguage } from './i18n';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { reducer } from './App.store';
import { Redirect, Route, Switch, useParams, useHistory } from 'react-router-dom';
import SignInPage from './pages/SignInPage/SignInPage';
import { pathToRegexp } from 'path-to-regexp';
import { AppContext, ModalsContext, ToasterContext } from './context/index.ts';
import ForgotPassword from './pages/SignInPage/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import PrivateRoute from './components/PrivateRoute';
import { setLanguage, getAuthUser, getAuthUserDone, loadData } from './store/actions';
import axios from './axios';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorCodes } from './constants';
import { Spinner } from 'react-bootstrap';
import Layout from './pages/Layout.tsx';
import Error from './pages/Errors/Error.tsx';

const setLocale = language => {
  const ROUTE = '/:lang/:path*';
  const routeComponents = pathToRegexp(ROUTE).exec(window.location.pathname);
  let subPaths = null;
  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split('/');
  }
  window.location.replace(`/${language}/${subPaths ? subPaths.join('/') : ''}`);
};

const App = ({ match }) => {
  const { lang } = useParams();
  const history = useHistory();
  const authorizing = useRef();

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth.data);
  const [appState, setAppState] = useReducer(reducer, {
    loading: true,
    error: undefined,
    locale: {
      value: lang,
      setValue: setLocale,
    },
  });

  const [toasts, setToasts] = useState([]);

  const removeToast = useCallback(
    toast => {
      setToasts(prev => {
        return prev.filter(_ => _.id !== toast.id);
      });
    },
    [toasts]
  );

  const addToast = useCallback(
    data => {
      const toast = { ...data, id: Date.now() };
      setToasts(prev => [...prev, toast]);
      setTimeout(() => removeToast(toast), 5000);
    },
    [toasts, removeToast]
  );

  const [modals, setModals] = useReducer(reducer, {
    confirmationModal: {
      open: false,
      show: data => toggleModal('confirmationModal', true, data),
      hide: () => toggleModal('confirmationModal', false),
      data: {},
    },
    referFMDModal: {
      open: false,
      show: data => toggleModal('referFMDModal', true, data),
      hide: () => toggleModal('referFMDModal', false),
      data: {},
    },
    exportPatientsModal: {
      open: false,
      show: data => toggleModal('exportPatientsModal', true, data),
      hide: () => toggleModal('exportPatientsModal', false),
      data: {},
    },
  });

  const toggleModal = (modal, open, data = {}) => {
    setModals({
      prop: modal,
      value: {
        ...modals[modal],
        open,
        data,
      },
    });
  };

  const { error: appError, loading: appLoading } = useSelector(state => state.app);

  const refresh = async ({ t, rt }, cb) => {
    try {
      const response = await axios.post('/auth/refresh-token', { refreshToken: rt }, { headers: { Authorization: t } });
      if (response.status === 200) {
        const { token, refreshToken } = response.data.data;
        axios.defaults.headers.common['Authorization'] = token;
        localStorage.setItem('aecp-ss-auth-token', token);
        localStorage.setItem('aecp-ss-refresh-token', refreshToken);
        return cb();
      } else {
        autoLogout();
        return Promise.reject();
      }
    } catch (e) {
      autoLogout();
      return Promise.reject(e);
    }
  };

  const autoLogout = () => {
    localStorage.removeItem('aecp-ss-auth-token');
    localStorage.removeItem('aecp-ss-refresh-token');
    delete axios.defaults.headers['Authorization'];
    window.location.replace(`/${lang}`);
  };

  useEffect(() => {
    axios.interceptors.response.use(undefined, err => {
      switch (err.response?.status) {
        case 401:
          //eslint-disable-next-line
          const refreshToken = localStorage.getItem('aecp-ss-refresh-token');

          if (!refreshToken) {
            autoLogout();
            return Promise.reject(err);
          }

          //eslint-disable-next-line
          const originalRequestConfig = err.config;
          //eslint-disable-next-line
          const token = originalRequestConfig.headers['Authorization'];
          delete originalRequestConfig.headers['Authorization'];

          // create pending authorization
          authorizing.current ??= refresh({ t: token, rt: refreshToken }, () => axios.request(originalRequestConfig))
            .finally(() => (authorizing.current = null))
            .catch(error => {
              autoLogout();
              return Promise.reject(error);
            });

          // delay original requests until authorization has been completed
          return Promise.resolve(authorizing.current).then(() => console.log('refreshed token and retryed request'));
        case 403:
          if (err.response.data.code === ErrorCodes.needPasswordChange) {
            return history.replace(`/${lang}/reset-password`);
          }

          return Promise.reject(err);
        default:
          return Promise.reject(err);
      }
    });

    const token = localStorage.getItem('aecp-ss-auth-token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setAppState({ prop: 'loading', value: true });
      dispatch(getAuthUser());
      dispatch(loadData());
      if (appState.locale.value) {
        dispatch(setLanguage({ token, lang: appState.locale.value }));
      }
    } else {
      dispatch(getAuthUserDone({}));
    }
  }, []);

  useEffect(() => {
    setAppState({ prop: 'locale', value: lang });
  }, [match]);

  useEffect(() => {
    if (user) {
      setAppState({ prop: 'locale', value: user.lang });
      changeLanguage(user.lang);
    }
  }, [user]);

  useEffect(() => {
    setAppState({ prop: 'loading', value: appLoading });
  }, [appLoading]);

  useEffect(() => {
    changeLanguage(appState.locale.value);
  }, [appState.locale.value]);

  if (appState.loading) {
    return <Spinner variant="primary" animation="grow" />;
  }

  if (!['en', 'am'].includes(lang)) {
    return <Redirect to={user ? `/${user.lang}` : '/en'} />;
  }

  if (appError) {
    return <Error />;
  }

  return (
    <AppContext.Provider value={appState}>
      <ToasterContext.Provider
        value={{
          toasts,
          addToast,
          removeToast,
          showError: message => addToast({ type: 'error', message }),
          showSuccess: message => addToast({ type: 'success', message }),
        }}
      >
        <ModalsContext.Provider value={modals}>
          <Switch>
            <Route exact path={`${match.url}/sign-in`} component={SignInPage} />
            <Route exact path={`${match.url}/forgot-password`} component={ForgotPassword} />
            <Route exact path={`${match.url}/reset-password`} component={ResetPassword} />
            <Redirect from="/:lang/" exact to={`/${match.params.lang}/patients/all`} />
            <PrivateRoute path={`${match.url}/`} component={Layout} appLoading={appLoading} />
          </Switch>
        </ModalsContext.Provider>
      </ToasterContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
