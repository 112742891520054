import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/Separator/Separator';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import AddressForm from '../../../components/AddressForm/AddressForm';


const SchoolForm = ({ register, control, setValue, errors, data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Separator text={t('examinations:screenings.school')} />
      <Row>
        <CustomFormGroup
          label={t('examinations:screenings.schoolName')}
          groupProps={{ className: 'col-md-6 mb-3' }}
          error={errors.school?.name}
          fixedLabel={true}
          controlProps={{
            id: 'school.name',
            name: 'school.name',
            defaultValue: data?.name || ''
          }}
          register={register('school.name', {
            required: true
          })}
        />
        <AddressForm
          control={control}
          register={register}
          setValue={setValue}
          nestedKey="school"
          errors={errors}
          data={data}
          streetAddressNeeded={false}
        />
      </Row>
    </>
  );
};
export default SchoolForm;
