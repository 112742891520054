import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import FilterMenu from '../../../components/FilterMenu/FilterMenu';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { CountryRegions, YerevanCommunities } from '../../../constants';

const Filter = ({ setParams, query, role }) => {
  const { t } = useTranslation();
  const regions = CountryRegions;

  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedCommunity, setSelectedCommunity] = useState(YerevanCommunities[0]);

  useEffect(() => {
    if (selectedRegion.key === 'yerevan') {
      setSelectedCommunity(YerevanCommunities[0]);
    } else {
      setSelectedCommunity(null);
    }
  }, [selectedRegion]);

  useEffect(() => {
    handleReset();
  }, [role]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
    clearErrors
  } = useForm();


  const handleFilter = values => {
    !values.name && delete values.name && query.delete('name');
    !values.region && delete values.region && query.delete('region');
    !values.settlement && delete values.settlement && query.delete('settlement');
    (!values.community || selectedRegion.value !== 1) && delete values.community && query.delete('community');
    setParams(values);
  };

  const handleReset = () => {
    setSelectedRegion({});
    reset({
      name: '',
      region: {},
      community: '',
      settlement: ''
    });

    query.delete('name');
    query.delete('region');
    query.delete('community');
    query.delete('settlement');
    setParams({});
  };

  return (
    <FilterMenu>
      <Form onSubmit={handleSubmit(handleFilter)}>
        <ul className="list-unstyled components sd-body">
          <li>
            <CustomFormGroup
              label={t('school:name')}
              groupProps={{ className: 'mb-3' }}
              error={errors.name}
              fixedLabel={true}
              controlProps={{
                id: 'name',
                name: 'name',
                maxLength: 100
              }}
              register={register('name')}
            />
          </li>
          <li>
            <Form.Group className="mb-3">
              <Form.Label>{t('address.region.title')}</Form.Label>
              <Controller
                name="region"
                control={control}
                defaultValue={regions[0].value}
                value={selectedRegion ? selectedRegion.value : regions[0].value}
                render={({ field: { onChange, name } }) => {
                  return (
                    <CustomSelect
                      disabled={!selectedRegion && selectedRegion.key === 'yerevan'}
                      options={regions}
                      values={selectedRegion ? [selectedRegion] : []}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{selectedRegion && selectedRegion.label}</span>
                      )}
                      onChange={selected => {
                        setSelectedRegion(selected[0]);
                        onChange(selected[0].value);
                      }}
                    />

                  );
                }}
              />
            </Form.Group>
          </li>
          {selectedRegion.value !== 1 &&
          <li>
            <CustomFormGroup
              label={t('school:settlement')}
              groupProps={{ className: 'mb-3' }}
              error={errors.settlement}
              fixedLabel={true}
              controlProps={{
                id: 'settlement',
                name: 'settlement'
              }}
              register={register('settlement')}
            />
          </li>
          }
          {selectedRegion && selectedRegion.value === 1 && (
            <Form.Group className="mb-3">
              <Form.Label>{t('address.community.title')}</Form.Label>
              <Controller
                name={'community'}
                control={control}
                defaultValue={selectedCommunity?.value}
                render={({ field: { onChange, name } }) => {
                  return (
                    <CustomSelect
                      register={{ ...register('community') }}
                      name={name}
                      options={YerevanCommunities}
                      value={selectedCommunity?.value}
                      contentRenderer={() => (
                        <span className="text-size-smaller">
                        {selectedCommunity
                          ? YerevanCommunities.find(_ => _.value === selectedCommunity.value).label
                          : YerevanCommunities[0].label}
                      </span>
                      )}
                      onChange={val => {
                        setSelectedCommunity(val[0]);
                        onChange(val[0].value);
                      }}
                    />
                  );
                }}
              />
            </Form.Group>
          )}


          <li>
            <FormGroup>
              <Button type="submit" className="submit btn btn-de-primary">
                {t('buttons.apply')}
              </Button>
              <Button as="button" className="btn btn-de-danger" onClick={handleReset}>
                {t('buttons.reset')}
              </Button>
            </FormGroup>
          </li>
        </ul>
      </Form>
    </FilterMenu>
  );
};

export default Filter;
